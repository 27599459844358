const input = {
  baseStyle: {

  },
  variants: {
    "login-input": {
      field: {
        border: "1px solid",
        borderColor: "gray.inputBorder",
        borderRadius: "0",
      },
    },
    "request-input": {
      field:  {
        border: "1px solid",
        borderColor: "gray.inputBorder",
        borderRadius: "0",
      },
    },

    "test": {
      field: {
        border: "1px solid",
        borderColor: "inherit",
        bg: "green",
        _readOnly: {
          boxShadow: "none !important",
          userSelect: "all"
        },
      }
    }
  },
}

export default input;
