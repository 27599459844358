import React, { Component } from 'react';

//Chakra
import { Box, Divider, Heading, Link, Text } from '@chakra-ui/layout';
import I18n from 'i18n-js';
class ApartmentReview extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {

  }

  render() {
    const apartment = 'A5'; // for demo purposes, TODO remove
    const time = '8.30'; // for demo purposes, TODO remove
    const date = '20.1.'; // for demo purposes, TODO remove

    return (
      <Box bg="white" p={[4, 8, 4, 8]}>
        <Heading>{I18n.t('avainiawrapper.apartment_review', {apartment: apartment})}</Heading>
        <Heading size="lg" textTransform="none" mb="2">{I18n.t('avainiawrapper.apartment_review_time', {date: date, time: time})}</Heading>
        <Text fontWeight="bold">{I18n.t('avainiawrapper.print_and_fill')}</Text>
        <Link variant="default-link">{I18n.t('avainiawrapper.contact_and_mandate')}</Link>
        <Divider />
        <Text>
          {I18n.t('avainiawrapper.review_schedule_info')}
        </Text>
        <Divider />
        <Link variant="default-link">{I18n.t('avainiawrapper.review_time_not_fitting')}</Link>
      </Box>
    )
  }
}

export default ApartmentReview;
