import React, { Component } from "react";
import I18n from 'i18n-js';

// Chakra
import { Box, SimpleGrid } from "@chakra-ui/layout";

import ProductCard from "../ProductCard/ProductCard";
import ProductSelectChip from "../badges/ProductSelectBadge/ProductSelectBadge";
import FormHeader from "../FormHeader/FormHeader";

class ProductSelectGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: null,
      productIsSelected: this.props.selection ? true : false,
      selectedProduct: this.props.selectedFromThisGroup || {},
      disabled: this.props.disabled,
    };
  }

  componentDidMount = () => {
    this.setState({
      products: null,
      productIsSelected: this.props.selection ? true : false,
      selectedProduct: this.props.selectedFromThisGroup,
    })
  }

  toggleProductSelection = (selected, product) => {
    this.setState({
      productIsSelected: selected,
      selectedProduct: product
    }, () => {
      if (this.state.selectedProduct !== null) {
        this.props.productSelectedCallback(this.state.selectedProduct)
      }
    });
  }

  render() {

    const columnCount = this.props.printMode ? 3 : [1, 1, 3, 2, 3, 4];

    return (
      <>
      <Box>
        <FormHeader text={this.props.products.name} />
        <Box pt="10" px={[4, 8, 4, 8]} mb="4" border="4px solid" borderColor="gray.divider" position="relative">
          {!this.props.printMode && <ProductSelectChip contentText={I18n.t('materialforms.choose_one_product')} />}
          <SimpleGrid id={this.props.products.name} columns={columnCount}>
            {
              this.props.products.options.sort((a, b) => a.order_index - b.order_index).map((product) => {
                return <ProductCard
                  area={this.props.products.area}
                  disabled={this.state.disabled}
                  id={this.props.id}
                  product={product}
                  productIsSelected={this.state.selectedProduct}
                  productSelectionToggleCallback={(selected, prod) => this.toggleProductSelection(selected, prod)}
                  onChange={(name, value) => this.props.onChange(name, value)}
                  callback={(product, data, optionId) => this.props.callback(product, data, optionId)}
                  selectedFromThisGroup={this.props.selectedFromThisGroup}
                  key={this.props.id}
                  printMode={this.props.printMode}
                />;
              })
            }
          </SimpleGrid>
        </Box>
      </Box >
      <div className="print-page-break" />
      </>
    );
  }
}

export default ProductSelectGrid;
