const button = {
  baseStyle: {
    fontWeight: "semibold",
    paddingRight: "50px",
  },
  variants: {
    "green-btn": {
      fontSize: "sm",
      color: "white",
      bg: "green.light",
      borderRadius: "20px",
      _hover: {
        bg: "green.dark",
      }
    },

    "green-request-btn": {
      fontSize: "sm",
      color: "white",
      bg: "green.light",
      borderRadius: "20px",
      _hover: {
        bg: "green.dark",
      }
    },

    "request-account-btn": {
      bg: "base.yellow",
      borderRadius: "0px",
      width: "100%",
      padding: "10",
      textTransform: "uppercase",
      fontSize: "sm",
    },
    "basic-btn": {
      width: "100%",
      height: "12",
      bg: "white",
      color: "text.dark",
      fontSize: "90%",
      textTransform: "uppercase",
      border: "2px solid",
      borderColor: "gray.inputBorder",
      borderRadius: "0",
      justifyContent: "left",
    },
    "user-nav-btn": {
      bg: "gray.darkGray",
      color: "white",
      borderRadius: "0",
      border: "none",
      h: "auto",
      w: "100%",
      fontSize: "sm",
    },
    "info-button": {
      bg: "base.yellow",
      color: "black",
      borderRadius: "0",
      border: "none",
      h: "auto",
      w: "100%",
      fontSize: "sm",
    },

    "yellow-rounded-btn": {
      fontWeight: "normal",
      bg: "base.yellow",
      fontSize: "sm",
      color: "black",
      borderRadius: "20px",
    },
    "grey-forward-btn": {
      fontSize: "sm",
      color: "white",
      bg: "gray.lighter",
      borderRadius: "20px",
      paddingRight: "20px"
    },
    "green-forward-btn": {
      fontSize: "sm",
      color: "white",
      bg: "green.light",
      borderRadius: "20px",
      paddingRight: "20px"
    },
    "yellow-forward-btn": {
      fontSize: "sm",
      color: "black",
      bg: "base.yellow",
      borderRadius: "20px",
    },
    "red-delete-button": {
      fontSize: "sm",
      borderRadius: "20px",
      pl: "16px",
      bg: "red.basic",
      color: "white",
      _hover: {
        background: "#9B2C2C",
      },
    },
    "lightgray-btn-white": {
      fontSize: "sm",
      color: "white",
      bg: "gray.lightest",
      borderRadius: "20px",
      _hover: {
        bg: "gray.lighter"
      }
    },
    "lightgray-btn-dark": {
      fontSize: "sm",
      bg: "bgColor",
      borderRadius: "20px",
      _hover: {
        bg: "gray.lightest",
      }
    },
    "lightgray-btn-lighter": {
      fontSize: "sm",
      bg: "gray.darkerLightGray",
      borderRadius: "20px",
      _hover: {
        bg: "gray.lightest",
      }
    }
  },
}

export default button;
