import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

//Chakras
import { Text } from '@chakra-ui/layout';

class EnvironmentAndSecurity extends Component {
  render() {
    return (
      <>
        <p>Toimintamme ympäristöriskit ovat melko pienet, mutta pyrimme päivittäisellä toiminnallamme vaikuttamaan osaltamme ympäristömme hyvinvointiin.</p>
        <br></br>
        <p>Jätteiden huolellinen ja asianmukainen lajittelu on osa työmaan arkipäivää ja järkevä materiaalien käyttö on sekä asiakkaiden, että meidän etumme.</p>
        <br></br>
        <p>Omassa omistuksessamme olevat koneet ja kalusto takaavat, että niistä huolehditaan ja niitä huolletaan.</p>
        <br></br>
        <p>Työmaan valmistuttua korjaamme työmaaliikenteen aiheuttamat vauriot rakennuskohteen ympäristölle.</p>
      </>
    )
  }
}

export default withRouter(EnvironmentAndSecurity);
