import React, { Component } from 'react';

// Chakra
import { Alert, Icon, Text } from '@chakra-ui/react';

// Icons
import { FaRegCheckCircle } from "react-icons/fa";

class SuccessAlert extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {

  }

  render() {
    return (
      <Alert
        position="absolute"
        w="auto"
        bg="black"
        color="base.yellow"
        top="-20px"
        right={{ base: "10px", lg: "-20px" }}
        zIndex="1"
        borderRadius="30px"
      >
        <Icon as={FaRegCheckCircle} w={4} h={4} />
        <Text pl="2" fontSize="14px">{this.props.text}</Text>
      </Alert>
    );
  }
}

export default SuccessAlert;
