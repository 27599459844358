import React, { Component } from 'react';
import I18n from 'i18n-js';

import { Box, Text } from '@chakra-ui/layout';
import { Textarea } from "@chakra-ui/react";

// Icons
import SuccessAlert from '../alerts/SuccessAlert';

class InputTextboxWithAlert extends Component {
  constructor(props) {
    super(props);

    this.state = {
      [this.props.name]: null,
      savedValue: this.props.value,
      loading: true,
      disabled: this.props.disabled
    };
  }

  componentDidMount = () => {
    const name = this.props.name;
    this.setState({ [name]: this.props.value, loading: false })
  }

  onChange = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value, savedValue: this.props.value },
      this.props.onChangeCallback(name, value)
    )
  }

  render() {
    return (
      <>
        {!this.state.loading &&
          <Box pb="6">
            <Text fontWeight="bold" pb="6">{this.props.question}</Text>
            {this.props.infotext && <Text pb="6">{this.props.infotext}</Text>}
            <Box w={{ base: "100%", 'lg': "70%", xl: "80%" }} position="relative">
              <Textarea
                disabled={this.state.disabled}
                variant="material-form"
                name={this.props.name}
                value={this.state[this.props.name]}
                onChange={(e) => this.onChange(e)}
              />
            </Box>
          </Box>
        }
      </>
    )
  }
}

export default InputTextboxWithAlert;
