import React, { Component } from 'react';

// Chakra
import { Box, Text } from '@chakra-ui/react';

export default class MaterialFormStatusBadge extends Component {
  render() {
    return (
      <Box
        bg={this.props.bg}
        color="white"
        px={2}
        py={2}
        height={'min-content'}
        width={'min-content'}
      >
        <Text
          casing="uppercase"
          fontSize="0.6rem"
          fontWeight="bold"
          textAlign={'center'}
        >
          {this.props.contentText}
        </Text>
      </Box>
    );
  }
}
