const link = {
  variants: {
    "default-link": {
      color: "blue.link",
      fontSize: "sm",
    },
    "main-navigation-link": {
      color: "black",
      fontSize: "sm",
      _hover: {
        textDecoration: "none",
        boxShadow: "0px -3px 0px 0px inset #303030",
      },
    },
    "mobile-navigation-link": {
      color: "white",
      fontSize: "sm",
      py: "4",
      px: "6",
      _hover: {
        textDecoration: "none",
      },
    },
  },
}

export default link;
