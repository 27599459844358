import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

//Icons
import { BsArrowBarUp } from "react-icons/bs";
import { BsArrowRightShort } from "react-icons/bs";

// Chakra
import { SimpleGrid, Box, Flex, Spacer, Text, Heading, Link } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { Image } from '@chakra-ui/image';
import { FormControl } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Select, Radio, RadioGroup } from '@chakra-ui/react';

class AccountRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      condominium: null,
      condominiums: null,
      apartments: null,
      usertype: 'owner',
      residence: '',
      email: '',
      phone: '',
      street_address: '',
      city: '',
      postnumber: '',
      message: '',
      error: '',
      loading: false,
      userAdded: false,
    }
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.condominiumsInfoGet(false).then((condominiums) => {
      console.log(condominiums);
      this.setState({condominiums});
    });
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  onChangeRadio = (e) => {
    this.setState({
      usertype: e
    });
  }

  condoChange = (e) => {
    let condoId =  e.target.value;
    let activeCondo = this.state.condominiums.find((x) => x.id == e.target.value);
    let apartments = activeCondo ? activeCondo.apartments : null;
    this.setState({
      condominium: condoId,
      apartments
    });
  }

  onSubmit = () => {

    if(
      !this.state.name ||
      !this.state.condominium ||
      !this.state.residence ||
      !this.state.usertype ||
      !this.state.email ||
      !this.state.phone ||
      !this.state.street_address ||
      !this.state.city ||
      !this.state.postnumber 
    ){
      alert("Pakollisia tietoja puuttuu");
      return false;
    }

    const api = new AvainiaCore(LocalStorageService.getToken);
    const payload = {
      name: this.state.name,
      condominium: this.state.condominium,
      residence: this.state.residence,
      usertype: this.state.usertype,
      email: this.state.email,
      phone: this.state.phone,
      street_address: this.state.street_address,
      city: this.state.city,
      postnumber: this.state.postnumber,
      message: this.state.message
    };

    api.newUserRequestCreate(payload).then((request) => {
      if (request.error) { return this.setState({ error: request.error }); }

      this.setState({
        name: '',
        residence: '',
        email: '',
        phone: '',
        street_address: '',
        city: '',
        postnumber: '',
        message: '',
        userAdded: true,
      });
    })
  }

  home() {
    window.location.assign("/")
  }

  render() {
    return (
      <Box className="AccountRequest">
        <Box className="main">
          <Image src="/assets/images/company-logo.png" alt="placeholder" />
        </Box>
        <SimpleGrid columns={ [1, null, 2] } gap={ 4 } rowGap={ 0 }>
          <Box>
            <Box className="welcome-box">
              <Box>
                <Heading mb="4">{I18n.t('accountrequest.welcoming')}</Heading>
                <Text fontSize="md">
                  {I18n.t('accountrequest.portal_info')}
                  <br/><br/>
                  {I18n.t('accountrequest.portal_responsibility')}
                </Text>
              </Box>
            </Box>
            <Box className="logo-boxes"
              _before={{
                content: `""`,
              }}
              _after={{
                content: `""`,
              }}
            >
              <Image src="/assets/images/login-screen.png" alt="placeholder" />
            </Box>
          </Box>
          <Box>
            <Box className="input-box">
              <Heading mb="4">{I18n.t('accountrequest.request_credentials')}</Heading>
              {
                this.state.userAdded
                  ?
                  <>
                    <h4>{I18n.t('general.thank_you')}</h4>
                    <div>{I18n.t('materialforms.get_mail')}</div>
                  </>
                  :
                  <>
                    <FormControl id="accountrequest" pb="4">
                      <Flex>
                        <Input
                          id="name"
                          variant="request-input"
                          name="name"
                          type="name"
                          autoComplete="name"
                          onChange={ (e) => this.onChange(e) }
                          onKeyDown={ this.onKeyDown }
                          autoFocus
                          placeholder={ I18n.t('accountrequest.name') }
                          mb="4"
                          isRequired
                        />
                      </Flex>
                      <Flex>
                        <Select
                          id="condominium"
                          variant="request-input"
                          name="condominium"
                          type="condominium"
                          placeholder={ I18n.t('accountrequest.condominium') }
                          onChange={ this.condoChange }
                          mb="4"
                          isRequired
                          style={{border:"1px solid #707070", borderRadius:"0"}}
                        >
                          {this.state.condominiums && this.state.condominiums.map((condo) =>
                            <option key={'c' + condo.id} value={condo.id}>{condo.name}</option>
                          )}
                        </Select>
                      </Flex>
                      <Flex>
                        <Select
                          id="residence"
                          variant="request-input"
                          name="residence"
                          type="residence"
                          placeholder={ I18n.t('accountrequest.residence') }
                          onChange={ this.onChange }
                          onKeyDown={ this.onKeyDown }
                          mb="4"
                          isRequired
                          style={{border:"1px solid #707070", borderRadius:"0"}}
                        >
                          {this.state.apartments && this.state.apartments.map((apt) =>
                            <option key={'a' + apt.id} value={apt.id}>{apt.name}</option>
                          )}
                        </Select>
                      </Flex>
                      <Flex>
                        <RadioGroup
                          name="usertype"
                          placeholder={ I18n.t('accountrequest.usertype') }
                          onChange={ this.onChangeRadio }
                          value={this.state.usertype}
                          mb="4"
                          isRequired
                        >
                            <Radio key="1" defaultChecked name="usertype" mr="4" value="owner">{ I18n.t('accountrequest.usertype_owner') }</Radio>
                            <Radio key="2" name="usertype" value="tenant">{ I18n.t('accountrequest.usertype_tenant') }</Radio>
                        </RadioGroup>
                      </Flex>
                      <Flex>
                        <Input
                          id="email"
                          variant="request-input"
                          name="email"
                          type="email"
                          placeholder={ I18n.t('accountrequest.email') }
                          autoComplete="email"
                          onChange={ this.onChange }
                          onKeyDown={ this.onKeyDown }
                          mb="4"
                          isRequired
                        />
                      </Flex>
                      <Flex>
                        <Input
                          id="phone"
                          variant="request-input"
                          name="phone"
                          type="phone"
                          placeholder={ I18n.t('accountrequest.phone') }
                          autoComplete="phone"
                          onChange={ this.onChange }
                          onKeyDown={ this.onKeyDown }
                          mb="4"
                          isRequired
                        />
                      </Flex>
                      <Flex>
                        <Input
                          id="street_address"
                          variant="request-input"
                          name="street_address"
                          type="street_address"
                          placeholder={ I18n.t('accountrequest.street_address') }
                          autoComplete="street_address"
                          onChange={ this.onChange }
                          onKeyDown={ this.onKeyDown }
                          mb="4"
                          isRequired
                        />
                      </Flex>
                      <Flex>
                        <Input
                          id="postnumber"
                          variant="request-input"
                          name="postnumber"
                          type="postnumber"
                          placeholder={ I18n.t('accountrequest.postnumber') }
                          autoComplete="postnumber"
                          onChange={ this.onChange }
                          onKeyDown={ this.onKeyDown }
                          mb="4"
                          isRequired
                        />
                      </Flex>
                      <Flex>
                        <Input
                          id="city"
                          variant="request-input"
                          name="city"
                          type="city"
                          placeholder={ I18n.t('accountrequest.city') }
                          autoComplete="city"
                          onChange={ this.onChange }
                          onKeyDown={ this.onKeyDown }
                          mb="4"
                          isRequired
                        />
                      </Flex>
                      <Flex>
                        <Input
                          as="textarea"
                          id="message"
                          variant="request-input"
                          name="message"
                          type="message"
                          placeholder={ I18n.t('accountrequest.message') }
                          autoComplete="message"
                          onChange={ this.onChange }
                          onKeyDown={ this.onKeyDown }
                          mb="4"
                          border="1px solid"
                          borderColor="gray.inputBorder"
                          borderRadius="0"
                          height="130"
                          lineHeight="10"
                        />
                      </Flex>
                    </FormControl>

                    <Flex justify="" mb="10">

                      <Button
                        variant="green-request-btn"
                        textAlign="center"
                        rightIcon={ <BsArrowBarUp size="26" className="icon" /> }
                        disabled={ this.state.loading }
                        type="submit"
                        onClick={ this.onSubmit }
                      >
                        { I18n.t('accountrequest.send-request-btn') }
                      </Button>

                      <Spacer />
                    </Flex>

                    <Box>
                      <Text>{ I18n.t('materialforms.approved') }</Text>
                    </Box>
                  </> }
              <Button
                variant="request-account-btn"
                rightIcon={ <BsArrowRightShort size="32" /> }
                marginTop={ ["0", null, "4"] }
                onClick={ this.home }
              >
                { I18n.t('login.back-home') }
              </Button>

            </Box>
          </Box>
          <Box>
          </Box>
        </SimpleGrid>
      </Box >
    )
  }
}

export default withRouter(AccountRequest);
