import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import I18n from 'i18n-js';

//Partials
import FormHeader from '../../partials/FormHeader/FormHeader.js';
import InputTextboxWithAlert from '../../partials/InputTextboxWithAlert/InputTextboxWithAlert.js';
import InputRadio from '../../partials/InputRadio/InputRadio.js';
import MaterialFormIntro from '../../partials/MaterialFormInstructions/MaterialFormInstructionsIntro.js';
import MaterialFormSelfDelivered from '../../partials/MaterialFormInstructions/MaterialFormInstructionsSelfDelivered.js';

//Chakras
import { Heading, Box, } from '@chakra-ui/layout';

class FormElements extends Component {
  constructor(props) {
    super(props);

    this.state = {
      residents_stay_during_repairs: null,
      info_background: null,
      info_keep: null,
      info_keep_store: null,
      info_keep_reinstall: null,
      disabled: false,
      loading: true,
    };
  }

  componentDidMount = () => {
    const { residents_stay_during_repairs, info_background, info_keep, info_keep_store, info_keep_reinstall } = this.props.submissionForm;
    const { disabled } = this.props;

    this.setState({
      residents_stay_during_repairs,
      info_background,
      info_keep,
      info_keep_store,
      info_keep_reinstall,
      disabled,
      loading: false,
    })
  }

  onChangeCallback = (name, value) => {
    this.setState({
      [name]: value
    }, () => this.props.onChange(name, value))
  }

  render() {
    const { residents_stay_during_repairs, info_background, info_keep, info_keep_store, info_keep_reinstall, disabled } = this.state;

    return (
      <>
        {!this.state.loading &&
          <Box>
            {!this.props.printMode && <Heading>{I18n.t('materialforms.general_instructions')}</Heading>}
            <MaterialFormIntro/>
            <FormHeader printMode={this.props.printMode} text={I18n.t('materialforms.background_information')} />
            <InputRadio
              disabled={disabled}
              radioQ={I18n.t('materialforms.residents_stay_during_repairs')}
              value={residents_stay_during_repairs}
              name="residents_stay_during_repairs"
              onChangeCallback={(name, value) => this.onChangeCallback(name, value)}
            />
            <InputTextboxWithAlert
              disabled={disabled}
              question={I18n.t('materialforms.info_background')}
              value={info_background}
              onChangeCallback={(name, value) => this.props.onChange(name, value)}
              name="info_background"
              printMode={this.props.printMode}
            />

            <div className="print-page-break" />
            <div className="print-page-start" />

            <FormHeader printMode={this.props.printMode} text={I18n.t('materialforms.header_stored_or_reinstalled')} />
            <InputRadio
              disabled={disabled}
              radioQ={I18n.t('materialforms.info_keep')}
              value={info_keep}
              name="info_keep"
              onChangeCallback={(name, value) => this.onChangeCallback(name, value)}
            />
            {info_keep || this.props.printMode ?
              <>
                <InputTextboxWithAlert
                  disabled={disabled}
                  question={I18n.t('materialforms.info_keep_store')}
                  infotext={I18n.t('materialforms.info_keep_store_instructions')}
                  value={info_keep_store}
                  onChangeCallback={(name, value) => this.props.onChange(name, value)}
                  name="info_keep_store"
                  printMode={this.props.printMode}
                />
                <InputTextboxWithAlert
                  disabled={disabled}
                  question={I18n.t('materialforms.info_keep_reinstall')}
                  value={info_keep_reinstall}
                  onChangeCallback={(name, value) => this.props.onChange(name, value)}
                  name="info_keep_reinstall"
                  printMode={this.props.printMode}
                />
              </>
              : <></> // Somehow without this, it will show 0 when chosen "no"
            }

            <MaterialFormSelfDelivered/>

          </Box>
        }
      </>
    )
  }
}


export default withRouter(FormElements);
