import { Link } from '@chakra-ui/layout';
import React, { Component } from 'react';
import I18n from 'i18n-js';

//Icons
import { BsChevronDown } from "react-icons/bs";

import { v4 as uuidv4 } from 'uuid';

class Dropdown extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { links, tab, handleClick } = this.props;
    return (
      <>
        <div className="ApartmentDropdown">
          <button className="button">
            <div className="button-content">
              <label>{I18n.t('general.subpages')}</label>
              <BsChevronDown className="icon" />
            </div>
          </button>
          <div className="dropdown-content">
            {links.map((link) => {
              return (
                <Link key={uuidv4()} textDecoration={tab === link.tab ? "underline" : "none"} onClick={() => handleClick(link.tab)}>
                  <label>{link.title}</label>
                </Link>
              )
            })}
          </div>
        </div>
      </>
    );
  }
}

export default Dropdown;
