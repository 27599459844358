import React, { Component } from "react";
import I18n from 'i18n-js';

// Chakra
import { Box, SimpleGrid } from "@chakra-ui/layout";

import ProductCard from "../ProductCard/ProductCard";
import ProductSelectChip from "../badges/ProductSelectBadge/ProductSelectBadge";
import FormHeader from "../FormHeader/FormHeader";
import AdditionalProductCard from '../AdditionalProductCard/AdditionalProductCard';

class AdditionalProductsSelectGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: null,
      selectedProducts: this.props.productsSelectedFromThisGroup || [],
      disabled: this.props.disabled,
    };
  }

  componentDidMount = () => {
    this.setState({
      products: null,
    })
  }

  toggleProductSelection = (selected, product, isAdded) => {
      this.props.productSelectedCallback(product, isAdded)
  }

  render() {
    return (
      <Box>
        <FormHeader text="Lisätuotteet" />
        <Box pt="10" px={[4, 8, 4, 8]} mb="4" border="4px solid" borderColor="gray.divider" position="relative">
          <ProductSelectChip contentText={I18n.t('materialforms.choose_multiple_products')} />
          <SimpleGrid id={this.props.products.name} columns={[1, 1, 3, 2, 3, 4]}>
            {
              this.props.products.sort((a, b) => a.id - b.id).map((product) => {
                return <AdditionalProductCard
                  disabled={this.state.disabled}
                  id={this.props.id}
                  product={product}
                  productIsSelected={this.state.selectedProducts.find((prod) => prod.id === product.id)}
                  productSelectionToggleCallback={(selected, prod, isAdded) => this.toggleProductSelection(selected, prod, isAdded)}
                  callback={(product, data) => this.props.callback(product, data)}
                  key={this.props.id}
                  selectedProducts={this.state.selectedProducts.find((prod) => prod.id === product.id)}
                  productSelection={this.props.productSelections.find((prod) => prod.additional_product_id === product.id)}
                />;
              })
            }
          </SimpleGrid>
        </Box>
      </Box >
    );
  }
}

export default AdditionalProductsSelectGrid;
