import React, { Component } from 'react';

import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/react";
import { Image } from '@chakra-ui/image';
import { Box, Text } from '@chakra-ui/layout';

import CustomCloseButton from "../buttons/CustomCloseButton/CustomCloseButton.js";
import I18n from 'i18n-js';

         class MaterialFormAttachmentModal extends Component {

          constructor(props) {
            super(props);

            this.state = {};
          }

          componentDidMount = () => {

          }

          render() {

          return (

              <Modal
              trapFocus={false}
              isOpen={this.props.isOpen}
              onClose={this.props.handleClick}
              >
                <ModalOverlay/>
                <ModalContent maxWidth="60vw" flexDirection="row" borderRadius="none" top="4rem"
                >
                  <CustomCloseButton onButtonClick={this.props.handleClick} />
                  <ModalBody d="flex" m="3rem 1.5rem 3rem 3rem" p="0" flexDirection="column" alignItems="center">
                  <Box paddingTop="30px">
                    <Image  src="/assets/images/800x600.png" alt="placeholder" />
                    <br/>
                    <Text fontWeight="bold" align="center">{I18n.t('materialforms.image_caption')}</Text>
                  </Box>
                  </ModalBody>

                </ModalContent>
              </Modal>
              )
           }
        }

export default MaterialFormAttachmentModal;