import React, { Component } from 'react';

import { Button, ButtonGroup, Flex, Modal, ModalOverlay, ModalContent,
         ModalHeader, ModalBody, Textarea, } from "@chakra-ui/react";
import { Box, Heading, Text, Divider, Center } from '@chakra-ui/layout';

import CustomCloseButton from "../buttons/CustomCloseButton/CustomCloseButton.js";
import I18n from 'i18n-js';

class MaterialFormCommentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      select: null,
      comments: null,
      groupName: null
    };
  }

  componentDidMount = () => {
    this.setState({
      select: this.props.select,
      comments: this.props.comments,
      groupName: this.props.groupName
    })
  }

  render() {
    return (
      <Modal
      trapFocus={false}
      isOpen={this.props.isOpen}
      onClose={this.props.handleClick}
      >
        <ModalOverlay/>
        <ModalContent className="MaterialFormCommentModal"
        maxWidth="60vw" flexDirection="row" borderRadius="none" top="4rem"
        >
          <CustomCloseButton onButtonClick={this.props.handleClick} />
          <ModalBody className="modal-body">
            <Flex>
      <Box paddingRight="120">
        <ModalHeader>
        <Heading>{I18n.t('materialforms.comment_offer')}</Heading>
        <Text fontWeight="bold" paddingTop="20px">{this.props.room}</Text>
        </ModalHeader>

        <Box position="relative" top="70px" left="20px">
        <Divider display={{ base: "none", md: "block" }}/>
        <Text color="gray">{I18n.t('materialforms.comment_offer_notice')}</Text>
      </Box>
      </Box>

      <Box className="comment-box">
      <Flex flexDirection="column">
        <Box paddingTop="20px">
        <Textarea className="comment-area" fontSize="14" placeholder={I18n.t('materialforms.enter_comment_here')}></Textarea>
        </Box>
      </Flex>
      <Box>
      <Flex flexDirection="column">
        <Center>
          <ButtonGroup className="button-group">
        <Button variant="green-btn" width="10rem" marginRight="20px">{I18n.t('materialforms.button_save')}</Button>
        <Button className="cancel-btn">{I18n.t('materialforms.button_cancel')}</Button>
        </ButtonGroup>
        </Center>
      </Flex>
      </Box>
      </Box>
    </Flex>
      </ModalBody>
      </ModalContent>
      </Modal>
    )
  }
}

export default MaterialFormCommentModal;