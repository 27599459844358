import React, { Component } from "react";
import I18n from 'i18n-js';

// Chakra
import { Button } from "@chakra-ui/button";

// Components
import ProductModal from "../ProductModal/ProductModal";
import ProductSelectedChip from "../badges/ProductSelectedBadge/ProductSelectedBadge";

// Styling
import "./ProductCard.scss";
import { Box, Image, Text } from "@chakra-ui/react";

export default class ProductCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isSelected: this.props.productIsSelected.product_id === this.props.product.product_id,
      isDisabled: this.props.disabled,
      comment: '',
    };
  }

  componentDidMount = () => {
    let comment = "";
    const selectId = this.props.product.material_form_select_id;
    const productId = this.props.product.product_obj.id;

    if (selectId === this.props.selectedFromThisGroup.material_form_select_id && productId === this.props.selectedFromThisGroup.product_id) {
      comment = this.props.selectedFromThisGroup.comment;
    }

    this.setState({
      comment
    })
  }

  // Remove comment if product selection is deselected
  componentDidUpdate = (prevProps) => {
    const isSelected = this.props.productIsSelected.product_id === this.props.product.product_id;
    const wasSelected = prevProps.productIsSelected.product_id === prevProps.product.product_id;

    if (!isSelected && wasSelected) {
      this.setState({comment: ''});
    }
  }

  toggleSelect = () => {
    this.setState((prevstate) => ({ isSelected: !prevstate.isSelected }), () => {
      this.props.productSelectionToggleCallback(this.state.isSelected, this.props.product);
    });
    this.handleClick();
  };

  handleClick = () => {
    this.setState((prevstate) => ({
      isOpen: !prevstate.isOpen,
    }));
  };

  getCalculatedPrice = (price) => {
    const product = this.props.product;
    const packageSize = product.package_amount;
    const calculatedPrice = (packageSize === 0 || packageSize === null) ? price : packageSize*price;

    return calculatedPrice;
  }

  render() {

    const {
      code,
      description,
      external_link,
      id,
      labor_price,
      manufacturer,
      measurements,
      name,
      product_category_id,
      product_context_photo,
      product_photo,
      price,
      product_id,
      is_contractual
    } = this.props.product;

    const { isDisabled } = this.state;
    const isSelected = this.props.productIsSelected.product_id === product_id;
    const totalPrice = this.getCalculatedPrice(price);

    return (
      <Box key={id} className={`ProductCard ${(isSelected && !this.props.printMode) ? "selected" : ""}`}>
        {this.state.isOpen &&
          <ProductModal
            disabled={isDisabled}
            handleClick={this.handleClick}
            isOpen={this.state.isOpen}
            isSelected={isSelected}
            product={this.props.product.product_obj}
            toggleSelect={this.toggleSelect}
            productIsSelected={this.props.productIsSelected}
            onChange={(name, value) => this.props.onChange(name, value)}
            commentCallback={(product, data, optionId) => {this.props.callback(product, data, optionId); this.setState({comment: data})}}
            comment={this.state.comment}
            selectedFromThisGroup={this.props.selectedFromThisGroup}
            optionId={this.props.product.id} // actual product data is in this.props.product.product_obj
            key={this.props.id}
          />
        }
        {!this.props.printMode && <Box className={`selected-chip ${isSelected ? "selected" : ""}`}>
          {!this.state.comment && <ProductSelectedChip contentText={I18n.t('materialforms.selected')} />}
          {this.state.comment && <ProductSelectedChip contentText={I18n.t('materialforms.selected_commented')} />}
        </Box>}
        <Box onClick={this.handleClick} className="product-span">
          <Image
            className={`product-image ${isSelected ? "selected" : ""}`}
            src={product_photo ? product_photo : "/assets/images/product_img.png"}
            alt={product_photo}
          />
          <Text className="product-number">{I18n.t('materialforms.product_number')} {code}</Text>
          <Text className="product-model">{I18n.t('materialforms.product_model')} {manufacturer}</Text>
          <Text className="product-name">{I18n.t('materialforms.product_name')} {name}</Text>
          <Text className="product-size">{I18n.t('materialforms.product_size')} {this.props.product.product_obj.measurements}</Text>
          { labor_price && labor_price !== "0.00" &&
            <>
              <Text className="compensation-prize">{I18n.t('materialforms.labor_price')} {labor_price} €</Text>
            </>
          }

          { is_contractual ?
            <Text className="contract-price">{I18n.t('materialforms.contract_prize')} {Number(price) + Number(labor_price)} €</Text>
            :
            <>
              <Text className="compensation-prize"> <strong>{I18n.t('materialforms.additional_price')} {Number(totalPrice) + Number(labor_price)} € </strong></Text>
            </>
          }

          <Box className={`dropdown-content ${isSelected ? "selected" : ""}`}>
            <Button
              className="product-select-grid-button"
              variant="green-btn"
              onClick={this.props.handleClick}
            >
              {I18n.t('materialforms.view_product')}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
}
