import React, { Component } from "react";
import Slider from "react-slick";

// Chakra
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

import "./ImageSlider.scss";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ChevronRightIcon
      color="white"
      h="5vw"
      maxW="50px"
      transitionDuration="0.2s"
      w="5vw"
      className={className}
      onClick={onClick}
      style={{ ...style }}
      _hover={{
        color: "gray.darkGray",
      }}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ChevronLeftIcon
      color="white"
      h="5vw"
      maxW="50px"
      transitionDuration="0.2s"
      w="5vw"
      className={className}
      onClick={onClick}
      style={{ ...style }}
      _hover={{
        color: "gray.darkGray",
      }}
    />
  );
}

export default class ImageSlider extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const settings = {
      customPaging: function (i) {
        // return array of thumbnails
        return (
          <a>
            <img src={pagination[i]} />
          </a>
        );
      },
      dots: true,
      dotsClass: "slick-dots slick-thumb",
      infinite: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };

    const pagination = [];

    // push thumbnail sources to array
    this.props.images.map((source) => {
      pagination.push(source.imageSrc);
    });

    return (
      <div className="ImageSlider">
        <Slider className="slider" {...settings}>
          {this.props.images.map((image) => {
            return (
              <div key={image.imageId}>
                <img src={image.imageSrc} alt={image.imageAlt} />
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}
