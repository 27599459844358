import React, { Component } from "react";

// Chakra
import { CloseIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";

// Styling
import "./CustomCloseButton.scss";

export default class CloseButton extends Component {
  handleClick = () => {
    this.props.onButtonClick(this.props.value);
  };

  render() {
    return (
      <Button
        alignItems="center"
        display="flex"
        flexShrink="0"
        justifyContent="center"
        onClick={this.handleClick}
        outline="0"
        position="absolute"
        right="1.5rem"
        rightIcon={<CloseIcon />}
        size="sm"
        top="1.5rem"
        variant="ghost"
        zIndex="1"
      >
        {this.props.contentText}
      </Button>
    );
  }
}
