import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import I18n from 'i18n-js';

// Components
import MaterialFormTab from './MaterialFormTab.js';

// Chakra
import { Box } from '@chakra-ui/react';

class MaterialFormTabs extends Component {
  render() {
    return (
      <Box
        display={'flex'}
        borderStyle={'solid none none solid'}
        borderWidth={'1px'}
        borderColor={'gray.inputBorder'}
      >
        <MaterialFormTab
          active={this.props.activePhase === 1 ?? false}
          disabled={false}
          content={I18n.t('materialforms.phases.materialform')}
          phaseNumber={'1'}
          tabCallback={() => this.props.tabCallback(1)}
        />
        <MaterialFormTab
          active={this.props.activePhase === 2 ?? false}
          disabled={this.props.phases >= 2 ? false : true}
          content={I18n.t('materialforms.phases.quote')}
          phaseNumber={'2'}
          tabCallback={() => this.props.tabCallback(2)}
        />
        <MaterialFormTab
          active={this.props.activePhase === 3 ?? false}
          disabled={this.props.phases !== 3}
          content={I18n.t('materialforms.phases.room-card')}
          phaseNumber={'3'}
          tabCallback={() => this.props.tabCallback(3)}
        />
      </Box>
    );
  }
}

export default withRouter(MaterialFormTabs);
