import React, { Component } from 'react';

// Chakra
import { Box, Flex, Text } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

export default class ProductSelectBadge extends Component {
  render() {
    return (
      <Flex
        alignItems="center"
        bg="black"
        borderRadius="20px"
        color="base.yellow"
        mr="-1.25rem"
        p="0.4rem 0.5rem"
        position="absolute"
        right="0"
        top="-1.5rem"
        zIndex="1"
      >
        <Box
          border="1px solid yellow"
          borderRadius="20px"
          lineHeight="0"
          marginLeft="0.1rem"
        >
          <CheckIcon h="0.75rem" w="0.75rem" p="3px" />
        </Box>
        <Text fontSize="14px" m="0 0.75rem">
          {this.props.contentText}
        </Text>
      </Flex>
    );
  }
}
