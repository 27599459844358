import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

//Chakras
import { Text } from '@chakra-ui/layout';

class WorkSafety extends Component {
  render() {
    return (
      <>
        <p>Meille on myönnetty Rakentamisen Laatu-pätevyys (RALA-pätevyys) useasta rakennusalan osaamisalueesta sekä toimintajärjestelmäsertifikaatti linjasaneerausten pääurakoinnin toimintamallista. RALA ry arvioi toimintamme vaatimusten mukaisuuttaa vuosittain. VRJ Groupilla on käytössään myös ISO 9001:2000 mukainen laatujärjestelmä, jota on täydennetty ISO 14001-mukaisella ympäristöjärjestelmällä.</p>
        <br></br>
        <p>Laatujärjestelmä helpottaa yrityksen johtamista, tukee toiminnan kehittämistä ja varmistaa asiakkaiden tyytyväisyyden tuotteisiimme ja palveluihimme. Siihen on kirjattu yhteisesti sovitut pelisäännöt, yrityksen tulevaisuuden suunnitelmat sekä tarvittavat ohjeet työntekijöille. Laatujärjestelmässä on määritelty myös mm. henkilöstön toimintaohjeet ja vastuualueet sekä työ-, palo- ja ympäristönsuojeluvaatimukset.</p>
        <br></br>
        <p>Laadimme jo hankkeen valmisteluvaiheessa kohteelle räätälöidyt laatu-, työturvallisuus- ja valvontasuunnitelmat. Dokumentoimme, valvomme ja tarkistamme oma työtämme monilla eri keinoilla. Kohteen alkaessa laaditut dokumentit luovutetaan aina myös tilaajalle luovutusasiakirjojen mukana.</p>
        <br></br>
        <p>Noudatamme kohteessa kaikkia työturvallisuuslakeja sekä määräyksiä ja olemme mukana kehittämässä työturvallisuutta myös tulevaisuudessa. Apuna työturvallisuuden valvonnassa ja seurannassa ovat käyttämämme uusimmat työturvallisuusohjelmistot.</p>
      </>
    )
  }
}

export default withRouter(WorkSafety);
