import { extendTheme } from '@chakra-ui/react';

import colors from './foundations/colors';
import styles from './styles';

import Button from './components/button';
import Input from './components/input';
import Link from './components/link';
import Heading from './components/heading';
import Text from './components/text';
import Select from './components/select';
import Divider from './components/divider';
import Menu from './components/menu';
import Textarea from './components/textarea';



const theme = extendTheme({
  fonts: {
    heading: "proxima-nova",
    body: "proxima-nova",
  },
  colors,
  styles,
  components: {
    Button,
    Input,
    Link,
    Heading,
    Text,
    Select,
    Divider,
    Menu,
    Textarea
  },
})

export default theme;
