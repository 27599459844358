import React, { Component } from 'react';

//Chakras
import { Button, Flex } from "@chakra-ui/react";
import { Box, Text, Heading } from '@chakra-ui/layout';

//Icons
import Icon from '@chakra-ui/icon';
import { BsPencil } from 'react-icons/bs';

//Partials
import MaterialFormAttachmentModal from '../../partials/MaterialFormAttachmentModal/MaterialFormAttachmentModal.js';
import I18n from 'i18n-js';


class MaterialSummaryGeneral extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = () => {
    this.setState((prevstate) => ({ isOpen: !prevstate.isOpen, }));
  }

  componentDidMount = () => {

  }

  render() {

    return (
      <Box className="general-information">

      <MaterialFormAttachmentModal isOpen={this.state.isOpen} handleClick={this.handleClick} />

        <Box className="general-information-outer" p={4, 8, 4, 8}>
          <Heading className="summary-header">{I18n.t('materialforms.general_info')}</Heading>
          <div onClick={this.handleClick} className="MaterialSummaryGeneral">
          <Button className="edit-button1">
        <Icon as={BsPencil} w={7} h={7} color="black" marginRight="3"
              onClick={() => this.setState({ isOpen: true })}/>{I18n.t('materialforms.edit_choices')}</Button>
        <Box className="general-information-inner" p={2, 4, 2, 4}>
        <Flex>
          <Box w="230px" h="16" bg="white">
            <Text fontWeight="bold">{I18n.t('materialforms.residents_staying_during_repairs')}</Text>
          </Box>
          <Box className="answer">
          <Text>{this.props.ans1}</Text>
          </Box>
        </Flex>

        <Flex>
        <Box w="230px" h="16">
            <Text fontWeight="bold">{I18n.t('materialforms.repairs')}</Text>
          </Box>
          <Box className="answer">
            <Text>{this.props.ans2}</Text>
          </Box>
        </Flex>

        <Flex>
        <Box w="230px" h="16">
            <Text fontWeight="bold">{I18n.t('materialforms.keep_furniture')}</Text>
          </Box>
          <Box className="answer">
            <Text>{this.props.ans3}</Text>
          </Box>
        </Flex>

        <Flex>
        <Box w="230px" h="16">
            <Text fontWeight="bold">{I18n.t('materialforms.store_furniture')}</Text>
          </Box>
          <Box className="answer">
          <Text>{this.props.ans4}</Text>
          </Box>
        </Flex>

        <Flex>
        <Box w="230px" h="16">
            <Text fontWeight="bold">{I18n.t('materialforms.reinstall_furniture')}</Text>
          </Box>
          <Box className="answer">
            <Text>{this.props.ans5}</Text>
          </Box>
        </Flex>
        </Box>
        </div>
        </Box>
        </Box>
    )
  }
}

export default MaterialSummaryGeneral;