import React, { Component } from "react";
import moment from "moment";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsGantt from "highcharts/modules/gantt";
import I18n from "i18n-js";

highchartsGantt(Highcharts);

class SchedulerGraph extends Component {
  render() {
    const isObject = Highcharts.isObject;

    Highcharts.setOptions({
      lang: {
        decimalPoint: ",",
        months: [
          I18n.t("calendar.months.january"),
          I18n.t("calendar.months.february"),
          I18n.t("calendar.months.march"),
          I18n.t("calendar.months.april"),
          I18n.t("calendar.months.may"),
          I18n.t("calendar.months.june"),
          I18n.t("calendar.months.july"),
          I18n.t("calendar.months.august"),
          I18n.t("calendar.months.september"),
          I18n.t("calendar.months.october"),
          I18n.t("calendar.months.november"),
          I18n.t("calendar.months.december"),
        ],
        shortMonths: [
          I18n.t("calendar.shortMonths.jan"),
          I18n.t("calendar.shortMonths.feb"),
          I18n.t("calendar.shortMonths.mar"),
          I18n.t("calendar.shortMonths.apr"),
          I18n.t("calendar.shortMonths.may"),
          I18n.t("calendar.shortMonths.jun"),
          I18n.t("calendar.shortMonths.jul"),
          I18n.t("calendar.shortMonths.aug"),
          I18n.t("calendar.shortMonths.sep"),
          I18n.t("calendar.shortMonths.oct"),
          I18n.t("calendar.shortMonths.nov"),
          I18n.t("calendar.shortMonths.dec"),
        ],
        shortWeekdays: [
          I18n.t("calendar.shortWeekdays.su"),
          I18n.t("calendar.shortWeekdays.mo"),
          I18n.t("calendar.shortWeekdays.tu"),
          I18n.t("calendar.shortWeekdays.we"),
          I18n.t("calendar.shortWeekdays.th"),
          I18n.t("calendar.shortWeekdays.fr"),
          I18n.t("calendar.shortWeekdays.sa"),
        ],
        weekdays: [
          I18n.t("calendar.weekdays.tuesday"),
          I18n.t("calendar.weekdays.wednesday"),
          I18n.t("calendar.weekdays.thursday"),
          I18n.t("calendar.weekdays.friday"),
          I18n.t("calendar.weekdays.saturday"),
          I18n.t("calendar.weekdays.sunday"),
          I18n.t("calendar.weekdays.monday"),
        ],
      },
    });

    // Now this just adds the values to new key names, could the key names be replaced?
    let scheduleStartDate = new Date().getTime();
    let scheduleEndDate = new Date().getTime();

    const schedulesToRender = this.props.schedules.map((s) => {
      const start = new Date(s.start_at).getTime();
      const end = new Date(s.end_at).getTime();

      if (start < scheduleStartDate) {
        scheduleStartDate = start;
      }
      if (end > scheduleEndDate) {
        scheduleEndDate = end;
      }

      return {
        name: s.description,
        id: s.id + "",
        start: new Date(s.start_at).getTime(),
        end: new Date(s.end_at).getTime(),
        estimate_start: new Date(s.estimate_start_at).getTime(),
        estimate_end: new Date(s.estimate_end_at).getTime(),
        parent: s.parent_id,
        completed: s.completion_percentage / 100,
      };
    });

    // Add +/- 3 days to both sides of the graph for readability
    scheduleStartDate -= 259200000;
    scheduleEndDate += 259200000;

    return (
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"ganttChart"}
        options={{
          data: {
            parseDate: Date.parse,
          },
          series: [
            {
              name: "Tasks",
              data: schedulesToRender,
            },
          ],
          tooltip: {
            pointFormatter: function () {
              const point = this; // ewwww // Shh Joel it works.
              const { options } = point;
              const { completed } = options;
              const amount = isObject(completed) ? completed.amount : completed;
              const status = `${(amount || 0) * 100}%`;
              const estimateStart =
                point.estimate_start !== 0
                  ? moment(new Date(point.estimate_start)).format("yyyy-MM-DD")
                  : null;
              const estimateEnd =
                point.estimate_end !== 0
                  ? moment(new Date(point.estimate_end)).format("yyyy-MM-DD")
                  : null;

              const lines = [
                {
                  visible: true,
                  title: I18n.t("schedules.name"),
                  value: point.name,
                },
                {
                  visible: true,
                  title: I18n.t("schedules.start"),
                  value: moment(new Date(point.start)).format("yyyy-MM-DD"),
                },
                {
                  visible: true,
                  title: I18n.t("schedules.end"),
                  value: moment(new Date(point.end)).format("yyyy-MM-DD"),
                },
                {
                  visible: point.estimate_end !== 0,
                  title: I18n.t("schedules.estimate-start"),
                  value: estimateStart,
                },
                {
                  visible: point.estimate_end !== 0,
                  title: I18n.t("schedules.estimate-end"),
                  value: estimateEnd,
                },
                /* {
                  visible: true,
                  title: I18n.t("schedules.completed"),
                  value: status,
                }, */
              ];

              return lines.reduce((accumulator, line) => {
                if (line.visible) {
                  return (
                    accumulator +
                    '<span style="font-size: 0.8em;>' +
                    (line.title || "") +
                    " " +
                    (line.value || "") +
                    "</span><br />"
                  );
                } else {
                  return accumulator;
                }
              }, "");
            },
          },
          title: {
            text: I18n.t("schedules.schedule"),
          },
          // navigator: {
          //   enabled: true,
          //   liveRedraw: true,
          //   series: {
          //     type: 'gantt',
          //   },
          //   scrollbar: {
          //     enabled: true,
          //   },
          //   time: {
          //     useUTC: false,
          //   },
          //   yAxis: {
          //     min: 0,
          //     max: 3,
          //     reversed: true,
          //     categories: [],
          //   },
          // },
          // xAxis: [{
          //   dateTimeLabelFormats: {
          //     week: {
          //       list: [`${I18n.t('calendar.week')} %W`, 'S%W']
          //     }
          //   }
          // }, {
          //   dateTimeLabelFormats: {
          //     week: {
          //       list: [`${I18n.t('calendar.week')} %W`, 'S%W']
          //     }
          //   }
          // }],
          xAxis: [
            {
              dateTimeLabelFormats: {
                week: {
                  list: [`${I18n.t("calendar.week")} %W`, "S%W"],
                },
              },
            },
            {
              dateTimeLabelFormats: {
                week: {
                  list: [`${I18n.t("calendar.week")} %W`, "S%W"],
                },
              },
            },
          ],
          //   xAxis: {
          //     startOfWeek: 1,
          //     type: 'datetime',
          //     currentDateIndicator: true,
          //     min: scheduleStartDate,
          //     max: scheduleEndDate,
          // },
          navigator: {
            enabled: true,
            liveRedraw: true,
            series: {
              type: "gantt",
            },
            yAxis: {
              min: 0,
              max: 3,
              reversed: true,
              categories: [],
            },
          },
          scrollbar: {
            enabled: true,
          },
          time: {
            useUTC: false,
          },
        }}
      />
    );
  }
}

export default SchedulerGraph;
