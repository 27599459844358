import React, { Component } from 'react';

import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import AvainiaCore from 'avainia-core-api';
import ContactCard from '../ContactCard/ContactCard.js'
import { SimpleGrid } from '@chakra-ui/layout';

class ContactCards extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.projectContactsGet(this.props.projectId, 'contacts').then((data) => {
      if (data.error) { return this.setState({ error: data.error }); }

      this.setState({
        contacts: data.contacts,
        loading: false,
      });
    })
  }

  render() {
    const { contacts, loading } = this.state;
    return (!loading &&
      <SimpleGrid columns={[1, null, null, 2, 3]} spacing="4">
        {contacts && contacts.map(contact => {
          return (
            <ContactCard key={contact.id} contact={contact} />
          )
        })}
      </SimpleGrid>
    )
  }
}

export default ContactCards;
