const menu = {
  baseStyle: {
    list: {
      color: "white",
      bg: "gray.darkGray",
      height: "100%",
      border: "0px",
      borderRadius: "0px",
      boxShadow: "none",
    },
    item: {
      _hover: {
        bg: "gray.darkGray"
      }
    },
  },
}

export default menu;
