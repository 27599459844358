import React, { Component } from "react";

// Chakra
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";

// Styling
import "./ProductSelectButton.scss";

export default class ProductSelect extends Component {
  render() {
    const isSelected = this.props.isSelected;
    const isDisabled = this.props.isDisabled;
    const canSelectMultiple = this.props.canSelectMultiple;

    if (!isSelected) {
      return (
        <Button
          variant="green-btn"
          className="ProductSelectButton"
          onClick={this.props.toggleSelect}
          isDisabled={isDisabled}
        >
          {this.props.contentText}
          <CheckIcon color="green.light" className="select-icon" />
        </Button>
      );
    } else {
      return (
        <>
          {!canSelectMultiple && (
            <Button
              variant="green-btn"
              className="ProductSelectButton"
              onClick={this.props.toggleSelect}
              isDisabled={true}
            >
              {this.props.contentText}
              <CheckIcon color="green.light" className="select-icon" />
            </Button>
          )}

          {canSelectMultiple && (
            <Button
              variant="red-delete-button"
              className="ProductSelectButton"
              onClick={this.props.toggleSelect}
              isDisabled={isDisabled}
            >
              {this.props.contentText}
              <CloseIcon color="#E53E3E" className="deselect-icon" />
            </Button>
          )}
        </>
      );
    }
  }
}
