import React, { Component } from "react";

import AvainiaCore from "avainia-core-api";
import I18n from "i18n-js";

import SchedulerGraph from "../../partials/SchedulerGraph/SchedulerGraph";
import CustomCloseButton from "../../partials/buttons/CustomCloseButton/CustomCloseButton";

import ScheduleService from "../../../AvainiaTools/ScheduleService.js";
import LocalStorageService from "../../../AvainiaTools/LocalStorageService";

// Chakra
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  Center
} from "@chakra-ui/react";
import { Spinner } from '@chakra-ui/spinner';

export default class SchedulesPage extends Component {
  constructor(props) {
    super(props);

    /* TODO clean up unnecessary states*/
    this.state = {
      /* baseKey: 0,
      completion: null,
      description: "",
      start_at: "",
      end_at: "",
      parent_id: 0, */
      schedules: [],
      schedulesSorted: [],
      /* scheduleToEdit: false, */
      modal: false,
      /* graphProps: false, */
      /* error: false, */
      loading: true,
    };
  }

  componentDidMount() {
    const api = new AvainiaCore(LocalStorageService.getToken);

    ScheduleService.getSchedules(this.props.projectId).then((data) => {
      const schedulesSorted = data.sorted;
      const schedules = data.unsorted;

      api.projectStatusesGet().then((projectStatuses) => {
        if (projectStatuses.error) {
          return;
        }

        this.setState({
          projectStatuses,
          schedulesSorted,
          schedules,
          loading: false,
        });
      });
    });
  }

  handleClick = () => {
    this.setState((prevstate) => ({
      modal: !prevstate.modal,
    }));
  };

  render() {
    return (
      <>
        {this.state.loading &&
          <Center>
            <Spinner size="xl" />
          </Center>
        }
        {!this.state.loading &&
          <>
            <Modal
              isOpen={this.state.modal}
              onClose={this.handleClick}
              trapFocus={false} /* TODO maybe remove because of accessibility? */
            >
              <ModalOverlay />
              <ModalContent minW="90vw">
                <CustomCloseButton
                  onButtonClick={this.handleClick}
                  contentText={I18n.t('general.close')}
                />
                <ModalBody p="0">
                  <Box p="3.5rem 2rem">
                    {!this.state.loading && (
                      <SchedulerGraph schedules={this.state.schedules} />
                    )}
                  </Box>
                </ModalBody>
              </ModalContent>
            </Modal>
            <Box bg="white" p={[4, 8, 4, 8]}>
              <Flex
                alignItems="center"
                alignContent="center"
                justifyContent="space-between"
              >
                <Heading lineHeight="1.2">
                  {I18n.t('schedules.schedules')}
                </Heading>
                <div style={{position: 'relative'}}>
                  <Button
                    bgColor="base.yellow"
                    borderRadius="0"
                    onClick={this.handleClick}
                    position="absolute"
                    right="0"
                    bottom="-15px"
                  >
                    <Text size="1rem" fontWeight="bold" textTransform="uppercase">
                      {I18n.t('schedules.expand_schedule')}
                    </Text>
                  </Button>
                </div>
              </Flex>
              <Divider display={{ base: "none", md: "block" }} width="100%" />
              {/* TODO add <Loading /> here and to the modal component */}
              {!this.state.loading && (
                <SchedulerGraph schedules={this.state.schedules} />
              )}
            </Box>
          </>
        }
      </>
    );
  }
}
