import React, { Component } from 'react';

//Chakra
import { Box, Heading, Text } from '@chakra-ui/layout';

class ContactCard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {

  }

  render() {
    const { company, company_address, company_opening_times, email, first_name, id, job_description, last_name, phone, title } = this.props.contact;

    return (
      <Box
        key={id}
        className="contact-card"
        textAlign="center"
        border={!this.props.inFrontPage ? "2px solid" : "none"}
        borderColor="gray.divider"
        p={!this.props.inFrontPage ? [4, 8, 4, 8] : "0"}
      >
        <Heading mb="2">{company}</Heading>
        <Text>{job_description}</Text>
        <Text>{title}</Text>
        <Text fontWeight="bold">{first_name} {last_name}</Text>
        <Text>{phone} {company_opening_times}</Text>
        <Text>{email}</Text>
        <Text>{company_address}</Text>
      </Box>
    )
  }
}

export default ContactCard;
