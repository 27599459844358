import React, { Component } from 'react';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import AvainiaCore from 'avainia-core-api';
import I18n from 'i18n-js';
//Components
import CondominiumCard from '../../partials/CondominiumCard/CondominiumCard.js';

//Slick slider
import Slider from "react-slick";

// Chakra
import { SimpleGrid } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';
import { Heading } from '@chakra-ui/layout';

class CondominiumChoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
    }
  }

  componentDidMount = () => {
    const user = LocalStorageService.getUser();
    const api = new AvainiaCore(LocalStorageService.getToken);

    if (user) {
      // Haetaan käyttäjän projektit
      api.projectsGet().then((projects) => {
        if (projects.error) { return this.setState({ error: projects.error }); }

        let condominiumIds = [];

        // Tehdään array jossa eri condojen uniikit id:t
        projects.map((project) => {
          if (project.condominiumProject && project.type === 'condominium' && !project.deleted_at) {
            condominiumIds.push(project.condominiumProject.condominium_id)
          } return null;
        })

        const uniqueCondominiumIds = [...new Set(condominiumIds)];
        let condoProjects = [];
        let condominiums = [];

        // haetaan condojen tiedot per uniikki id ja laitetaan ne stateen
        uniqueCondominiumIds.forEach((id) => {

          api.condominiumGet(id).then((condominium) => {
            if (condominium.error) { return this.setState({ error: condominium.error }); }
            condominiums.push(condominium);

            api.condominiumProjectsGet(id).then((condominiumProjects) => {
              if (condominiumProjects.error) { return this.setState({ error: condominiumProjects.error }); }
              condoProjects.push(condominiumProjects)

              this.setState((prevState) => ({
                user,
                projects,
                condoProjects,
                condominiums,
                loading: false,
              }));
            });
          });
        })
      });
    }
  }

  render() {
    const { name, email } = this.state.user;
    const { projects, condominiums } = this.state;
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      slidesToShow: 1,
      speed: 500,
      arrows: false,
    };

    return (
      <>
        <Heading display={["block", null, "none"]} fontSize="md" py="4" pl="4">{I18n.t('navigation.condosAndApartments')}</Heading>
        <Box className="CondominiumChoice">
          <SimpleGrid columns={[2, null, 2, 3, 4]} gap={4} rowGap={4}>
            {condominiums && condominiums.sort((a, b) => a.id.toString().localeCompare(b.id.toString())).map(condominium => {
              return (
                <Box display={["none", "block"]}>
                  <CondominiumCard
                    id={condominium.id}
                    path={`/condominium/${condominium.id}`}
                    handleClick={() => LocalStorageService.setCondominium(condominium)}
                    name={condominium.name}
                    street_address={condominium.street_address}
                    postcode={condominium.postcode}
                    post_office={condominium.post_office}
                    photo={condominium.condominium_photo}
                  />
                </Box>
              )
            })}
          </SimpleGrid>
          <Box display={["block", "none"]}>
            <Slider {...settings}>
              {condominiums && condominiums.sort((a, b) => a.id.toString().localeCompare(b.id.toString())).map(condominium => {
                return (
                  <CondominiumCard
                    id={condominium.id}
                    path={`/condominium/${condominium.id}`}
                    handleClick={() => LocalStorageService.setCondominium(condominium)}
                    name={condominium.name}
                    street_address={condominium.street_address}
                    postcode={condominium.postcode}
                    post_office={condominium.post_office}
                  />
                )
              })}
            </Slider>
          </Box>
        </Box>
      </>
    )
  }
}

export default CondominiumChoice;
