import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// DO NOT REMOVE
// This will NOT work if you remove the "unnecessary" ones from react-scroll
import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import I18n from 'i18n-js';

import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import AvainiaCore from 'avainia-core-api';

// Components
import MaterialFormPrintable from '../MaterialForm/MaterialFormPrintable.js';
import CurrentApartment from '../CurrentApartment/CurrentApartment.js';
import MaterialFormSidebar from '../MaterialFormSidebar/MaterialFormSidebar.js';
import MaterialFormStatus from '../MaterialFormStatus/MaterialFormStatus.js';
import MaterialForm from '../MaterialForm/MaterialForm.js';
import MaterialFormOffer from '../../layouts/MaterialFormOffer/MaterialFormOffer.js';

//Chakra
import { Box, GridItem, SimpleGrid } from '@chakra-ui/react';

class MaterialFormInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      project: null,
      apartment: null,
      materialforms: null,
      apartmentTemplates: null,
      targetMaterialform: false,
      materialFormWithOffer: null,
      submissionFormForOffer: null,
      targetMaterialformDisabled: null,
      totalPrice: 0,
      formPrintMode: false,
      menuItem: {
        id: 0,
        name: I18n.t('materialforms.general_instructions'),
      },
      activeMenuItem: {
        id: 0,
        name: I18n.t('materialforms.general_instructions'),
      },
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    const condominium = LocalStorageService.getCondominium();
    const project = LocalStorageService.getProject();
    const apt = LocalStorageService.getApartment();

    condominium
      ? api
          .condominiumApartmentGet(condominium.id, apt.id)
          .then((apartment) => {
            if (apartment.error) {
              return this.setState({ error: apartment.error });
            }

            api.projectMaterialFormsGet(project.id).then((materialforms) => {
              if (materialforms.error) {
                return this.setState({ error: materialforms.error });
              }

              api
                .condominiumApartmentTemplatesGet(condominium.id)
                .then((apartmentTemplates) => {
                  if (apartmentTemplates.error) {
                    return this.setState({ error: apartmentTemplates.error });
                  }

                  api
                    .getMaterialFormSubmissionsByApartment(apt.id)
                    .then((submissionForms) => {
                      if (submissionForms.error) {
                        return this.setState({ error: submissionForms.error });
                      }

                      api.getOpenQuotesByApartment(apt.id).then((quotes) => {
                        // eslint-disable-next-line no-param-reassign
                        if (quotes.error) {
                          quotes =
                            []; /* this is because no quotes === 404 error. */
                        }

                        const templatePromises = apartmentTemplates
                          .filter(
                            (template) =>
                              template.id === apartment.apartment_template_id
                          )
                          .map((t) =>
                            api.condominiumApartmentTemplateMaterialformsGet(
                              t.id
                            )
                          );

                        Promise.all(templatePromises).then((data) => {
                          let error = false;
                          data.forEach((materialforms) => {
                            if (error) {
                              return;
                            }
                            if (materialforms.error) {
                              error = true;
                              return;
                            }
                            apartmentTemplates.forEach((template) => {
                              template.materialforms = materialforms;
                            });
                          });

                          this.setState(
                            {
                              quotes,
                              project,
                              apartment,
                              materialforms,
                              submissionForms,
                              apartmentTemplates: apartmentTemplates.filter(
                                (template) =>
                                  template.id ===
                                  apartment.apartment_template_id
                              ),
                            },
                            this.setState({ loading: false })
                          );
                        });
                      });
                    });
                });
            });
          })
      : this.props.history.push(`/`);
  };

  activate = (materialform, disabled, submissionForm, printable) => {
    this.setState({
      targetMaterialform: materialform,
      targetMaterialformDisabled: disabled,
      targetMaterialSubmissionForm: submissionForm,
      formPrintMode: printable,
    });
  };

  disable = () => {
    window.location.reload();
  };

  materialformPriceCallback = (price) => {
    this.setPrice(price);
  };

  setPrice = (price) => {
    if (this.state.callbackPrice === 0 || this.state.callbackPrice !== price) {
      this.setState({
        callbackPrice: price,
      });
    }
  };

  activeMenuItemCallback = (menuItem) => {
    this.setState({ menuItem, activeMenuItem: menuItem });
  };

  activeSubmenuItemCallback = (submenuItem) => {
    this.setState({ activeMenuItem: submenuItem }, () =>
      this.scrollTo(submenuItem.name)
    );
  };

  scrollTo(el) {
    scroller.scrollTo(el, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  }

  openMaterialFormOffer = (form, submissionForm, quote) => {
    this.setState({
      materialFormWithOffer: form,
      submissionFormForOffer: submissionForm,
      targetQuote: quote,
      targetQuoteDisabled: quote.status === 'approved',
    });
  };

  closeOffer = () => {
    window.location.reload();
  };

  render() {
    return (
      <>
        <SimpleGrid columns={8} gap={4}>
          <GridItem colSpan={[8, null, null, 3, 2, 2]}>
            <Box display={{ base: 'none', lg: 'block' }}>
              <CurrentApartment condominium={this.props.match.params.id} />
            </Box>
            {!this.state.formPrintMode && (
              <MaterialFormSidebar
                totalPrice={this.state.callbackPrice}
                visible={this.state.targetMaterialform ? true : false}
                materialform={this.state.targetMaterialform}
                activeMenuItem={this.state.activeMenuItem}
                activeMenuItemCallback={(menuItem) =>
                  this.activeMenuItemCallback(menuItem)
                }
                activeSubmenuItemCallback={(submenuItem) =>
                  this.activeSubmenuItemCallback(submenuItem)
                }
                materialFormSubmission={this.state.targetMaterialSubmissionForm}
              />
            )}
          </GridItem>
          <GridItem
            bg="white"
            p={[4, 8, 4, 8]}
            colSpan={{ base: '8', lg: '5', xl: '6' }}
            mb="8"
          >
            {(!this.state.targetMaterialform &&
              !this.state.materialFormWithOffer) && (
              <MaterialFormStatus
                loading={this.state.loading}
                targetMaterialform={this.state.targetMaterialform}
                targetMaterialformDisabled={
                  this.state.targetMaterialformDisabled
                }
                targetMaterialSubmissionForm={
                  this.state.targetMaterialSubmissionForm
                }
                printMode={this.state.printMode}
                apartmentTemplates={this.state.apartmentTemplates}
                submissionForms={this.state.submissionForms}
                apartment={this.state.apartment}
                quotes={this.state.quotes}
                activateCallback={(
                  materialform,
                  disabled,
                  submissionForm,
                  printable
                ) =>
                  this.activate(
                    materialform,
                    disabled,
                    submissionForm,
                    printable
                  )
                }
                openMaterialFormOfferCallback={(
                  form,
                  submissionForm,
                  quote,
                ) =>
                  this.openMaterialFormOffer(
                    form,
                    submissionForm,
                    quote,
                  )
                }
              />
            )}
            {this.state.formPrintMode && (
              <MaterialFormPrintable
                disabled={this.state.targetMaterialformDisabled}
                disable={this.disable}
                mf={this.state.targetMaterialform}
                apartment={this.state.apartment}
                project={this.state.project}
                materialformPriceCallback={(price) =>
                  this.materialformPriceCallback(price)
                }
                category={this.state.menuItem}
                activeMenuItemCallback={(menuItem) =>
                  this.activeMenuItemCallback(menuItem)
                }
                activeSubmenuItemCallback={(submenuItem) =>
                  this.activeSubmenuItemCallback(submenuItem)
                }
                targetMaterialSubmissionForm={
                  this.state.targetMaterialSubmissionForm
                }
              />
            )}
            {!this.state.formPrintMode && this.state.targetMaterialform && (
              <MaterialForm
                disabled={this.state.targetMaterialformDisabled}
                disable={this.disable}
                mf={this.state.targetMaterialform}
                apartment={this.state.apartment}
                project={this.state.project}
                materialformPriceCallback={(price) =>
                  this.materialformPriceCallback(price)
                }
                category={this.state.menuItem}
                activeMenuItemCallback={(menuItem) =>
                  this.activeMenuItemCallback(menuItem)
                }
                activeSubmenuItemCallback={(submenuItem) =>
                  this.activeSubmenuItemCallback(submenuItem)
                }
                targetMaterialSubmissionForm={
                  this.state.targetMaterialSubmissionForm
                }
              />
            )}
            {this.state.materialFormWithOffer &&
              this.state.submissionFormForOffer && (
                <MaterialFormOffer
                  closeOffer={this.closeOffer}
                  apartment={this.state.apartment}
                  materialForm={this.state.materialFormWithOffer}
                  project={this.state.project}
                  submissionForm={this.state.submissionFormForOffer}
                  quote={this.state.targetQuote}
                  disabled={this.state.targetQuoteDisabled}
                  disable={this.closeOffer}
                />
              )}
          </GridItem>
        </SimpleGrid>
      </>
    );
  }
}

export default withRouter(MaterialFormInfo);
