import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import I18n from 'i18n-js';
import translations from './config/translations.js';

I18n.translations = translations;
I18n.defaultLocale = 'fi';
I18n.missingTranslation = (key) => { console.log(`Missing translation '${key}`); return '???'; };
window.I18n = I18n;

let detectedLanguage = 'fi';

if (translations[detectedLanguage]) {
  I18n.locale = detectedLanguage;
} else {
  console.log(`Detected language ${detectedLanguage} is not available, falling back to ${I18n.currentLocale()}`);
}

// Setup global helper
window.Avainia = {
  core: process.env.REACT_APP_API_HOST,
  version: process.env.REACT_APP_COMMIT,
};


ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('root'));
