import React, { Component } from 'react';

//Chakras
import { Button, Flex, Badge, Textarea } from "@chakra-ui/react";
import { Box, Text, Heading } from '@chakra-ui/layout';
import I18n from 'i18n-js';

//Icons
import Icon from '@chakra-ui/icon';
import { BsPencil } from 'react-icons/bs';

//Partials
import MaterialFormAttachmentModal from '../../partials/MaterialFormAttachmentModal/MaterialFormAttachmentModal.js';
import MaterialFormOfferCommentModal from '../MaterialFormOfferCommentModal/MaterialFormOfferCommentModal.js';

class MaterialOfferGeneralInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      comments: [],
      loading: true,
    };
  }

  componentDidMount = () => {
    this.setState({
      comments: this.props.comments,
      loading: false,
    })
  }

  handleClick = () => {
    const comment = this.getLatestComment();
    this.setState((prevstate) => ({ isOpen: !prevstate.isOpen, latestComment: comment }));
  }

  getLatestComment = () => {
    const allComments = this.state.comments;
  }

  getNewComments = () => {
    const comments = this.state.comments;
    if (!comments[comments.length - 1].comment) {
      return;
    }
    let newComments = comments.filter((comment) => !comment.accepted_by_owner);
    return newComments.length > 0 ? newComments.length : null;
  }

  saveComment = (comment) => {
    this.props.saveCommentCallback(comment);
    this.setState({
      isOpen: false,
      comments: this.props.comments,
    })
  }

  render() {
    const { residents_stay_during_repairs, info_background, info_keep, info_keep_reinstall, info_keep_store } = this.props.submissionForm;
    const { other_tasks, other_tasks_price } = this.props.submissionForm.material_quote;
    const commentValue = this.state.comments && this.state.comments.length > 0 && this.state.comments[this.state.comments.length - 1].source === 'owner' ? this.state.comments[this.state.comments.length - 1].comment : '';

    return (
      <>
        { !this.state.loading &&
          <Box mt="4" px={ [0, null, 8, 4, 8] } pb={ [0, null, 8, 4, 8] } pt={ [0, null, 4, 2, 4] } border={ { base: "none", md: "1px solid" } } borderColor="gray.inputBorder">
            {
              this.state.isOpen && <MaterialFormOfferCommentModal
                onChangeCallback={ (name, value) => this.props.onChangeCallback(name, value) }
                isOpen={ this.state.isOpen }
                onClose={ this.handleClick }
                saveComment={ (comment) => this.saveComment(comment) }
                title={ I18n.t('materialforms.comments_for_general_info') }
                textAreaName="submissionFormComment" s
                comments={ this.state.comments }
                value={ commentValue }
              />
            }
            { !this.props.disabled &&
              <Flex className="print-hide" alignItems="center" justify="space-between">
                <Heading>{ I18n.t('materialforms.general_info') }</Heading>
                <Button variant="lightgray-btn-dark" onClick={ this.handleClick }>
                  { this.state.comments && this.state.comments.length > 0 &&
                    <Badge position="absolute" variant="solid" colorScheme="red" borderRadius="30px" top="-3px" left="-3px" fontSize="md">
                      { this.getNewComments() }
                    </Badge>
                  }
                  <Icon display={ { base: "none", md: "block" } } as={ BsPencil } w={ 6 } h={ 6 } color="black" marginRight="3" />
                  { I18n.t('materialforms.comment') }
                </Button>
              </Flex>
            }
            <Box className="info-box" mt={ [2, 4, 2, 4] } border="3px solid" borderColor="gray.lightest">
              <Flex direction={ { base: "column", md: "row" } }>
                <Text py="2" px={ [2, 4, 2, 4] } w={ { base: "100%", md: "25%" } } fontWeight="bold">{ I18n.t('materialforms.residents_staying_during_repairs') }</Text>
                <Text py="2" px={ [2, 4, 2, 4] } flex="1">{ residents_stay_during_repairs ? I18n.t('general.yes') : I18n.t('general.no') }</Text>
                <Box w={ { base: "100%", md: "20%" } } bg="bgColor">
                  {/* <Text py="2" px={[2, 4, 2, 4]}>300€</Text> */ }
                </Box>
              </Flex>
              <Flex direction={ { base: "column", md: "row" } }>
                <Text py="2" px={ [2, 4, 2, 4] } w={ { base: "100%", md: "25%" } } fontWeight="bold">{ I18n.t('materialforms.repairs') }</Text>
                <Text py="2" px={ [2, 4, 2, 4] } flex="1">{ info_background || '-' }</Text>
                <Box w={ { base: "100%", md: "20%" } } bg="bgColor">
                  {/* <Text py="2" px={[2, 4, 2, 4]}>300€</Text> */ }
                </Box>
              </Flex>
              <Flex direction={ { base: "column", md: "row" } }>
                <Text py="2" px={ [2, 4, 2, 4] } w={ { base: "100%", md: "25%" } } fontWeight="bold">{ I18n.t('materialforms.keep_furniture') }</Text>
                <Text py="2" px={ [2, 4, 2, 4] } flex="1">{ info_keep ? I18n.t('general.yes') : I18n.t('general.no') }</Text>
                <Box w={ { base: "100%", md: "20%" } } bg="bgColor">
                  {/* <Text py="2" px={[2, 4, 2, 4]}>300€</Text> */ }
                </Box>
              </Flex>
              <Flex direction={ { base: "column", md: "row" } }>
                <Text py="2" px={ [2, 4, 2, 4] } w={ { base: "100%", md: "25%" } } fontWeight="bold">{ I18n.t('materialforms.store_furniture') }</Text>
                <Text py="2" px={ [2, 4, 2, 4] } flex="1">{ info_keep_store ? info_keep_store : '-' }</Text>
                <Box w={ { base: "100%", md: "20%" } } bg="bgColor">
                  {/* <Text py="2" px={[2, 4, 2, 4]}>300€</Text> */ }
                </Box>
              </Flex>
              <Flex direction={ { base: "column", md: "row" } }>
                <Text py="2" px={ [2, 4, 2, 4] } w={ { base: "100%", md: "25%" } } fontWeight="bold">{ I18n.t('materialforms.reinstall_furniture') }</Text>
                <Text py="2" px={ [2, 4, 2, 4] } flex="1">{ info_keep_reinstall ? info_keep_reinstall : '-' }</Text>
                <Box w={ { base: "100%", md: "20%" } } bg="bgColor">
                  {/* <Text py="2" px={[2, 4, 2, 4]}>300€</Text> */ }
                </Box>
              </Flex>
            </Box>

            {/* <Box mt={[2, 4, 2, 4]} border="3px solid" borderColor="gray.lightest">
              <Flex direction={{ base: "column", md: "row" }}>
                <Text py="2" px={[2, 4, 2, 4]} w={{ base: "100%", md: "25%" }} fontWeight="bold">Lisätyöt</Text>
                <Textarea py="2" px={[2, 4, 2, 4]} flex="1">{other_tasks}</Textarea>
                <Box w={{ base: "100%", md: "20%" }} bg="bgColor">
                  <Text py="2" px={[2, 4, 2, 4]}>{other_tasks_price} €</Text>
                </Box>
              </Flex>
            </Box> */}
          </Box>
        }
      </>
    )
  }
}

export default MaterialOfferGeneralInformation;
