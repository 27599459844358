import React, { Component } from 'react';

class MaterialFormInstructionsDisclaimer extends Component {
  render() {
    return (
      <div
        style={{ textAlign: 'center', maxWidth: '750px', margin: '60px auto' }}
      >
        <strong>
          <p>
            Taloyhtiön putkiurakka on aina iso hanke kaikille osapuolille. Jotta
            kaikki sujuisi hallitusti ja aikataulun mukaisesti, työmaan eri
            ammattilaisten resurssit varataan sovittujen töiden mukaan ja
            huoneistoihin tilataan hyvissä ajoin tarvittavat tuotteet, joista
            osalla on hyvinkin pitkä toimitusaika.
          </p>
          <br></br>
          <p>
            Tämän vuoksi, ei muutoksia tai lisätilauksia oteta enää vastaan, kun
            muutostyötarjoukset on hyväksytty ja huonekortit ovat valmiit.
          </p>
          <br></br>
          <p>
            Saneeraukseen sisältyy kuitenkin aina pieni riski, että rakenteiden
            avaamisen jälkeen saattaa ilmetä jokin rakenteellinen tai
            talotekninen este, jonka vuoksi toteutusta ei voida tehdä aiemmin
            sovitulla tavalla. Tällaiset tilanteet ovat harvinaisia, mutta siinä
            tapauksessa otamme välittömästi yhteyttä osakkaaseen.
          </p>
          <br></br>
          <p>
            Huoneisto on asuntokohtaisen urakan ajan urakoitsijan
            työmaa-aluetta, josta me vastaamme (siisteys, turvallisuus, vauriot
            ym.), sen vuoksi osakkaan omia remontteja ei sallita, eikä
            ulkopuolisia urakoitsijoita tai työntekijöitä päästetä tuona aikana
            huoneistoihin töihin.
          </p>
          <br></br>
          <p>
            Jos toimitat itse tuotteita, niin ilmoitathan
            asiakaspalveluinsinöörille niiden toimituksesta ja sijainnista
            huoneistossa.
          </p>
        </strong>
        <br></br>
        <p>
          <i>
            Huom! Tämä valintalomake ei ole taloyhtiön ja urakoitsijan välisen
            urakkasopimuksen virallinen liite. Urakkasopimus ja työmaakokouksien
            pakottavista syistä pöytäkirjaan merkityt päätökset menevät
            ristiriitatilanteissa tämän asiakirjan edelle.
          </i>
        </p>
      </div>
    );
  }
}

export default MaterialFormInstructionsDisclaimer;
