import React, { Component } from 'react';

//Chakras
import { Box, Text } from '@chakra-ui/layout';

class MaterialOfferIntro extends Component {
  render() {
    return (
      <Box my="8">
        <Text>
          Tarjous on urakoitsijan ja osakkaan välinen molempia osapuolia sitova
          sopimus, johon ei enää hyväksynnän jälkeen tehdä muutoksia ilman
          erityisen painavia perusteita (esim. taloyhtiön tilaaman urakan
          sisältö muuttuu tai toteutus on mahdoton urakan edetessä ilmaantuvista
          rakenteellisista seikoista johtuen).
        </Text>
        <br />
        <Text>
          Laskun maksuehto on 14 päivää netto. Osakasmuutoslasku sisältää vain
          osakkaan itse tilaamat työt sekä muutokset ja on laskutuskelpoinen
          alla mainituin tarkennuksin niiltä osin, kun osakkaan erikseen
          tilaamat työt on tehty. Osakas ei voi pidättäytyä laskun maksamisesta
          laskussa mainitsemattomiin töihin liittyvien erimielisyyksien vuoksi
          (REYS-8/RYS-9). Taloyhtiön tilaukseen sisältyviin asioihin sovelletaan
          taloyhtiön ja urakoitsijan välisiä sopimuksia sekä lainsäädäntöä.
          Takuuaika 24 kk, jollei yhtiön urakkasopimuksessa ole muuta sovittu.
          Vika- ja puutetyö- sekä takuukorjauksissa noudatetaan yhtiön kanssa
          tehdyn sopimuksen aikatauluja, joiden käytännön järjestelyistä
          informoidaan osakkaita lähempänä ajankohtaa. Tiedot em. liittyen
          toimitetaan taloyhtiölle. Työt suoritetaan voimassa olevan SisäRYL:n
          ja RT-korttien mukaisesti. Arvonlisävero määräytyy kulloinkin voimassa
          olevan verokannan mukaisesti.
        </Text>
        <br />
        <Text>
          Pääurakoitsija laskuttaa osakasta lisätöistä pääsääntöisesti niiden
          valmistumisen jälkeen. Lisätöiden arvonlisäverollisen loppusumman
          ylittäessä 2.000,00 €, laskuttaa urakoitsija kokonaissummasta 50 %
          ennakkoon.
        </Text>
        <br />
        <Text>
          Jos osakas käyttää VRJ:n kautta OP Pohjola Oyj:n rahoitussopimusta,
          hyväksyy hän samalla, että VRJ laskuttaa sovitut muutostyöt suoraan
          rahoitusyhtiötä ja vakuuttaa tutustuneensa osakkaille jaetun
          osakaskansion rahoitussopimusesitteeseen.
        </Text>
        <br />
        <Text>
          Loppusumman jäädessä negatiiviseksi, hyvitys ohjautuu taloyhtiölle
          kokonaisurakan päätyttyä. Taloyhtiö käsittelee erikseen em.
          huoneistokohtaiset hyvitykset.
        </Text>
      </Box>
    );
  }
}

export default MaterialOfferIntro;
