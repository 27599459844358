import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import UtilService from '../../../AvainiaTools/UtilService.js';
import AvainiaCore from 'avainia-core-api';
import I18n from 'i18n-js';

//Icons
import { BsFolder } from "react-icons/bs";

//Chakra
import { Box, Divider, Flex, Heading, Link, Text, Center } from '@chakra-ui/layout';
import Icon from '@chakra-ui/icon';
import { Spinner } from '@chakra-ui/spinner';

class ProjectMaterials extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.condominiumFoldersGet(this.props.projectId, 'folders').then((projectFolders) => {
      if (projectFolders.error) { return this.setState({ error: projectFolders.error }); }

      api.getFields('fields').then((fields) => {
        if (fields.error) { return this.setState({ error: fields.error }); }

        api.condominiumProjectDocumentsGet(this.props.projectId, fields.fields, UtilService.dbDocumentToImage).then((documents) => {
          if (documents.error) { return this.setState({ error: documents.error }); }

          this.setState({
            documents,
            fields: fields.fields,
            loading: false,
            projectFolders: this.sortFolders(projectFolders),
          });
        });
      });
    });
  }

  sortFolders = (projectFolders) => {
    let projectFoldersFormatted = {folders: []};

    if(!projectFolders){
      return null;
    }

    projectFolders.folders.map((folder) => {
      if(!folder.parent_id){
        folder.children = this.getChildFolders(projectFolders, folder);
        projectFoldersFormatted.folders.push(folder);
      }
    });

    return projectFoldersFormatted;
  }

  getChildFolders = (projectFolders, parent_folder) => {
    let childFolders = [];

    projectFolders.folders.map((folder) => {
      if(folder.parent_id == parent_folder.id){
        folder.children = this.getChildFolders(projectFolders, folder);
        childFolders.push(folder);
      }
    });

    return childFolders;
  }

  download = async (id) => {
    const targetDocument = this.state.documents.find((doc) => doc.id === id)

    try {
      const obj = { headers: { Authorization: `Bearer ${LocalStorageService.getToken()}` } };
      const res = await fetch(process.env.REACT_APP_API_HOST + targetDocument.url, obj);

      if (res.ok) {
        const binary = await res.blob();

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(binary, targetDocument.files[0].full_name);
        } else {
          const src = window.URL.createObjectURL(binary);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style.cssText = 'display: none';
          a.href = src;
          a.download = targetDocument.files[0].full_name;
          a.click();
          window.URL.revokeObjectURL(src);
          setTimeout((x) => { document.body.removeChild(a); }, 1000);
        }
      } else if (res.status !== 410) { // 410 means dont retry
        throw new Error('failed to fetch document'); // TODO! Fatal error, do actual logging
      }
    } catch (ex) {
      console.error(ex); // TODO! Fatal error, do actual logging
    }
  }

  renderFolder(documents, folder, lvl = 0) {
    return (
      <Box key={ folder.id }>
        { lvl != 0 && <Divider />}
        <Flex direction={ { base: "column", xl: "row"/* , lg: "column"  */ } }>
          <Flex w={ ["100%", null, null, null, "40%"] }>
            <Icon as={ BsFolder } w={ 5 } h={ 5 } />
            <Text variant="small-bold" ml="4">{ folder.name }</Text>
          </Flex>
          <Flex direction="column">
            { documents && documents.map((doc) => {
              if (doc.folder_id === '') {
                doc.folder_id = '0';
              }
              if (doc.folder_id.toString() === folder.id.toString()) {
                const text = doc.name;
                const extension = doc.files[0].extension ? `(${doc.files[0].extension})` : '';
                return (
                  <Link
                    variant="default-link"
                    ml={ { base: "10", xl: "0" } }
                    my={ { base: "1", xl: "0" } }
                    key={ doc.id }
                    onClick={ () => this.download(doc.id) }
                  >
                    { text } { extension }
                  </Link>
                )
              } return null;
            }) }
          </Flex>
        </Flex>
        <Box pl={4} mt={2}>
          {folder.children && folder.children.map((childFolder) => {
            return this.renderFolder(documents, childFolder, (lvl + 1));
          })}
        </Box>
        { lvl == 0 && <Divider />}
      </Box>
    )
  }

  render() {
    const { summary } = this.props;
    const { projectFolders, documents, loading } = this.state;
    let allProjectFolders = projectFolders && [{ id: 0, name: `${I18n.t('projectmaterials.main-folder')}`, parent_id: null, default: 0 }, ...projectFolders.folders];

    return (
      <>
        { loading &&
          <Center>
            <Spinner size="xl" />
          </Center>
        }
        { !loading &&
          <>
            <Box bg="white" p={ [4, 8, 4, 8] }>
              <Heading mb="4">{ I18n.t('projectmaterials.heading') }</Heading>
              <Divider />
              <Box>
                { allProjectFolders.sort((a, b) => a.id.toString().localeCompare(b.id.toString())).map((folder) => {
                  if (summary) {
                    return (
                      this.renderFolder(documents, folder)
                    )
                  } return null;
                }) }
              </Box>
            </Box>
          </>
        }
      </>
    )
  }
}

export default withRouter(ProjectMaterials);

/*
else {
  return (
    <div key={folder.id}>
      <div>{folder.name}</div>
      { documents && documents.map((doc) => {
        if (doc.folder_id === '') {
          doc.folder_id = '0';
        }
        if (doc.folder_id.toString() === folder.id.toString()) {
          return (
            <div style={{ cursor: "pointer", color: "#ac13bd" }} onClick={() => this.download(doc.id)} key={doc.id}>
              {doc.name}
            </div>
          )
        } return null;
      })}
      <hr />
    </div>
  )
}
 */
