import React, { Component } from 'react';
import I18n from 'i18n-js';

//Chakras
import { RadioGroup, Radio, Flex } from "@chakra-ui/react";
import { Box, Text } from '@chakra-ui/layout';

class InputRadio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: null,
      disabled: false,
    };
  }

  componentDidMount = () => {
    const { value, disabled } = this.props;

    this.setState({
      value,
      disabled
    })
  }

  onChange = (value) => {
    this.setState({ value: parseInt(value) }, () => this.props.onChangeCallback(this.props.name, parseInt(value)))
  }

  render() {
    return (
      <Box className="InputRadio">
        <Text fontWeight="bold" pb="6">{this.props.radioQ}</Text>
        <RadioGroup onChange={this.onChange} value={this.state.value} pb="6">
          <Flex direction="column">
            <Radio isDisabled={this.state.disabled} value={1}><Text>{I18n.t('general.yes')}</Text></Radio>
            <Radio isDisabled={this.state.disabled} value={0}><Text>{I18n.t('general.no')}</Text></Radio>
          </Flex>
        </RadioGroup>
      </Box>
    )
  }
}

export default InputRadio;
