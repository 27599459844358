import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Components
import MaterialFormInfo from '../../partials/MaterialFormInfo/MaterialFormInfo.js'

class MaterialFormPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render(props) {
    return (
      <>
        <MaterialFormInfo {...props}/>
      </>
    );
  }
}

export default withRouter(MaterialFormPage);
