import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { Container } from '@chakra-ui/layout';
import { GridItem, SimpleGrid } from "@chakra-ui/react";
import CurrentApartment from '../../partials/CurrentApartment/CurrentApartment.js';
import ApartmentReview from '../../partials/ApartmentReview/ApartmentReview.js';
import NavigationBar from '../../partials/NavigationBar/NavigationBar.js';
import NavigationMobile from '../../partials/NavigationMobile/NavigationMobile';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class AvainiaWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {};

  }

  render() {
    this.bodyColSpan = this.props.hideSidebar ? 8 : [8, 8, 4, 5, 6, 6];
    const params = `${this.props.match.params.id}/${this.props.match.params.projectId}`;
    const user = LocalStorageService.getUser();
    const userIsOwner = user.roles.filter((role) => role.display_name === 'apartmentOwner').length > 0;
    const userIsAdmin = user.roles.filter((role) => role.name === 'owner').length > 0;

    return (
      <>
        <Container maxW="100rem" px={ this.props.containerVariant === "mobile" ? [0, 4] : [4] }>
          <SimpleGrid columns={ 8 } gap={ 4 }>
            { !this.props.hideNav &&
              <GridItem colSpan={ 8 }>
                <NavigationBar userIsOwner={ userIsOwner } userIsAdmin={ userIsAdmin } />
                <NavigationMobile containerVariant={ this.props.containerVariant } />
              </GridItem>
            }
            { !this.props.hideSidebar &&
              <GridItem colSpan={ [8, 8, 4, 3, 2, 2] }>
                <CurrentApartment userIsOwner={ userIsOwner } params={ params } condominium={ this.props.match.params.id } />
                {/* {this.props.sidebarContent !== 'materialFormPage' &&
                  <ApartmentReview />
                } */}
              </GridItem>
            }

            <GridItem colSpan={ this.bodyColSpan }>
              { this.props.children }

            </GridItem>
          </SimpleGrid>
        </Container>
      </>
    );
  }
}

export default withRouter(AvainiaWrapper);
