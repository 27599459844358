import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import AvainiaCore from 'avainia-core-api';
import UtilService from '../../../AvainiaTools/UtilService.js';
import I18n from 'i18n-js';

//Chakras
import { Box, Button, ButtonGroup, Flex, Center, Spinner, Text } from "@chakra-ui/react";
import MaterialFormDisclaimer from '../MaterialFormInstructions/MaterialFormInstructionsDisclaimer.js';
import MaterialFormIntro from '../MaterialFormInstructions/MaterialFormInstructionsIntro.js';

class MaterialFormPrintable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupIndexes: [0],
      selectionIndexes: {},
      additionalProductIndexes: [],
      showSavedAlert: false,
      additionalInfos: [],
      loading: true,
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.materialSelectionsPerSubmissionFormGet(this.props.project.id, this.props.apartment.id, this.props.targetMaterialSubmissionForm.id).then((selections) => {
      if (selections.error) { return this.setState({ error: selections.error }); }

      UtilService.convertMaterialform(this.props.mf).then((converted) => {
        if (converted.error) { return this.setState({ error: converted.error }); }

        api.materialFormSubmissionGet(this.props.apartment.id, this.props.mf.id).then((submissionForm) => {
          if (submissionForm.error) { return this.setState({ error: submissionForm.error }); }

          this.setState({
            materialform: this.props.mf,
            apartment: this.props.apartment,
            project: this.props.project,
            selections,
            submissionForm,
            choicesDisabled: this.props.disabled,
            selectedAdditionalProducts: submissionForm.additional_product_selections,
            loading: false,
          }, this.calculatePageBreaks);
        })
      })
    })
  }


  calculatePageBreaks = () => {

    // 1754px is the amount of pixels in y-axis A4 in 150 DDI
    // 1420px is POOMA estimate for elements to render and print nicely
    const pageHeight = 1420;

    // Set group page breaks
    const groups = document.getElementsByClassName('group');
    if (groups.length > 0) {

      let cumulativeHeight = 0; // for cumulative groups
      let selectionsHeight = 0; // for cumulative selections height
      let selectionBreakPageMatrix = {};

      for (let index = 0; index < groups.length; index++) {
        const group = groups[index];
        const groupHeight = group.offsetHeight;

        // Check if the group is too big for a page
        if (groupHeight >= pageHeight) {

          // Add page break
          this.setState((prevState) => ({
            ...prevState,
            groupIndexes:  [...prevState.groupIndexes, index ],
          }));
          const selections = group.getElementsByClassName('group-selection');
          const startElement = group.getElementsByClassName('group-description');
          selectionsHeight = startElement[0].offsetHeight; // begin with the group title and the description

          // Insert page break between selecions
          for (let selectionIndex = 0; selectionIndex < selections.length; selectionIndex++) {
            const prevSelIndex = selectionIndex > 0 ? ( selectionIndex - 1 ) : 0;

            selectionsHeight += selections[selectionIndex].offsetHeight;

            if (selectionsHeight >= pageHeight) {
              selectionsHeight = selections[selectionIndex].offsetHeight; // reset height counter

              // Check if the group already has selection indexes
              if (selectionBreakPageMatrix[index]) {
                let oldArr = selectionBreakPageMatrix[index];
                oldArr.push(prevSelIndex);
                selectionBreakPageMatrix[index] = oldArr;
              } else {
                selectionBreakPageMatrix[index] = [ prevSelIndex ];
              }
            }
          }
          cumulativeHeight = selectionsHeight;
        } else if ((cumulativeHeight + groupHeight) >= pageHeight) {
            // Add page break
            this.setState((prevState) => ({
              ...prevState,
              groupIndexes:  [...prevState.groupIndexes, index ],
            }));
            cumulativeHeight = 0; // reset height
        } else {
          cumulativeHeight += groupHeight;
        }
      }
      // Save group selection indexes
      this.setState({selectionIndexes: selectionBreakPageMatrix});
    }

    // Set additional product page breaks
    const additionalProducts = document.getElementsByClassName('additional-product');
    if (additionalProducts.length > 0) {
      const title = document.getElementsByClassName('additional-products-title');
      let additionHeight = title[0].offsetHeight; // Cumulative additional products height

      for (let index = 0; index < additionalProducts.length; index++) {
        const product = additionalProducts[index];
        const productHeight = product.offsetHeight;
        additionHeight += productHeight;

        if (additionHeight >= pageHeight) {
          // Add page break
          this.setState(prevState => ({
            ...prevState,
            additionalProductIndexes: [...prevState.additionalProductIndexes, index],
          }));
          additionHeight = 0; // reset height
        }
      }
    }
  }

  onSelect = (tab) => { this.setState({ tab }); }

  getCalculatedPrice = (product, price) => {
    const packageSize = product.package_amount;
    const calculatedPrice = (packageSize === 0 || packageSize === null) ? price : packageSize*price;
    return calculatedPrice;
  }

  getMappedSelections = () => {
    const mapped = [];

    this.state.selections.forEach((selection) => {
      let select = false;
      this.state.materialform.groups.forEach((g) => {
        if (select) { return; }
        select = g.selects.find((s) => s.id === selection.material_form_select_id);
      });

      if (!select) { return; /* Probably, hopefully, this means that this selection is for some other materialform */ }

      mapped.push({
        select,
        ...selection,
      });
    });

    return mapped;
  }

  onChange = (name, value) => {
    this.setState((prevState) => ({
      submissionForm: { ...prevState.submissionForm, [name]: value, },
    }));
  }

  productIsChosen = (product) => {

    let selection = this.getMappedSelections().find((s) => s.material_form_select_id === product.material_form_select_id);
    if(selection){
      return selection.material_form_option.id === product.id ? 'print-product-active' : '';
    }
    return '';
  }

  // Non-DRY, check productIsChosen()
  getProductComment = (product) => {
    const selection = this.getMappedSelections().find((s) => s.material_form_select_id === product.material_form_select_id);
    return selection?.material_form_option?.id === product.id ? selection.comment : false;
  }

  additionalProductIsChosen = (product) => {
    return this.state.selectedAdditionalProducts.find((s) => s.additional_product_id === product.id) ? 'print-product-active' : '';
  }

  getAdditionalProductComment = (product) => {
    const selection = this.state.selectedAdditionalProducts.find((s) => s.additional_product_id === product.id);
    return selection?.comment ?? false;
  }

  handleIndex = (groupIndex, selectionIndex) => {
    if (this.state.selectionIndexes[groupIndex]?.includes(selectionIndex)) {
      return true;
    }
    return false;
  }

  render() {
    const form = this.props.targetMaterialSubmissionForm;
    return (
      <Box bg="white">
        { this.state.loading &&
          <Center>
            <Spinner size="xl" />
          </Center>
        }
        { this.state.materialform && <>
          <Box className="printedForm onlyPrint">
            <div>
              <h2>{I18n.t('materialforms.selection_form')}</h2>
              <h1>{LocalStorageService.getCondominium().name} {this.props.apartment.stairwell} {this.props.apartment.apartment_number}</h1>
            </div>

            <MaterialFormIntro></MaterialFormIntro>

            <div className="print-page-break"/>

            <div className="page">
              <h2>Taustatietoja huoneistosta</h2>
              <h3>Huoneistossa asutaan remontin aikana</h3>
                <div>
                  <label>Kyllä</label> {form.residents_stay_during_repairs === 1 ? <input type="radio" checked/> : <input type="radio"/>}
                  <label>Ei</label> {form.residents_stay_during_repairs === 0 ? <input type="radio" checked/> : <input type="radio"/>}
                </div>
              <h3>Huoneistossa on viimeisen kolmen vuoden aikana tehty remonttia. Mitä? Milloin?</h3>
              <textarea value={form.info_background}/>
            </div>
            <div>
              <h2>Säilytettävät tai takaisin asennettavat tuotteet</h2>
              <h3>Onko kylpyhuoneessa sellaisia kalusteita, jotka haluatte säilyttää?</h3>
              <div>
                <label>Kyllä</label> {form.info_keep === 1 ? <input type="radio" checked/> : <input type="radio"/>}
                <label>Ei</label> {form.info_keep === 0 ?  <input type="radio" checked/> : <input type="radio"/>}
              </div>
              <h3>Mitä kalusteita säilytetään (otetaan talteen, ei asenneta takaisin)?</h3>
              <p>Huomioithan, että urakoitsija ei ole velvollinen varastoimaan tai huolehtimaan näistä kalusteista tai varusteista. Ne kannattaa viipymättä viedä pois asunnosta tai siirtää pois työmaa-alueelta. Tuotteet ovat irrotettuina n. kaksi viikkoa töiden aloittamisen jälkeen. Ilmoitathan, kun tulet noutamaan niitä.</p>
              <textarea value={form.info_keep_store}/>
              <h3>Mitä kalusteita asennetaan takaisin säilytyksen jälkeen?</h3>
              <textarea value={form.info_keep_reinstall}/>
            </div>
            {this.state.materialform.groups.map((group, index) => {
              let groupIndex = index;
              return <>
                  { this.state.groupIndexes.includes(index) && <div className="print-page-break"/> }
                  <div className={`group ${this.state.groupIndexes.includes(index) ? "page" : ""}`} key={ group.id } title={ group.name }>
                    <div className="group-description">
                      <h2>{ group.name }</h2>
                      <p style={{whiteSpace:"pre-line"}}>{group.description}</p>
                    </div>
                    { group.selects.map((selectionType, selectionIndex) => {
                        return <>
                        {/* TODO check handleIndex() function, it runs twice here  */}
                          <div  className={`group-selection ${this.handleIndex(groupIndex, (selectionIndex - 1)) ? "page" : ""}`} title={ selectionType.name }>
                            <h3>{ selectionType.name }</h3>
                            <div className="print-productselection" id={selectionType.name} columns={3}>
                              {selectionType.options.sort((a, b) => a.order_index - b.order_index).map((product) => {
                                const comment = this.getProductComment(product);
                                return <>
                                  <div className={this.productIsChosen(product)}>
                                    <div className="print-productcard">
                                      <Text className="product-text">
                                        { product.product_obj.manufacturer && product.product_obj.manufacturer !== "NULL" && <span>{product.product_obj.manufacturer}</span>}
                                        { product.product_obj.code && product.product_obj.code !== "NULL" && <span>{product.product_obj.code}</span>}
                                        { product.product_obj.name && <span>{product.product_obj.name}</span>}
                                        { product.product_obj.measurements && <span>{product.product_obj.measurements}</span>}
                                      </Text>
                                      <Text>
                                        { product.is_contractual ?
                                          <span className="contract-price">
                                            { Number(product.labor_price) != 0 && <span>Työn hinta: {Number(product.labor_price)} €</span> }
                                            {" " + I18n.t('materialforms.contract_prize')} {Number(product.price) + Number(product.labor_price)} €
                                          </span>
                                          :
                                          <span className="compensation-prize">
                                            { Number(product.labor_price) != 0 && <span>Työn hinta: {Number(product.labor_price)} €</span> }
                                            <strong>{" " + I18n.t('materialforms.additional_price')} {Number(this.getCalculatedPrice(product, product.price)) + Number(product.labor_price)} €</strong>
                                          </span>
                                        }
                                      </Text>
                                    </div>
                                    { comment && <span>
                                      <Text className='product-comment' fontWeight="bold">
                                        {I18n.t('materialforms.comments_for_this_product')}
                                      </Text>
                                      <Text className='product-comment'>{comment}</Text>
                                    </span>}
                                  </div>
                                </>
                              })}
                            </div>
                          </div>
                          { this.handleIndex(groupIndex, selectionIndex) && <div className="print-page-break"/> }
                        </>
                    }) }
                  </div>
                </>
            }
            )
            }
            <div className="print-page-break"/>
            <div className="page">
              <h2 className="additional-products-title">Lisätuotteet</h2>
              <div className="print-productselection">
                {this.state.submissionForm.additional_products.sort((a, b) => a.id - b.id).map((product, index) => {
                  const comment = this.getAdditionalProductComment(product);
                  return <>
                    { this.state.additionalProductIndexes.includes(index) && <div className="print-page-break"/> }
                    <div className={`additional-product ` + this.additionalProductIsChosen(product)}>
                      <div className={`${this.state.additionalProductIndexes.includes(index) ? "page" : ""} print-productcard additional-productcard`}>
                        <Text className="product-name">{product.name}</Text>
                        { product.code &&
                          <Text className="product-number">{I18n.t('materialforms.product_number')} {product.code}</Text>
                        }
                        { product.manufacturer &&
                          <Text className="product-model">{I18n.t('materialforms.product_model')} {product.manufacturer}</Text>
                        }
                        <Text className="compensation-prize"> <strong>{I18n.t('materialforms.additional_price')} {Number(product.price) + Number(product.labor_price)} € </strong></Text>
                      </div>
                      { comment && <span>
                        <Text className='product-comment' fontWeight="bold">
                          {I18n.t('materialforms.comments_for_this_product')}
                        </Text>
                        <Text className='product-comment'>{comment}</Text>
                      </span>}
                    </div>
                  </>
                })}
              </div>
            </div>

            <div className="print-page-break"/>
            <div className="page">
              <h2>Lisätiedot</h2>
              <textarea value={form.info_free_word}/>
              <MaterialFormDisclaimer />
            </div>

          </Box>
          <ButtonGroup className="print-hide">
            <Flex direction={ { base: "column", md: "row" } }>
              <Button m="1" onClick={ this.props.disable } variant="grey-forward-btn">{I18n.t('general.back')}</Button>
              <Button m="1" onClick={window.print} variant="grey-forward-btn">{I18n.t('materialforms.print.form')}</Button>
            </Flex>
          </ButtonGroup>
        </>
        }
      </Box>
    )
  }
}

export default withRouter(MaterialFormPrintable);
