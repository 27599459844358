import React, { Component } from 'react';
import InputTextboxWithAlert from '../InputTextboxWithAlert/InputTextboxWithAlert.js';
import I18n from 'i18n-js';
import MaterialFormInstructionsDisclaimer from '../MaterialFormInstructions/MaterialFormInstructionsDisclaimer.js';

class MaterialFormAdditionalInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      info_free_word: null,
      disabled: false,
      loading: true,
    };
  }

  componentDidMount = () => {
    const { info_free_word } = this.props.submissionForm;
    const { disabled } = this.props;

    this.setState({
      info_free_word,
      disabled,
      loading: false,
    });
  };

  render() {
    const { info_free_word, disabled } = this.state;

    return (
      <>
        {!this.state.loading && (
          <>
            <InputTextboxWithAlert
              disabled={disabled}
              question={I18n.t('materialforms.additional_info')}
              value={info_free_word}
              onChangeCallback={(name, value) =>
                this.props.onChange(name, value)
              }
              name="info_free_word"
              printMode={this.props.printMode}
            />
            <MaterialFormInstructionsDisclaimer />
          </>
        )}
      </>
    );
  }
}

export default MaterialFormAdditionalInfo;
