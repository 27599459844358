import { Image } from '@chakra-ui/image';
import React, { Component } from 'react';
import AvainiaCore from 'avainia-core-api';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import { NavLink, withRouter } from 'react-router-dom';
import I18n from 'i18n-js';

//Icons
import { BsArrowRightShort, BsTag } from "react-icons/bs";

// Components
import ButtonBasic from '../buttons/ButtonBasic/ButtonBasic.js';
import ApartmentDropdown from '../buttons/ApartmentDropdown/ApartmentDropdown.js';

//Chakra
import { Box, Heading, Text, Link } from '@chakra-ui/layout';

class CurrentApartment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apartments: [],
      error: null
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.condominiumMainPhotoGet(this.props.condominium).then((condominiumPhoto) => {
      if (condominiumPhoto.error) { return this.setState({ error: condominiumPhoto.error }); }
      this.setState({
        condominiumPhotoUrl: condominiumPhoto,
      });
    })
  }

  render() {
    const { params, userIsOwner } = this.props;

    return (
      <Box bg="white" mb="4">
        <Box textAlign="-webkit-center">
          <Image alt="Condominium" src={this.state.condominiumPhotoUrl ? this.state.condominiumPhotoUrl : "/assets/images/condo-placeholder.png"} w="100%"></Image>
        </Box>
        <Box p={[4, 8, 4, 8]}>
          <Heading mb="4">{I18n.t('avainiawrapper.your_apartment')}</Heading>
          <ApartmentDropdown condominium={this.props.condominium} />
          <Box h="4" />
          { userIsOwner && <Link as={NavLink} exact to={`/condominium/${params}/materialforms`} variant="default-link">
            <ButtonBasic
              text="Materiaalinvalinta"
              icon={<BsTag size="22" />}
              arrow={<BsArrowRightShort size="36" />}
            />
          </Link> }
        </Box>
      </Box>
    )
  }
}

export default CurrentApartment;
