import React, { Component } from 'react';

import { Modal, ModalOverlay, ModalContent, ModalBody, Textarea, ModalHeader, ModalCloseButton, ModalFooter, Text } from "@chakra-ui/react";
import { Image } from '@chakra-ui/image';
import { Box,  } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';

import CustomCloseButton from "../buttons/CustomCloseButton/CustomCloseButton.js";
import I18n from 'i18n-js';

class MaterialFormOfferCommentModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: null,
      value: null,
    };
  }

  componentDidMount = () => {
    const name = this.props.textAreaName;
    const value = this.props.value;

    this.setState({
      [name]: value
    })
  }

  onChange = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value },
      this.props.onChangeCallback(name, value)
    )
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} onClose={this.props.onClose}>
        <ModalOverlay />
        <ModalContent maxWidth="60vw" borderRadius="none" top="4rem">
          <ModalHeader>{this.props.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text as="u">{I18n.t('materialforms.previous_comments')}</Text>
            <br/>
            {
              this.props.comments && this.props.comments.map((comment) => {
                return (
                  <>
                    <Text as="cite">{comment.user.name} - {comment.created_at}: {comment.comment}</Text>
                    <br/>
                  </>
                )
              })
            }
            <Textarea
              className="comment-area" 
              fontSize="14"
              name={this.props.textAreaName}
              value={this.state[this.props.textAreaName]}
              onChange={(e) => this.onChange(e)}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => this.props.saveComment(this.state[this.props.textAreaName], this.props.textAreaName)} variant="green-btn" width="10rem" marginRight="20px">{I18n.t('materialforms.button_save')}</Button>
            <Button onClick={() => this.props.onClose()} className="cancel-btn">{I18n.t('materialforms.button_cancel')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }
}

export default MaterialFormOfferCommentModal;