import React, { Component } from 'react';
import I18n from 'i18n-js';

//Chakras
import { Button, Flex, Spacer, Badge } from "@chakra-ui/react";
import { Box, Text, Heading } from '@chakra-ui/layout';

//Icons
import Icon from '@chakra-ui/icon';
import { BsPencil, BsPlusCircle } from 'react-icons/bs';

import { Image } from '@chakra-ui/image';

//Partials
import MaterialFormCommentModal from '../../partials/MaterialFormCommentModal/MaterialFormCommentModal.js';
import MaterialFormOfferCommentModal from '../MaterialFormOfferCommentModal/MaterialFormOfferCommentModal.js';


class MaterialOfferChoiceListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      submissionForm: null,
      loading: true,
      comments: null,
    };
  }

  handleClick = () => {
    this.setState((prevstate) => ({
      isOpen: !prevstate.isOpen,
    }));
  }

  componentDidMount = () => {
    let materialSelectionGroups = [];
    let quoteRows = [];

    this.props.quoteRows.forEach(row => {
      quoteRows.push(row)
    });

    const groupData = quoteRows.map((row) => {
      // A different data structure is a hassle
      return { name: row.group_name, id: row.group_id };
    });

    // Get unique groups with this lovely one-liner and map them in to objects nested in a array for easier iteration
    materialSelectionGroups = [...new Map(groupData.map(item => [item['id'], item])).values()].map(group => {
      return ({
        name: group.name,
        id: group.id,
        selections: [],
      })
    });

    quoteRows.forEach(row => {
      materialSelectionGroups.forEach((group, groupIndex) => {
        if ((row.group_id) === group.id) {
          group.selections.push(row);
        }
      })
    });

    this.setState({
      materialSelections: this.props.selections,
      submissionForm: this.props.submissionForm,
      materialSelectionGroups,
      loading: false,
      comments: this.props.commments,
      quoteRows,
    });
  }

  getAmountOfCommenters = () => {
    return 1;
  }

  getNewComments = (comments) => {
    // check if not empty
    if (!comments[comments.length - 1].comment) {
      return;
    }
    let newComments = comments.filter((comment) => !comment.accepted_by_owner);
    return newComments.length > 0 ? newComments.length : null;
  }

  activateComments = (select, comments, groupName) => {
    this.setState({
      select,
      comments,
      groupName,
    }, this.handleClick)
  }

  deactivateComments = () => {
    this.setState({
      select: null,
      comments: null,
      groupName: null
    }, this.handleClick)
  }

  getPrice = (selection, group) => {
    let materialprice = (group?.name === 'Lisätuotteet' ? 1 : parseFloat(selection.package_amount)) * parseFloat(selection.price);
    let total = materialprice + parseFloat(selection.labor_price);
    return total.toFixed(2);
  }


  getLaborPrice = (selection) => {
    return parseFloat(selection.labor_price);
  }

  render() {
    const { submissionForm } = this.state;
    const commentValue = this.state.comments && this.state.comments.length > 0 && this.state.comments[this.state.comments.length - 1].source === 'owner' ? this.state.comments[this.state.comments.length - 1].comment : '';
    const selectedAdditionalProducts = this.state.submissionForm && this.state.submissionForm.selected_additional_products;

    return (
      <Box key={ this.props.key } mt="4" px={ [0, null, 8, 4, 8] } pb={ [0, null, 8, 4, 8] } pt={ [0, null, 8, 4, 8] } border={ { base: "none", md: "1px solid" } } borderColor="gray.inputBorder">
        { !this.state.loading &&
          <>
            { this.state.isOpen && <MaterialFormOfferCommentModal
              isOpen={ this.state.isOpen }
              onClose={ this.deactivateComments }
              onChangeCallback={ (name, value) => this.props.onChangeCallback(name, value) }
              saveComment={ (comment, rowId) => this.props.saveCommentCallback(comment, rowId) }
              title={ this.state.groupName }
              textAreaName={ this.state.select && this.state.select.id }
              comments={ this.state.comments }
              value={ commentValue }
            />
            }
            <Heading mb="4">{I18n.t('materialforms.products')}</Heading>
            { this.state.materialSelectionGroups.map((group) => {
              return (
                <>
                  <Heading mb="4">{ group.name }</Heading>
                  { group.selections.map((select) => {
                    const isAdditionalProduct = select.selection_type == 'additional_product_selection' ?  true : false;
                    const selectionItem = this.state.materialSelections.find((item) => item.id === select.id);

                    let selectionCode = '';
                    if(isAdditionalProduct){
                      selectionCode = '';
                    } else {
                      selectionCode = select.product ? select.product.code : '';
                    }

                    const selectionArea = selectionItem?.material_form_select?.area;
                    const type = selectionItem?.material_form_select?.area !== null ? I18n.t('materialforms.packages') : I18n.t('materialforms.pcs');
                    const packageAmount = select.package_amount + ' ' + type;
                    const manufactorText = select.product?.manufacturer ? `${select.product?.manufacturer} , ` : '';

                    const selectionText = isAdditionalProduct ? select.product_name : manufactorText + select.product_name + (selectionItem?.material_form_select?.area ? ', ' + selectionArea + 'm²' : '');

                    const thumbnail = select?.product?.product_photo ?? null;
                    const commentRow = submissionForm && submissionForm.material_quote && submissionForm.material_quote.rows && submissionForm.material_quote.rows.find((row) => row.selection_id === select.selection_id);

                    return (
                      <>
                        <Box className="product-container print-hide" border="3px solid" borderColor="gray.lightest" mb="4">
                          { !this.props.disabled && !isAdditionalProduct &&
                            <Flex className="print-hide" alignItems="center" justify="space-between" px={ [2, 4, 2, 4] } py="2" borderBottom="2px solid" borderColor="gray.lightest">
                              <Heading>{ select.selection_name }</Heading>
                              <Button variant="lightgray-btn-dark" onClick={ () => { this.activateComments(select, commentRow?.comments, group.name); } }>
                                { commentRow && commentRow?.comments?.length > 0 &&
                                  <Badge position="absolute" variant="solid" colorScheme="red" padding="2px" width="24px" height="24px" borderRadius="50%" top="-4px" left="-4px" fontSize="md">
                                    { this.getNewComments(commentRow?.comments) }
                                  </Badge>
                                }
                                <Icon display={ { base: "none", md: "block" } } as={ BsPencil } w={ 6 } h={ 6 } color="black" marginRight="3" />
                                { I18n.t('materialforms.comment') } {commentRow?.comments.length > 0 && <span class="comment-counter">({commentRow?.comments.length})</span>}
                              </Button>
                            </Flex>
                          }
                          <Flex direction={ { base: "column", md: "row" } }>
                            <Text py="2" px={ [2, 4, 2, 4] } w={ { base: "100%", md: "20%" } } fontWeight="bold">{ selectionCode }</Text>
                            <Flex py="2" px={ [2, 4, 2, 4] } flex="1" direction={ { base: "column", md: "row" } }>
                              {!isAdditionalProduct && thumbnail && <Image style={ { maxWidth: '60px', maxHeight: '60px' } } src={ thumbnail } alt="Product photo" />}
                              <Text ml={ { base: "none", md: "2" } }>{ selectionText }
                                {commentRow?.additional_info && <Text>{I18n.t('materialforms.additional_info')}: { commentRow?.additional_info }</Text>}
                              </Text>
                            </Flex>
                            <Box w={ { base: "100%", md: "20%" } } style={{textAlign:"right"}} bg="bgColor">
                              <Text py="2" px={ [2, 4, 2, 4] }>{I18n.t('materialforms.products_total_price')} {this.getPrice(select, group) } €</Text>
                              <Text py="2" px={ [2, 4, 2, 4] }>{I18n.t('materialforms.labor_price')} { this.getLaborPrice(select) } €</Text>
                            </Box>
                          </Flex>
                        </Box>

                        <Flex className="print-offer-product" direction={ { base: "row"} }>
                          <Box>
                            <Text>{ selectionCode } { selectionText }</Text>
                            {commentRow?.additional_info && <Text>{I18n.t('materialforms.additional_info')}: { commentRow?.additional_info }</Text>}
                          </Box>
                          <Box style={{textAlign:"right"}}>
                            <Text>{I18n.t('materialforms.labor_price')}: { this.getLaborPrice(select) } €</Text>
                          </Box>
                        </Flex>
                      </>
                    )
                  }) }
                </>
              )
            })
            }
            {/* selectedAdditionalProducts && selectedAdditionalProducts.length > 0 && <Heading>{I18n.t('materialforms.additional_products')}</Heading> }
            {
              selectedAdditionalProducts.map((additionalProduct) => {
                return (
                  <>
                    <Box className="product-container print-hide" border="3px solid" borderColor="gray.lightest" mb="4">
                      <Flex alignItems="center" justify="space-between" px={ [2, 4, 2, 4] } py="2" borderBottom="2px solid" borderColor="gray.lightest">
                        <Heading>{ additionalProduct.name }</Heading>
                      </Flex>
                      <Flex direction={ { base: "column", md: "row" } }>
                        <Text py="2" px={ [2, 4, 2, 4] } w={ { base: "100%", md: "20%" } } fontWeight="bold">{ additionalProduct.name }</Text>
                        <Flex py="2" px={ [2, 4, 2, 4] } flex="1" direction={ { base: "column", md: "row" } }>
                          <Image style={ { maxWidth: '60px', maxHeight: '60px' } } src={ additionalProduct.product_photo ? additionalProduct.product_photo : "/assets/images/product_img.png" } alt="Product photo" />
                          <Text ml={ { base: "none", md: "2" } }>{ additionalProduct.description }</Text>
                        </Flex>

                        <Box w={ { base: "100%", md: "20%" } } style={{textAlign:"right"}} bg="bgColor">
                          <Text py="2" px={ [2, 4, 2, 4] }>{ additionalProduct.price } €</Text>
                          <Text py="2" px={ [2, 4, 2, 4] }>{I18n.t('materialforms.labor_price')} { additionalProduct.labor_price } €</Text>
                        </Box>
                      </Flex>
                    </Box>

                    <Flex className="print-offer-product" direction={ { base: "row"} }>
                      <Box>
                        <Text>{ additionalProduct.name }</Text>
                        <Text ml={ { base: "none", md: "2" } }>{ additionalProduct.description }</Text>
                      </Box>
                      <Box style={{textAlign:"right"}}>
                        <Text>{I18n.t('materialforms.product_prize')} { additionalProduct.price } €</Text>
                        <Text>{I18n.t('materialforms.labor_price')}: { additionalProduct.labor_price } €</Text>
                      </Box>
                    </Flex>

                  </>
                )
              })
            */}
          </>
        }
      </Box>
    )
  }
}

export default MaterialOfferChoiceListing;
