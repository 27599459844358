import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import I18n from 'i18n-js';

import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import AvainiaCore from 'avainia-core-api';

// Components
import MaterialFormStatusBadge from '../badges/MaterialFormStatusBadge/MaterialFormStatusBadge.js';
import MaterialFormButton from '../buttons/MaterialFormButton/MaterialFormButton.js';

// Icons
import { BsArrowRightShort } from 'react-icons/bs';

// Chakra
import { Box, Badge, Text } from '@chakra-ui/react';
import Moment from 'react-moment';

class MaterialFormStatusBar extends Component {
  sendMaterialFormChoices = (formId, submissionForm, status) => {
    const submissionFormId = submissionForm.id;
    const payload = {
      status: status,
      submissionFormId,
      selections: submissionForm.material_selections,
    };

    if (!window.confirm(I18n.t('materialforms.send-materialform-confirm'))) {
      return;
    }

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.materialFormSubmissionSend(formId, payload).then((result) => {
      if (result.error) {
        return this.setState({ error: result.error });
      }
      window.location.reload(); // TODO: Improve
    });
  };

  downloadQuotePDF = async (id, apt) => {
    try {
      const obj = { headers: { Authorization: `Bearer ${LocalStorageService.getToken()}` }};
      const apiRoute = `materialquotes/${id}/generate`;
      const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/v1/${apiRoute}`, obj);
      const name = `Tarjous_${apt.stairwell}_${apt.apartment_number}`;

      if (res.ok) {
        const binary = await res.blob();
        const fileName = name + '.pdf';
        const src = window.URL.createObjectURL(binary);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.cssText = 'display: none';
        a.href = src;
        a.download = fileName;
        window.open(src, '_blank');
        setTimeout((x) => { document.body.removeChild(a); }, 1000);
      } else if (res.status !== 410) { // 410 means dont retry
        throw new Error('failed to fetch document'); // TODO! Fatal error, do actual logging
      }
    } catch (ex) {
      console.error(ex); // TODO! Fatal error, do actual logging
    }
  }

  downloadRoomCard = async (quote) => {
    try {
      const obj = {
        headers: { Authorization: `Bearer ${LocalStorageService.getToken()}` },
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_HOST}/api/v1/room-card/${quote.id}/download`,
        obj
      );
      const name = `Huonekortti_${this.props.apartment.stairwell}_${this.props.apartment.apartment_number}`;

      if (res.ok) {
        const binary = await res.blob();
        const extension = binary.type === 'application/pdf' ? '.pdf' : '.docx';
        const fileName = name + extension;

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(binary, fileName);
        } else {
          const src = window.URL.createObjectURL(binary);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style.cssText = 'display: none';
          a.href = src;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(src);
          setTimeout((x) => {
            document.body.removeChild(a);
          }, 1000);
        }
      } else if (res.status !== 410) {
        // 410 means dont retry
        throw new Error('failed to fetch document'); // TODO! Fatal error, do actual logging
      }
    } catch (ex) {
      console.error(ex); // TODO! Fatal error, do actual logging
    }
  };

  approveRoomCard = (quote) => {
    if (!window.confirm(I18n.t('materialforms.accept-room-card-confirm'))) {
      return;
    }
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.roomCardApprove(quote.id).then((result) => {
      if (result.error) {
        return this.setState({ error: result.error });
      }
      window.location.reload(); // TODO: Improve
    });
  };

  render() {
    const {
      form,
      deadline,
      bg,
      deadlineHasPassed,
      roomcarddeadline,
      roomcarddeadlineHasPassed,
      disabled,
      submissionForm,
      quote,
      status,
      activePhase,
      isAdmin,
    } = this.props;

    let printBtnText = '';
    let enablePrint = false;
    let callback = null;
    switch (activePhase) {
      case 1:
        printBtnText = I18n.t('materialforms.print.form');
        enablePrint = isAdmin;
        callback = () => this.props.activateCallback(true);
        break;
      case 2:
        printBtnText = I18n.t('materialforms.print.quote');
        enablePrint = quote && !quote.skipped && isAdmin ? true : false;
        callback = () => this.downloadQuotePDF(quote.id, this.props.apartment);
        break;
      case 3: // This is unused at the moment
        printBtnText = I18n.t('materialforms.print.room-card');
        enablePrint = false;
        break;
      default:
        printBtnText = I18n.t('materialforms.print.form');
        enablePrint = isAdmin;
        callback = () => this.props.activateCallback(true);
    }

    return (
      <Box
        display={'flex'}
        my={1}
        mx={6}
        alignItems="center"
        justifyContent={'space-between'}
        bgColor={'gray.lightGray'}
        flexWrap={'wrap'}
      >
        <Box py={4} display={'flex'} alignItems="center">
          <Text fontSize={'sm'} mx={4} fontWeight={'bold'}>
            {form.name}
          </Text>

          <MaterialFormStatusBadge
            bg={bg}
            contentText={this.props.contentText}
          />
          {/* Deadline for materialform selections */}
          {(activePhase === 1) && (
            <>
              {deadline && deadline !== '0000-00-00 00:00:00' && (
                <Text
                  mx={4}
                  textTransform={'initial'}
                  fontWeight={'normal'}
                  fontSize={'sm'}
                  textAlign={'center'}
                >
                  {I18n.t('materialforms.form_deadline')}{' '}
                  {<Moment format="DD.MM.YYYY HH:mm">{deadline}</Moment>}
                </Text>
              )}
              {deadlineHasPassed && (
                <Badge mx={2} variant="outline" colorScheme="red">
                  {I18n.t('materialforms.deadline-passed')}
                </Badge>
              )}
            </>
          )}
          {/* Deadline for roomcards */}
          {(activePhase === 3) && (
            <>
              {roomcarddeadline && roomcarddeadline !== '0000-00-00 00:00:00' && (
                <Text
                  mx={4}
                  textTransform={'initial'}
                  fontWeight={'normal'}
                  fontSize={'sm'}
                  textAlign={'center'}
                >
                  {I18n.t('materialforms.form_deadline')}{' '}
                  {<Moment format="DD.MM.YYYY HH:mm">{roomcarddeadline}</Moment>}
                </Text>
              )}
              {roomcarddeadlineHasPassed && !quote.room_card_approved && (
                <Badge mx={2} variant="outline" colorScheme="red">
                  {I18n.t('materialforms.deadline-passed')}
                </Badge>
              )}
            </>
          )}
        </Box>
        <Box py={4} pr={3} display={'flex'} alignItems="center">
          {enablePrint && (
            <MaterialFormButton
              icon={false}
              variant={'lightgray-btn-lighter'}
              content={printBtnText}
              onClick={(form, submissionForm, quote) =>
                callback(form, submissionForm, quote)
              }
            />
          )}
          {activePhase === 1 && (
            <MaterialFormButton
              icon={<BsArrowRightShort size="26" className="icon" />}
              variant={'yellow-forward-btn'}
              content={
                status === 'open' && submissionForm && !quote
                  ? !submissionForm.initial_selections_saved
                    ? I18n.t('materialforms.make_choices')
                    : I18n.t('materialforms.edit_choices')
                  : I18n.t('materialforms.view_choices')
              }
              onClick={() => this.props.activateCallback()}
            />
          )}

          {status === 'open' && !quote && (
            <MaterialFormButton
              variant="green-request-btn"
              icon={<BsArrowRightShort size="26" className="icon" />}
              content={I18n.t('materialforms.send-materialform')}
              disabled={
                disabled ||
                !submissionForm ||
                !submissionForm.initial_selections_saved
              }
              onClick={() =>
                this.sendMaterialFormChoices(form.id, submissionForm, 'sent')
              }
            />
          )}

          {quote &&
            activePhase === 2 &&
            (quote.status === 'sent' || quote.status === 'approved') && !quote.skipped && (
              <MaterialFormButton
                variant="green-request-btn"
                icon={<BsArrowRightShort size="26" className="icon" />}
                onClick={() => this.props.openMaterialFormOfferCallback()}
                content={
                  quote.status === 'sent'
                    ? I18n.t('materialforms.accept-offer')
                    : I18n.t('materialforms.open-offer')
                }
              />
            )}
          {quote && quote.custom_room_card && activePhase === 3 && (
            <>
              <MaterialFormButton
                variant={
                  quote.room_card_approved
                    ? 'green-request-btn'
                    : 'lightgray-btn-lighter'
                }
                icon={
                  quote.room_card_approved ? (
                    <BsArrowRightShort size="26" className="icon" />
                  ) : (
                    false
                  )
                }
                onClick={() => this.downloadRoomCard(quote)}
                content={I18n.t('materialforms.download-room-card')}
              />
              {!quote.room_card_approved && (
                <MaterialFormButton
                  variant="green-request-btn"
                  icon={<BsArrowRightShort size="26" className="icon" />}
                  onClick={() => this.approveRoomCard(quote)}
                  content={I18n.t('materialforms.accept-room-card')}
                />
              )}
            </>
          )}
        </Box>
      </Box>
    );
  }
}

export default withRouter(MaterialFormStatusBar);
