import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { animateScroll as scroll, scroller } from 'react-scroll'
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import AvainiaCore from 'avainia-core-api';
import UtilService from '../../../AvainiaTools/UtilService.js';
import I18n from 'i18n-js';

// Components
import ProductSelectGrid from '../ProductSelectGrid/ProductSelectGrid.js';
import FormElements from '../../layouts/FormElements/FormElements.js';
import AdditionalProductsSelectGrid from '../AdditionalProductsSelectGrid/AdditionalProductsSelectGrid.js';
import MaterialFormAdditionalInfo from '../MaterialFormAdditionalInfo/MaterialFormAdditionalInfo.js';


//Icons
import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";

//Chakras
import Icon from '@chakra-ui/icon';
import { Box } from '@chakra-ui/layout';
import { Alert, AlertIcon, Button, ButtonGroup, Divider, Flex, Heading, Spinner, Center } from "@chakra-ui/react";

class MaterialForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSavedAlert: false,
      additionalInfos: [],
      additionalProductComments: [],
      key: Math.random(),
      loading: true,
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.materialSelectionsPerSubmissionFormGet(this.props.project.id, this.props.apartment.id, this.props.targetMaterialSubmissionForm.id).then((selections) => {
      if (selections.error) { return this.setState({ error: selections.error }); }

      UtilService.convertMaterialform(this.props.mf).then((converted) => {
        if (converted.error) { return this.setState({ error: converted.error }); }

        api.materialFormSubmissionGet(this.props.apartment.id, this.props.mf.id).then((submissionForm) => {
          if (submissionForm.error) { return this.setState({ error: submissionForm.error }); }
          let selectedAdditionalProducts = [];

          submissionForm.additional_product_selections.forEach((selection) => {
            selectedAdditionalProducts.push(submissionForm.additional_products.find((product) => product.id === selection.additional_product_id));
          });

          this.setState({
            materialform: this.props.mf,
            apartment: this.props.apartment,
            project: this.props.project,
            selections,
            submissionForm,
            choicesDisabled: this.props.disabled,
            selectedAdditionalProducts: selectedAdditionalProducts, // This is an array of AdditionalProducts
            additionalProductSelections: submissionForm.additional_product_selections, // This is an array of AdditionalProductSelections
          }, this.makeSureEachSelectionHasAnOptionSelected);
        })
      })
    })
  }

  makeSureEachSelectionHasAnOptionSelected = () => {
    // Go through materialform and selections. Loop through all groups -> selections,

    let selections = [];

    this.state.materialform.groups.forEach((group) => {
      group.selects.forEach((select) => {
        // Check a selection exists in selections for this select
        // eslint-disable-next-line eqeqeq
        const selection = this.state.selections.find((s) => s.material_form_select_id == select.id);
        // If a selection for this select is not found, use the default.
        if (!selection) {
          let breaker = false;
          select.options.forEach((option) => {
            if (breaker) { return; }
            if (option.isDefault) {
              option.selected = true;
              selections.push(option)
              breaker = 1;
            }
          });
        }
      });
    });

    selections = selections.concat(this.state.selections);
    this.setState({ selections }, () => this.setState({loading: false}));
  }

  selectAdditionalProduct = (product, isAdded) => {

    var additionalProducts = this.state.selectedAdditionalProducts;

    if (!isAdded) {
      this.setState(prevState => ({
        selectedAdditionalProducts: [...additionalProducts.filter(prod => prod.id !== product.id)]
      }))
    } else {
      this.setState(prevState => ({
        selectedAdditionalProducts: [...additionalProducts, product]
      }))
    }
  }

  selectOption = (groupTarget, selectTarget, optionTarget) => {
    const materialform = { ...this.state.materialform };
    const group = materialform.groups.find((g) => g.id === groupTarget.id);
    const select = group.selects.find((s) => s.id === selectTarget.id);

    var selections = this.state.selections.filter((selection) => {
      if (selectTarget.id !== selection.material_form_select_id) { return true; }
      return false;
    });

    select.options.forEach((option) => {
      option.selected = false;
      if (option.id === optionTarget.id) {
        option.selected = true;
        selections.push({
          material_form_select_id: select.id,
          material_form_option_id: option.id,
          labor_price: option.labor_price,
          name: option.name,
          price: option.price,
          product_image: option.product_photo,
          product_context_image: option.product_context_image,
          product_id: optionTarget.product_id,
          product: optionTarget.product_obj
        });
      }
    });

    this.setState({ selections, materialform });
  }

  saveAndQuit = () => {
    this.save(true);
  }

  save = (quitAfter = false) => {
    if (this.state.loading) { return; }
    if (!this.props.apartment) { return; }

    const apartmentId = this.props.apartment.id;
    const api = new AvainiaCore(LocalStorageService.getToken);
    const materialFormId = this.state.materialform.id;
    const materialFormSubmissionId = this.state.submissionForm.id || null;
    const { residents_stay_during_repairs, info_background, info_keep, info_keep_reinstall, info_keep_store, info_free_word } = this.state.submissionForm;
    const selectedAdditionalProducts = this.state.selectedAdditionalProducts;

    let selections = [];

    this.state.materialform.groups.forEach((group) => {
      group.selects.forEach((select) => {
        // Check a selection exists in selections for this select
        // eslint-disable-next-line eqeqeq
        selections.push(this.state.selections.find((s) => s.material_form_select_id == select.id));
      })
    })

    const payload = [];
    const data = {};

    data['apartment_id'] = apartmentId;
    data['materialform_id'] = materialFormId;
    data['residents_stay_during_repairs'] = residents_stay_during_repairs;
    data['info_background'] = info_background;
    data['info_keep'] = info_keep;
    data['info_keep_reinstall'] = info_keep_reinstall;
    data['info_keep_store'] = info_keep_store;
    data['additional_products'] = selectedAdditionalProducts;
    data['info_free_word'] = info_free_word;
    data['initial_selections_saved'] = true;

    selectedAdditionalProducts.forEach((product) => {
      const comment_obj = this.state.additionalProductComments[product.id];
      product.comment = comment_obj ? comment_obj.comment : '';

      if(!product.comment){
        let selection = this.state.additionalProductSelections.find((x) => x.additional_product_id === product.id);
        product.comment = selection ? selection.comment : product.comment;
      }
    });

    data['additional_products'] = selectedAdditionalProducts;

    this.state.materialform.groups.forEach((group) => {
      group.selects.forEach((select) => {
        select.options.forEach((option) => {

          const currentSelection = selections.find((selection) => selection?.id === option.material_form_select_id);
          let data;
          if (option.selected) {

            data = Object.values(this.state.additionalInfos).find(obj => obj.optionId === option.id);
            let additional_info = '';
            if (data) {
              additional_info = data.additional_info;
            }

            payload.push({
              material_form_select_id: select.id,
              material_form_option_id: option.id,
              name: option.name,
              price: option.price,
              additional_info
            });
          }
          else {
            if (currentSelection) {
              data = Object.values(this.state.additionalInfos).find(obj => obj.optionId === currentSelection.id);
              if (data) {
                const additional_info = data.additional_info;
                payload.push({
                  material_form_select_id: select.id,
                  material_form_option_id: option.id,
                  name: option.name,
                  price: option.price,
                  additional_info
                });
              }
            }
          }
        });
      });
    });

    data['payload'] = payload;

    if (materialFormSubmissionId) {
      api.materialSelectionsUpdate(materialFormSubmissionId, data).then((selections) => {
        if (this.state.showSavedAlert === false) {
          this.setState({ showSavedAlert: !this.state.showSavedAlert });
          setTimeout(function () {
            this.setState({ showSavedAlert: false });
          }.bind(this), 3000);
        }

        if (selections.error) { return this.setState({ error: selections.error }); }

        if(quitAfter){
          this.props.disable();
        }

      });
    } else {
      api.materialSelectionsCreate(data).then((selections) => {
        if (selections.error) { return this.setState({ error: selections.error }); }
      });
    }
    this.setState({ key: Math.random() }, () => {
    })
  }

  callback = (product, data, optionId) => {
    const id = product.id;

    this.setState((prevState) => ({
      additionalInfos: { ...prevState.additionalInfos, [id]: { id: id, additional_info: data, optionId } }
    }))
  }

  additionalInfoCallback = (product, data) => {
    const id = product.id;

    this.setState((prevState) => ({
      additionalProductComments: { ...prevState.additionalProductComments, [id]: { id: id, comment: data } }
    }))
  }

  onSelect = (tab) => { this.setState({ tab }); }

  getTotalPrice = () => {
    let accumulator = 0;
    this.state.selections.forEach((selection) => {
      let select = false;

      this.state.materialform.groups.forEach((g) => {
        if (select) { return; }
        // eslint-disable-next-line eqeqeq
        select = g.selects.find((s) => s.id == selection.material_form_select_id);
      });

      // Probably, hopefully, this means that this selection is for some other materialform
      // or it is manually added through editable quote rows
      if (!select || !select.product_category_id ) { return; }

      const price = Number(selection.price);
      const s = select.options.find((option) => option.product_id === selection.product_id);
      let packageAmount = 1;
      if (s.package_amount) {
        packageAmount = s.package_amount;
      }
      const laborPrice = selection.material_form_option ? Number(selection.material_form_option.labor_price) : Number(selection.labor_price);
      accumulator += laborPrice;
      accumulator += packageAmount !== 0 || packageAmount !== null ? price * packageAmount : price;
    });
    this.state.selectedAdditionalProducts.forEach((additionalProduct) => {
      const price = Number(additionalProduct.price) + Number(additionalProduct.labor_price);
      accumulator += price;
    })

    const price = Number(accumulator).toFixed(2);
    this.props.materialformPriceCallback(price);

    return Number(accumulator).toFixed(2);
  }

  getMappedSelections = () => {
    const mapped = [];

    this.state.selections.forEach((selection) => {
      let select = false;
      this.state.materialform.groups.forEach((g) => {
        if (select) { return; }
        select = g.selects.find((s) => s.id === selection.material_form_select_id);
      });

      if (!select) { return; /* Probably, hopefully, this means that this selection is for some other materialform */ }

      mapped.push({
        select,
        ...selection,
      });
    });

    return mapped;
  }

  onChange = (name, value) => {
    this.setState((prevState) => ({
      submissionForm: { ...prevState.submissionForm, [name]: value, },
    }));
  }

  changePage = (direction) => {

    scroll.scrollToTop()

    const pages = [
      { id: 0, name: I18n.t('materialforms.general_instructions') },
      ...this.props.mf.groups,
      {id: 1000000, name: I18n.t('materialforms.additional_products')},
      {id: 2000000, name: I18n.t('materialforms.additional_info')}
    ];

    const currentPageId = this.props.category.id;
    const currentPage = pages.findIndex((group) => group.id === currentPageId);
    let index = null;

    if (direction === 'next') {
      index = currentPage + 1;
    } else if (direction === 'previous') {
      index = currentPage - 1;
    }

    let page = pages[index];

    if(page){
      this.props.activeMenuItemCallback(page);
    }
  }

  render() {
    const mapped = this.state.selections && this.getMappedSelections();
    const currentPage = this.props.category.id;
    const pages = [
      { id: 0, name: I18n.t('materialforms.general_instructions') },
      ...this.props.mf.groups,
      {id: 1000000, name: I18n.t('materialforms.additional_products')},
      {id: 2000000, name: I18n.t('materialforms.additional_info')}
    ];
    const lastPage = pages.reduce((acc, page) => acc = acc > page.id ? acc : page.id, 0)
    const disabled = this.state.choicesDisabled
    let key = this.state.key;

    return (
      <Box p={ [4, 8, 4, 8] } bg="white">
        { this.state.loading && (
          <Center>
            <Spinner size="xl" />
          </Center>
        )}
        { this.state.materialform && <>
          <Box>
            { this.state.submissionForm && this.props.category.id === 0 &&
              <FormElements
                disabled={ disabled }
                submissionForm={ this.state.submissionForm }
                currentPage={ this.props.category }
                nextPage={ this.props.mf.groups }
                onChange={ (name, value) => this.onChange(name, value) }
                key={ this.state.key }
              />
            }
            { (this.state.materialform.groups || []).filter((group) => group.id === this.props.category.id).map((group) =>
              <Box key={ group.id } title={ group.name }>
                <Heading mb="6">{ group.name }</Heading>

                <Box>
                  <p style={{whiteSpace: "pre-line"}}> {group.description} </p>
                </Box>

                { group.selects.map((selectionType) => {
                  const selection = mapped.find((line) => line.material_form_select_id === selectionType.id);
                  if (selection && selection.select) {
                    return <ProductSelectGrid
                      disabled={ disabled }
                      products={ selectionType }
                      productSelectedCallback={ (option) => this.selectOption(group, selectionType, option) }
                      selectedFromThisGroup={ mapped.find((line) => line.material_form_select_id === selectionType.id) }
                      selection={ selection.select }
                      onChange={ (name, value) => this.onChange(name, value) }
                      callback={ (product, data, optionId) => this.callback(product, data, optionId) }
                    />
                  }
                }) }
              </Box>)
            }
            { this.state.submissionForm && this.props.category.id === 1000000 &&
              <AdditionalProductsSelectGrid
                disabled={ disabled }
                products={ this.state.submissionForm.additional_products }
                productSelectedCallback={ (product, isAdded) => this.selectAdditionalProduct(product, isAdded) }
                productsSelectedFromThisGroup={ this.state.selectedAdditionalProducts }
                productSelections={ this.state.additionalProductSelections }
                callback={ (product, data) => this.additionalInfoCallback(product, data) }
              />
            }
            { this.state.submissionForm && this.props.category.id === 2000000 &&
              <MaterialFormAdditionalInfo
                disabled={ disabled }
                submissionForm={ this.state.submissionForm }
                onChange={ (name, value) => this.onChange(name, value) }
                key={ this.state.key }
              />
            }
          </Box>
          <ButtonGroup>
            <Flex direction={ { base: "column", md: "row" } }>
              <Button m="1" onClick={ this.props.disable } variant="grey-forward-btn">{ I18n.t('materialforms.button_cancel') }</Button>
              <Button disabled={ disabled } m="1" onClick={() => this.save(false) } variant="green-forward-btn">{ I18n.t('materialforms.button_save') }</Button>
              {
                currentPage !== 0 &&
                <Button m="1" onClick={ () => this.changePage('previous') } variant="yellow-forward-btn">
                  <Icon as={ BsArrowLeftShort } display={ { base: "none", xl: "block" } } w={ 6 } h={ 6 } color="black" />
                  { I18n.t('materialforms.previous_page') }
                </Button>
              }
              {
                currentPage < lastPage &&
                <Button m="1" onClick={ () => this.changePage('next') } variant="yellow-forward-btn">{ I18n.t('materialforms.next_page') }
                  <Icon as={ BsArrowRightShort } display={ { base: "none", xl: "block" } } w={ 6 } h={ 6 } color="black" />
                </Button>
              }
              {
                currentPage == lastPage &&
                <Button disabled={ disabled } m="1" onClick={() => this.save(true) } variant="green-forward-btn">{ I18n.t('materialforms.button_save_and_quit') }</Button>
              }
            </Flex>
          </ButtonGroup>
        </>
        }
        { this.state.showSavedAlert &&
          <Alert status="success" borderRadius="40px" w="fit-content" mt="4">
            <AlertIcon />
            { I18n.t('materialforms.saved') }
          </Alert>
        }
        {!this.state.loading && <Divider />}
        { this.state.selections &&
          <div>
            <div sm={ 8 }><b>{ I18n.t('general.price_total') }</b></div>
            <div sm={ 2 }><b>{ this.getTotalPrice() }</b></div>
            <div sm={ 2 }></div>
          </div>
        }
      </Box>
    )
  }
}

export default withRouter(MaterialForm);
