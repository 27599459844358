import React, { Component } from 'react';

//Chakras
import { Button, Flex, Spacer } from "@chakra-ui/react";
import { Box, Text, Heading } from '@chakra-ui/layout';

//Icons
import Icon from '@chakra-ui/icon';
import { BsPencil, BsPlusCircle } from 'react-icons/bs';

import { Image } from '@chakra-ui/image';

//Partials
import MaterialFormCommentModal from '../../partials/MaterialFormCommentModal/MaterialFormCommentModal.js';


class MaterialSummaryPicks extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = () => {
    this.setState((prevstate) => ({ isOpen: !prevstate.isOpen, }));
  }

  componentDidMount = () => {

  }

  render() {
    return (
      <Box className="bathroom-information">

      <MaterialFormCommentModal isOpen={this.state.isOpen} handleClick={this.handleClick}
      room="Tila: kylpyhuone"/>

          <Box className="general-information-outer" p={4, 8, 4, 8}>
            <Heading className="summary-header">Kylpyhuone</Heading>
            <Box className="bathroom-information-inner" p={2, 4, 2, 4} paddingBottom="0px">
              <Heading className="bathroom-subheader">Seinälaatat</Heading>
              <div onClick={this.handleClick} className="MaterialSummaryPicks">
              <Button className="edit-button2">
              <Icon as={BsPencil} w={7} h={7} color="black" marginRight="3" />Muokkaa valintoja</Button>
              </div>
            </Box>

        <Box className="bathroom-information-innermost" display={{ base: "none", xl: "block" }}
             background="linear-gradient(90deg, #ffffff 80%, #e8e8e8 20%)">
        <Flex className="materials-flex">
          <Box w="230px" h="16">
            <Text fontWeight="bold">Pääväri</Text>
          </Box>
          <Box w="430px" h="16">
            <Flex>
            <Image src="/assets/images/40x40.png" alt="placeholder" />
            <Text marginLeft="10px">Tuotekoodi, Tuotemerkki, Nimi, koko, hinta / m²<Text fontWeight="bold" fontStyle="italic">
              MENEKKI: huonepohja-arvio 8m². osakkaan arvio 12m²</Text></Text>
            </Flex>
          </Box>
          <Spacer />
          <Flex>
            <Box>
            <Text fontWeight="bold" paddingRight="160px">0 €</Text>
            </Box>
        </Flex>
        </Flex>

        <Flex className="materials-flex">
        <Box w="230px" h="16">
            <Text fontWeight="bold">Tehosteväri</Text>
          </Box>
          <Box w="430px" h="16">
            <Flex>
            <Image src="/assets/images/40x40.png" alt="placeholder" />
            <Text color="red" marginLeft="10px">Tuotekoodi, Tuotemerkki, Nimi, koko, hinta / m²
            <Text color="black" fontWeight="bold" fontStyle="italic">
              MENEKKI: huonepohja-arvio 8m². osakkaan arvio 12m²</Text></Text>
            </Flex>
          </Box>
          <Spacer />
          <Flex>
            <Box>
              <Text fontWeight="bold" paddingRight="153px">85 €</Text>
              </Box>
              </Flex>
              </Flex>
            </Box>

            <Box className="bathroom-information-inner2" p={2, 4, 2, 4} paddingBottom="10px">
              <Heading className="bathroom-floor-subheader">Lattialaatat</Heading>
            </Box>

        <Box className="bathroom-information-innermost" display={{ base: "none", xl: "block" }}
             background="linear-gradient(90deg, #ffffff 80%, #e8e8e8 20%)">
        <Flex className="materials-flex">
          <Box w="230px" h="16">
            <Text fontWeight="bold">Pääväri</Text>
          </Box>
          <Box w="430px" h="16">
            <Flex>
            <Button className="choicemaking-btn">
              <Icon as={BsPlusCircle} w={6} h={6} color="black" marginRight="3" />Tee valinnat
            </Button>
            </Flex>
          </Box>
        </Flex>

        <Flex className="materials-flex">
        <Box w="230px" h="16">
            <Text fontWeight="bold">Saumavärit</Text>
          </Box>
          <Box w="430px" h="16">
            <Flex>
            <Button className="choicemaking-btn">
              <Icon as={BsPlusCircle} w={6} h={6} color="black" marginRight="3" />Tee valinnat
            </Button>
            </Flex>
          </Box>
              </Flex>
            </Box>
          </Box>
            </Box>
            )
          }
        }

        export default MaterialSummaryPicks;
