import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import I18n from 'i18n-js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

//Icons
import { BsList } from "react-icons/bs";

//Chakra
import { Box, Flex, Link, Text } from '@chakra-ui/layout';
import { Button, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Icon, Image } from "@chakra-ui/react"

class NavigationMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      toggleNavigationMenu: false,
    };
  }

  logout = () => {
    LocalStorageService.clearUser();
    window.location.assign("/");
    //window.location.reload();
  };

  componentDidMount = () => {
    const user = LocalStorageService.getUser();

    this.setState(() => ({
      user,
    }));

  }

  render() {
    const containerVariant = this.props.containerVariant;
    const toggleNavigationMenu = this.state.toggleNavigationMenu;
    const { name } = this.state.user;
    const params = `${this.props.match.params.id}/${this.props.match.params.projectId}`;
    const condominiumData = LocalStorageService.getCondominium();
    const condominiumName = condominiumData ? condominiumData.name : I18n.t('navigation.condominium');

    return (
      <>
        {this.props.match.params.id !== undefined ?
          <Box className="print-hide" display={{ base: "block", md: "none" }}>
            <Flex justifyContent="space-between" px={containerVariant === "mobile" ? "4" : "0"}>
              <Box w="20%" alignSelf="center">
                <Icon as={BsList} w={8} h={8} onClick={() => this.setState({ toggleNavigationMenu: !toggleNavigationMenu })} cursor="pointer" />
              </Box>
              <Box p="15" display={{ base: "block", lg: "none" }}>
                <Image src="/assets/images/company-logo.png" alt="logo" />
              </Box>
              <Box w="20%"></Box>
            </Flex>
            <Drawer
              isOpen={toggleNavigationMenu}
              placement="left"
              onClose={() => this.setState({ toggleNavigationMenu: !toggleNavigationMenu })}
            >
              <DrawerOverlay>
                <DrawerContent bg="gray.darkGray">
                  <DrawerHeader pt="12">
                    <Flex mb="8">
                      <Box>
                        <Text color="white" fontSize="xs" fontWeight="normal">{name}</Text>
                        <Text color="white" fontSize="xs" fontWeight="normal">{condominiumName}</Text>
                        <Link href="/"><Text color="base.yellow" fontSize="xs" fontWeight="normal">{I18n.t('navigation.switchApartment')}</Text></Link>
                      </Box>
                    </Flex>
                    {/*<Button
                      variant="yellow-rounded-btn"
                        w="100%"
                        as={NavLink}
                        exact to={`/condominium/${params}/materialforms`}
                        onClick={() => this.setState({ toggleNavigationMenu: !toggleNavigationMenu })}
                    >
                      {I18n.t('navigation.offerReceived')}
                    </Button>*/}
                  </DrawerHeader>
                  <DrawerBody p="0">
                    <Flex direction="column">
                      <Link
                        as={NavLink}
                        exact to={`/condominium/${params}`}
                        onClick={() => this.setState({ toggleNavigationMenu: !toggleNavigationMenu })}
                        variant="mobile-navigation-link" bg="gray.lighter"
                      >
                        {I18n.t('navigation.frontpage')}
                      </Link>
                      <Link
                        as={NavLink}
                        exact to={`/condominium/${params}/materialforms`}
                        onClick={() => this.setState({ toggleNavigationMenu: !toggleNavigationMenu })}
                        variant="mobile-navigation-link"
                      >
                        {I18n.t('navigation.materialSelection')}
                      </Link>
                      <Link
                        as={NavLink}
                        exact to={`/condominium/${params}/notifications`}
                        onClick={() => this.setState({ toggleNavigationMenu: !toggleNavigationMenu })}
                        variant="mobile-navigation-link"
                        bg="gray.lighter"
                      >
                        {I18n.t('navigation.notifications')}
                      </Link>
                      <Link
                        as={NavLink}
                        exact to={`/condominium/${params}/projectmaterials`}
                        onClick={() => this.setState({ toggleNavigationMenu: !toggleNavigationMenu })}
                        variant="mobile-navigation-link"
                      >
                        {I18n.t('navigation.projectMaterials')}
                      </Link>
                      <Link
                        as={NavLink}
                        exact to={`/condominium/${params}/schedules`}
                        onClick={() => this.setState({ toggleNavigationMenu: !toggleNavigationMenu })}
                        variant="mobile-navigation-link"
                        bg="gray.lighter"
                      >
                        {I18n.t('navigation.schedule')}
                      </Link>
                      <Link
                        as={NavLink}
                        exact to={`/condominium/${params}/company`}
                        onClick={() => this.setState({ toggleNavigationMenu: !toggleNavigationMenu })}
                        variant="mobile-navigation-link"
                      >
                        {I18n.t('navigation.company')}
                      </Link>
                      <Link
                        as={NavLink}
                        exact to={`/condominium/${params}/contacts`}
                        onClick={() => this.setState({ toggleNavigationMenu: !toggleNavigationMenu })}
                        variant="mobile-navigation-link"
                        bg="gray.lighter"
                      >
                        {I18n.t('navigation.contact')}
                      </Link>
                      <Link variant="mobile-navigation-link" onClick={this.logout}>{I18n.t('login.logout')}</Link>
                    </Flex>
                  </DrawerBody>
                </DrawerContent>
              </DrawerOverlay>
            </Drawer>
          </Box>
          :
          <Box display={{ base: "block", md: "none" }}>
            <Flex justifyContent="space-between" px={containerVariant === "mobile" ? "4" : "0"}>
              <Box w="20%">
              </Box>
              <Box p="15" display={{ base: "block", lg: "none" }}>
                <Image src="/assets/images/company-logo.png" alt="logo" />
              </Box>
              <Box w="20%"></Box>
            </Flex>
          </Box>
        }
      </>
    );
  }
}

export default withRouter(NavigationMobile);
