const divider = {
  baseStyle: {
    opacity: 0.5,
    borderColor: "gray.divider",
    borderWidth: "2px",
    my: "4"
  },
}

export default divider;
