import ConfigurationService from './ConfigurationService.js';
import GlobalsService from './GlobalsService.js';
import GPSService from './GPSService.js';
import LocalStorageService from './LocalStorageService.js';
import UtilService from './UtilService.js';

const Utils = {
  Configuration: ConfigurationService,
  Globals: GlobalsService,
  GPS: GPSService,
  LocalStorage: LocalStorageService,
  Utils: UtilService,
};

export default Utils;