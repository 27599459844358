import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

//Icons
import { BsArrowBarUp } from "react-icons/bs";
import { BsArrowRightShort } from "react-icons/bs";

// Chakra
import { SimpleGrid, Box, Flex, Spacer, Text, Heading, Link } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { Image } from '@chakra-ui/image';
import { FormControl } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';

class ForgottenPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      loading: false,
      emailSent: false
    }
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  onSubmit = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.userPasswordReset(this.state.email).then(() => {
        this.setState({ loading: false, emailSent: true }, () => {

        });
    });

  }

  home() {
    window.location.assign("/")
  }

  render() {
    return (
      <Box className="ForgottenPassword">
        <Box className="main">
          <Image src="/assets/images/company-logo.png" alt="placeholder" />
        </Box>
        <SimpleGrid columns={ [1, null, 2] } gap={ 4 } rowGap={ 0 }>
          <Box>
            <Box className="welcome-box">
              <Box>
                <Heading mb="4">{I18n.t('accountrequest.welcoming')}</Heading>
                <Text fontSize="md">
                  {I18n.t('accountrequest.portal_info')}
                  <br /><br />
                  {I18n.t('accountrequest.portal_responsibility')}
                </Text>
              </Box>
            </Box>
            <Box className="logo-boxes"
              _before={ {
                content: `""`,
              } }
              _after={ {
                content: `""`,
              } }
            >
              <Image src="/assets/images/login-screen.png" alt="placeholder" />
            </Box>
          </Box>
          <Box>
            <Box className="input-box">

              {
                this.state.emailSent
                  ?
                  <>
                    <Heading>{ I18n.t('forgottenPassword.thanks-1') }</Heading>
                    <div>{ I18n.t('forgottenPassword.thanks-2') }</div>
                  </>
                  :
                  <>

                    <Box mb="4">
                      <Heading>{ I18n.t('forgottenPassword.title') }</Heading>
                      <p>{ I18n.t('forgottenPassword.subtitle') }</p>
                    </Box>

                    <FormControl id="ForgottenPassword" pb="4">
                      <Flex>
                        <Input
                          id="email"
                          variant="request-input"
                          name="email"
                          type="text"
                          onChange={ (e) => this.onChange(e) }
                          onKeyDown={ this.onKeyDown }
                          autoFocus
                          placeholder={ I18n.t('forgottenPassword.email') }
                          mb="4"
                          isRequired
                        />
                      </Flex>
                    </FormControl>

                    <Flex justify="" mb="10">

                      <Button
                        variant="green-request-btn"
                        textAlign="center"
                        rightIcon={ <BsArrowBarUp size="26" className="icon" /> }
                        disabled={ this.state.loading }
                        type="submit"
                        onClick={ this.onSubmit }
                      >
                        { I18n.t('forgottenPassword.send-request-btn') }
                      </Button>

                      <Spacer />
                    </Flex>

                  </> }
              <Button
                variant="request-account-btn"
                rightIcon={ <BsArrowRightShort size="32" /> }
                marginTop={ ["0", null, "4"] }
                onClick={ this.home }
              >
                { I18n.t('login.back-home') }
              </Button>

            </Box>
          </Box>
          <Box>
          </Box>
        </SimpleGrid>
      </Box >
    )
  }
}

export default withRouter(ForgottenPassword);
