import React, { Component } from 'react';

//Chakra
import { Button } from '@chakra-ui/button';

class ButtonBasic extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {

  }

  render() {
    return (
      <Button
        variant="basic-btn"
        leftIcon={this.props.icon}
        rightIcon={this.props.arrow}
      >
        {this.props.text}
      </Button>
    );
  }
}

export default ButtonBasic;
