export default class LocalStorageService {

  static setProject(projectObject) {
    try {
      localStorage.setItem('project', JSON.stringify(projectObject));
    } catch (e) {
      console.log('Error writing project to localstorage');
    }
  }

  static getProject() {
    try {
      return JSON.parse(localStorage.getItem('project'))
    } catch (e) {
      console.log('Error retrieving project from localstorage', e);
    }
  }

  static setCondominium(condominiumObject) {
    try {
      localStorage.setItem('condominium', JSON.stringify(condominiumObject));
    } catch (e) {
      console.log('Error writing condominium to localstorage');
    }
  }

  static getCondominium() {
    try {
      return JSON.parse(localStorage.getItem('condominium'))
    } catch (e) {
      console.log('Error retrieving condominium from localstorage', e);
    }
  }

  static setApartment(apartmentObject) {
    try {
      localStorage.setItem('apartment', JSON.stringify(apartmentObject));
    } catch (e) {
      console.log('Error writing apartment to localstorage');
    }
  }

  static getApartment() {
    try {
      return JSON.parse(localStorage.getItem('apartment'))
    } catch (e) {
      console.log('Error retrieving apartment from localstorage', e);
    }
  }

  static setUser(userObject) {
    try {
      localStorage.setItem('userData', JSON.stringify(userObject));
    } catch (e) {
      console.log('Error writing user to localstorage');
    }
  }

  static getUser() {
    try {
      const user = JSON.parse(localStorage.getItem('userData'));

      if (!user) { return; }

      user.hasRole = (role) => {
        if (!user.roles) { return false; }
        return user.roles.find((x) => x.display_name === role);
      };

      user.hasPermission = (permission) => {
        if (user.hasRole('Super')) { return true; }
        if (!user.permissions) { return false; }
        return user.permissions.find((x) => x.name === permission);
      };

      user.isOwnerManager = () => {
        if (user.hasRole('Super')) { return true; }
        return user.hasRole('owner') && user.hasRole('manager');
      };

      return user;
    } catch (e) {
      console.log('Error retrieving user from localstorage', e);
    }
  }

  static getToken() {
    const user = LocalStorageService.getUser();
    if (!user) return null;
    return user.token;
  }

  static clearUser() {
    this.setUser({});
    localStorage.clear();
  }


}
