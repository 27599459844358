import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

//Chakras
import { Text, Box } from '@chakra-ui/layout';

class FrequentQuestions extends Component {
  render() {
    return (
      <>
        <Text>Olemme keränneet tälle sivulle meiltä usein kysyttyjä yleisiä asioita. Täydennämme sivua edelleen ja otamme mielellämme vastaan ideoita aiheista, mistä pitäisi kertoa lisää.</Text>
        <br></br>
        
        <Box>
          <strong>Mitä kaikkea ”vesikalusteet”-sana tarkoittaa? Voinko käydä itse ostamassa hanan?</strong>
          <p>Vesikalusteilla tarkoitetaan taloyhtiön käyttövesiverkostoon kytkettyjä kalusteita, joita ovat kaikki hanat, wc-istuimet ja vesikiertoiset patterit. </p>
          <br></br>
          <p>Koska kalusteet ovat osa vesijohtoverkkoa, koskee niitä asunto-osakeyhtiössä tietyt vaatimukset. Jokaiselle verkostoon asennettavalle kalusteelle tulee löytyä tyyppihyväksyntä ja hanojen tulee kuulua ääniluokkaan 1. Tuotteen myyjältä voit kysyä hyväksyntätietoja. Lisäksi VTT:n nettisivuilta löytyy yleisimpien vesikalusteiden todistuksia. </p>
          <br></br>
          <p>Ennen kuin ostat itse hanan tai muun vastaavan tuotteen, varmista, että se saadaan asentaa paikalleen. Toimita osakasmuutoksista vastaavalle henkilöllemme valitsemasi vesikalusteen tiedot, niin hän auttaa hyväksyttämään tuotteen.</p>
        </Box>
        <br></br>

        <Box>
          <strong>Haluaisin urakasta poikkeavat laatat. Onnistuuko?</strong>
          <p>Se onnistuu kyllä. Voit hankkia itse mieleisesi laatat ja toimittaa ne huoneistoosi odottamaan laatoitustöiden alkua.</p>
          <br></br>
          <p>Voit myös hyödyntää yhteistyökumppaneitamme ja käydä valitsemassa sinun kylpyhuoneessesi sopivat laatat. Kerro osakasmuutoksista vastaavalle henkilölle, mitä laattoja olet valinnut, niin hän tekee niistä sinulle tarjouksen. Jos tarjous miellyttää ja päätät tilata laatat kauttamme, me hoidamme määrälaskennan, tilauksen ja rahdit. Laatat toimitetaan asuntoosi kannettuna ja paikalleen asennettuna. Helppoa!</p>
          <br></br>
          <p>Muista valita vielä omiin laattoihisi sopivat saumavärit.</p>
          <br></br>
          <p>Jos toiveenasi on tehdä kylpyhuoneeseesi eri värisillä laatoilla tehosteseiniä, raitoja tai kuvioita, tee tarkat ohjeet huoneistokatselmukseen mennessä. Jos tarvitset apua, niin muutotyösinsinöörimme auttaa.</p>
        </Box>
        <br></br>

        <Box>
          <strong>10 + 1 vinkkiä huoneiston suojaamiseen</strong>
          <p>
            Parhaat oman asuntosi suojausohjeet saat huoneistokatselmuksen aikana, sillä asuntoa näkemättä ei voi täsmällisiä ohjeita antaa. Suojausohjeisiin vaikuttavat mm. asuntosi sisustus, kalusteratkaisut, lisätöiden laatu ja sovitun antenni- sekä telekaapeleiden reitit. Seuraavaksi joitakin yleisohjeita:
          </p>
          <br></br>
          <p>
            1) Eteinen ja kylpyhuone (wc, huoneistosauna) tulisi tyhjentää aivan kokonaan kaikesta irtaimistosta. Jos eteisessä on peiliovia tai muuten herkkiä komeron ovia, kannattaa nekin nostaa pois töiden ajaksi. Eteinen on se ruuhkaisin kulkureitti töiden aikana. Usein kylpyhuone on eteisen vieressä ja purkutöistä tulee paljon pölyä.
          </p>
          <br></br>
          <p>
            2) Kylpyhuonetta sekä wc-tilaa ja mahdollista huoneistosaunaa ympäröivät seinät ja seinän vierustat tulisi tyhjentää. Joskus myös seinärakenne on niin ohut, että työn aikana saattaa tulla ns. läpilyöntejä. Tyhjentämällä seinät, varmistut, ettei mahdollinen läpilyönti riko esimerkiksi seinällä olevaa taulua. Kun seinän vieressä on metrin verran tilaa, pääsemme myös hyvin tarkastamaan vahingot ja korjaamaan rikkoutuneen seinän.
          </p>
          <br></br>
          <p>
            3) Kylpyhuoneen työt aiheuttavat tärinää rakenteissa ja siksi seinän toisella puolella olevassa kaapissa voivat astiat ja koristeet liikkua tärinän vuoksi. On ikävä yllätys, jos kaapin etureunaan pakkautuneet astiat putovat alas.
          </p>
          <br></br>
          <p>
            4) Kaikki keittiön kaapit tulee tyhjentää, sillä se on työmaa-aluetta. Usein keittiössä tehtävät työt eivät vaikuta kovin mittavilta, mutta ennen töiden alkua emme varmasti pysty sanomaan, kuinka paljon joudumme purkamaan kaapistoja ja tekemään läpivientiaukkoja uutta tekniikkaa varten. Ennen urakkaa iso vaiva, mutta helpottaa kotiin paluuta. Muista tyhjentää jääkappi sekä pakastin ja ottaa kylmälaitteista virrat pois.
          </p>
          <br></br>
          <p>
            5) Jätämme teille katselmuksen yhteydessä suojamuovia ja teippiä. Jos tarvitset lisää suojaustarvikkeita, niin meiltä niitä saa pyytämällä. Vaikka teippi on helposti irtoavaa rakennusteippiä, niin useamman kuukauden jälkeen teipin irrotus saatta aiheuttaa ongelmia. Älä kiinnitä teippiä kalusteisiin, lattiaan, oviin, maalipintoihin, jne. Teippi saattaa irrotuksen yhteydessä repiä alla olevan pinnan mukanaan. Kiinnitä teipillä vain suojausmateriaaleja yhteen. Hyvä tapa on laittaa muovin kulmat limittäin ja kiinnittää ne teipillä toisiinsa.
          </p>
          <br></br>
          <p>
            6) Urakan sisällöstä riippuen, voi asunnossa olla huoneita, joihin meidän ei juurikaan tarvitse mennä. Näiden huoneiden oviaukon saat parhaiten tiivistettyä laittamalla vanhan verhon tai lakanan sisäpuolelta oven yli ja sulkemalla oven. Kangas jää tiivisteeksi oven reunoihin. Älä kuitenkaan lukitse ovea. Usein urakan yhteydessä uusitaan ryhmäkeskus eli sulaketaulu ja sähköasentajan tulee päästä tarkistamaan, että keskuksen vaihdon jälkeen joka huoneeseen tulee virtaa.
          </p>
          <br></br>
          <p>
            7) Vanhat lakanat, verhot, pyyhkeet ja muut vastaavat ovat putkiurakan aikana käyttökelpoisia suojausvarusteita. Remonttipöly laskeuttu suojamuovien päälle ja kun muoveja aletaan poistamaan, lähtee pöly taas helposti liikkeelle. Muovin päällä oleva kangas sitoo huokoisuutensa takia hyvin suurimman pölyn. Jos lakanat loppuvat kesken tai niitä ei ole, niin lähimmältä kirpputorilta lakanoita ja muita käyttökelpoisia kankaita löytyy pikkurahalla.
          </p>
          <br></br>
          <p>
            8) Soittimet ja elektroniikka ovat erityisen herkkiä pölylle. Siksi suosittelemmekin, että ne vietäisiin kokonaan pois asunnosta huoneiston töiden ajaksi. Jollei se ole mahdollista, niin muista huolellinen suojaus. Pienemmät laitteet voi kääriä kankaaseen ja sulkea vaikka teippaamalla jätesäkin sisään.
          </p>
          <br></br>
          <p>
            9) Kattovalaisimet tulee irrottaa urakka-alueelta ja työn aikaisilta kulkureiteiltä. Jos huoneistosi kattoa koristaa kristallikruunu tai muu upea valaisin, jota et halua irrottaa, kokeile mahtuuko jätesäkki sen ympärille. Jos mahtuu, niin pujota valaisin jätesäkin sisälle ja teippaa jätesäkki umpeen valaisimen yläpuolelta.
          </p>
          <br></br>
          <p>
            10) Kaikki arvotavara kannattaa viedä pois huoneistosta urakan ajaksi. On hyvin epätodennäköistä, että mitään varastettasiin, mutta nukut yösi rauhallisemmin, kun tiedät tavaroidesi olevan varmassa tallessa. Jos poisvienti ei ole mahdollista, niin poista ainakin arvotavarat näkyvistä ja piilota ne hyvin suojattuna asuntosi perimmäiseen nurkkaan.
          </p>
          <br></br>
          <p>
          Ja vielä yksi vinkki: Varmista, että kotivakuutuksesi on kunnossa remontin alkaessa. Jos jotain sattuu, niin asuntosi irtaimisto kuuluu kotivakuutuksen piiriin. Ihan vain varmuuden vuoksi.
          </p>
        </Box>
        <br></br>

      </>
    )
  }
}

export default withRouter(FrequentQuestions);
