import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import UtilService from '../../../AvainiaTools/UtilService.js';
import AvainiaCore from 'avainia-core-api';
import I18n from 'i18n-js';

//Icons
import { BsFolder } from "react-icons/bs";

//Components
import ButtonBasic from '../buttons/ButtonBasic/ButtonBasic.js';

//Chakra
import { Box, Heading, SimpleGrid } from '@chakra-ui/layout';

class ProjectMaterials extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.condominiumFoldersGet(this.props.projectId, 'folders').then((projectFolders) => {
      if (projectFolders.error) { return this.setState({ error: projectFolders.error }); }

      api.getFields('fields').then((fields) => {
        if (fields.error) { return this.setState({ error: fields.error }); }

        api.projectDocumentsGet(this.props.projectId, fields.fields, UtilService.dbDocumentToImage).then((documents) => {
          if (documents.error) { return this.setState({ error: documents.error }); }

          this.setState({
            documents,
            fields: fields.fields,
            loading: false,
            projectFolders,
          });
        });
      });
    });
  }

  render() {
    const { summary } = this.props;
    const { projectFolders, loading } = this.state;
    const allProjectFolders = projectFolders && [...projectFolders.folders, { id: 0, name: `${I18n.t('projectmaterials.main-folder')}`, parent_id: null }];
    const params = `${this.props.match.params.id}/${this.props.match.params.projectId}`;

    return (!loading &&
      <>
        <Box bg="white" p={[4, 8, 4, 8]}>
          <Heading mb="4">{I18n.t('projectmaterials.heading')}</Heading>
          <SimpleGrid columns={[1, null, null, 2]} gap={4}>
            {allProjectFolders && allProjectFolders.sort((a, b) => a.id.toString().localeCompare(b.id.toString())).map((folder) => {
              if (summary) {
                return (
                  <a key={folder.id} href="#" onClick={() => { window.location.href = `/condominium/${params}/projectmaterials#${folder.id}`}} >
                    <ButtonBasic
                      icon={<BsFolder size="22" />}
                      text={folder.name}
                    />
                  </a>
                )
              } return null;
            })}
          </SimpleGrid>
        </Box>
      </>
    )
  }
}

export default withRouter(ProjectMaterials);
