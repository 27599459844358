import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

//Chakras
import { Box, Divider, Text, Flex, Link, Heading, SimpleGrid, GridItem } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';

import I18n from 'i18n-js';
import { v4 as uuidv4 } from 'uuid';
import DefaultTab from './tabs/DefaultTab.js';
import EnvironmentAndSecurity from './tabs/EnvironmentAndSecurity.js';
import WorkSafety from './tabs/WorkSafety.js';
import Links from './tabs/Links.js';
import FrequentQuestions from './tabs/FrequentQuestions.js';
import Dropdown from '../../partials/buttons/Dropdown/Dropdown.js';

class CompanyPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 'default'
    };
  }

  componentDidMount = () => {

  }

  handleClick = (linkTab) => {
    this.setState({ tab: linkTab });
  }

  render() {
    const links = [
      {
        title: I18n.t("companypage.links.default"),
        tab: "default",
      },
      {
        title: I18n.t("companypage.links.environmentAndSecurity"),
        tab: "environmentAndSecurity",
      },
      {
        title: I18n.t("companypage.links.workSafety"),
        tab: "workSafety",
      },
      {
        title: I18n.t("companypage.links.links"),
        tab: "links",
      },
      {
        title: I18n.t("companypage.links.frequentQuestions"),
        tab: "frequentQuestions",
      },
    ];

    const { tab } = this.state;
    return (
      <Box p={[4, 8, 4, 8]} bg="white">
        <Heading mb={{ base: 6, xl: 0 }}>{I18n.t("companypage.heading")}</Heading>
        <Divider display={{ base: "none", lg: "block" }} />
        <Box display={{ base: "block", xl: "none" }}>
          <Dropdown links={links} tab={tab} handleClick={this.handleClick} />
        </Box>
        <SimpleGrid columns={{ base: 1, xl: 4 }} >
          <GridItem mt={4} mr={{ xl: 12 }} colSpan={{ base: 1, xl: 3 }}>
            {tab === 'default' && <DefaultTab />}
            {tab === 'environmentAndSecurity' && <EnvironmentAndSecurity />}
            {tab === 'workSafety' && <WorkSafety />}
            {tab === 'links' && <Links />}
            {tab === 'frequentQuestions' && <FrequentQuestions />}
          </GridItem>
          <GridItem display={{ base: "none", xl: "block" }} colSpan={1}>
            <Flex direction="column" px={4} pt={6} pb={2} bg="bgColor">
              {links.map((link) => {
                return <Link key={uuidv4()} mb={4} fontWeight={link.tab === tab ? "bold" : "normal"} onClick={() => this.handleClick(link.tab)}>{link.title}</Link>
              })}
            </Flex>
          </GridItem>
        </SimpleGrid>
        <Image mt={8} src="/assets/images/splash-large.png" />
      </Box>
    )
  }
}


export default withRouter(CompanyPage);
