const text = {
  baseStyle: {
    fontSize: "md"
  },
  variants: {
    "small-bold": {
      fontWeight: "bold",
      color: "text.dark",
      fontSize: "90%",
      textTransform: "uppercase",
    },
  },
}

export default text;
