import React, { Component } from 'react';

import ApartmentDropdown from '../buttons/ApartmentDropdown/ApartmentDropdown';

//Chakra
import { Image } from '@chakra-ui/image';
import { Link } from '@chakra-ui/layout';
import { Heading } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/layout';
import AvainiaCore from 'avainia-core-api';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class CondominiumCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      condominiumPhotoUrl : "/assets/images/condo-placeholder.png"
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.condominiumMainPhotoGet(this.props.id).then((condominiumPhoto) => {
      if (condominiumPhoto.error) { return this.setState({ error: condominiumPhoto.error }); }
      this.setState({
        condominiumPhotoUrl: condominiumPhoto,
      });
    })
  }

  render() {
    return (
      <Box className="CondominiumCard">
        <Link onClick={this.props.handleClick} href={this.props.path}>
          <Box>
            <Image alt={this.props.name} src={this.state.condominiumPhotoUrl}></Image>
          </Box>
          <Box bg="white" px={["4", null, "6", "8"]} py={["4", null, "4", "6"]}>
            <Heading fontSize="md" pb="4">{this.props.name}</Heading>
            {/* <ApartmentDropdown /> // is this really even necessary here? Causes warnings. */}
          </Box>
        </Link>
      </Box>
    );
  }
}

export default CondominiumCard;
