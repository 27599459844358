import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import AvainiaCore from 'avainia-core-api';

// Components
import CurrentApartment from '../../partials/CurrentApartment/CurrentApartment.js';
import ApartmentReview from '../../partials/ApartmentReview/ApartmentReview.js';
import ProjectMaterials from '../../partials/ProjectMaterials/ProjectMaterials.js';


//Chakra
import { GridItem, SimpleGrid, Text } from "@chakra-ui/react";

class ProjectMaterialsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {

  }

  render() {
    return (
      <ProjectMaterials projectId={this.props.projectId} summary />
    );
  }
}

export default withRouter(ProjectMaterialsPage);
