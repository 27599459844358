import React, { Component } from "react";
import I18n from 'i18n-js';

// Chakra
import { Button } from "@chakra-ui/button";

// Components
import ProductModal from "../ProductModal/ProductModal";
import ProductSelectedChip from "../badges/ProductSelectedBadge/ProductSelectedBadge";
import AdditionalProductModal from '../AdditionalProductModal/AdditionalProductModal';

// Styling
import "./AdditionalProductCard.scss";
import { Box, Image, Text } from "@chakra-ui/react";

export default class AdditionalProductCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isSelected: this.props.productIsSelected
    };
  }

  toggleSelect = () => {
    this.setState((prevstate) => ({ isSelected: !prevstate.isSelected }), () => {
      if (this.state.isSelected) {
        // adding
        this.props.productSelectionToggleCallback(this.state.isSelected, this.props.product, true);
      } else {
        // removing
        this.props.productSelectionToggleCallback(this.state.isSelected, this.props.product, false);
      }
    });
    this.handleClick();
  };

  handleClick = () => {
    this.setState((prevstate) => ({
      isOpen: !prevstate.isOpen,
    }));
  };


  render() {
    const {
      id,
      name,
      description,
      price,
      product_photo,
      labor_price
    } = this.props.product;

    const isSelected = this.state.isSelected;
    return (
      <Box key={id} className={`ProductCard ${isSelected ? "selected" : ""}`}>
        {this.state.isOpen &&
          <AdditionalProductModal
            disabled={this.props.disabled}
            handleClick={this.handleClick}
            isOpen={this.state.isOpen}
            isSelected={isSelected}
            product={this.props.product}
            toggleSelect={this.toggleSelect}
            productIsSelected={this.props.productIsSelected}
            onChange={(name, value) => this.props.onChange(name, value)}
            commentCallback={(product, data) => this.props.callback(product, data)}
            selectedFromThisGroup={this.props.selectedProducts}
            productSelection={this.props.productSelection}
            id={this.props.id}
            key={this.props.id}
          />
        }
        <Box className={`selected-chip ${isSelected ? "selected" : ""}`}>
          <ProductSelectedChip contentText={I18n.t('materialforms.selected')} />
        </Box>
        <Box onClick={this.handleClick} className="product-span">
          <Image
            className={`product-image ${isSelected ? "selected" : ""}`}
            src={product_photo ? product_photo : "/assets/images/product_img.png"}
            alt={product_photo}
          />
          <Text className="product-name">{I18n.t('materialforms.product_name')} {name}</Text>
          <Text className="product-description">{I18n.t('materialforms.product_description')} {description}</Text>
          <Text className="compensation-prize"> <strong>{I18n.t('materialforms.additional_price')} {Number(price) + Number(labor_price)} € </strong></Text>

          <Box className={`dropdown-content ${isSelected ? "selected" : ""}`}>
            <Button
              className="product-select-grid-button"
              variant="green-btn"
              onClick={this.props.handleClick}
            >
              {I18n.t('materialforms.view_product')}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
}
