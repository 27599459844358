import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

//Chakras
import { Text } from '@chakra-ui/layout';

class DefaultTab extends Component {
  render() {
    return (
      <>
        <Text>
          VRJ-konserni on valtakunnallinen rakentamisen monitoimialayritys, joka
          on perustettu vuonna 1981 ja työllistää 350 henkilöä.
        </Text>
        <br />
        <Text fontSize="md">
          VRJ Etelä-Suomi Oy on pääasiassa pääkaupunkiseudulla toimiva
          rakentamisen monitoimiosaaja, jonka vahvuudet ovat
          korjausrakentaminen, maa- ja vesirakentaminen, viher- ja
          ympäristörakentaminen, kunnallistekniset työt sekä betoni- ja
          siltarakentaminen.
        </Text>
        <br />
        <Text>
          VRJ Rakennuksen vastuualueelle kuuluvat putkiremontit,
          toimitilasaneeraukset, kunnossapito ja kiinteistöpalvelut.
        </Text>
        <br />
        <Text>
          VRJ Rakennuksella on kokemusta erikokoisista ja erityyppisistä
          linjasaneeraus- eli putkiremonttikohteista yli kymmenen vuoden
          ajalta. Kokemuksemme perusteella uskallamme luvata, että olemme
          luotettava valinta ja taloyhtiönne remontti on hyvissä
          käsissä. Käytössänne on ammattitaitoinen ja motivoitunut henkilökunta,
          vakiintuneet ja osaavat yhteistyökumppanit, sekä monipuoliset
          palvelut.
        </Text>
        <br />
        <Text>
          Olemme laatineet linjasaneerauksille erillisen toimintajärjestelmän,
          joka on jaettu eri osa-alueisiin. Järjestelmä määrittää aikajanan
          avulla eri vaiheisiin kuuluvat tarkistukset ja tehtävät sekä niiden
          vastuuhenkilöt. Järjestelmän apuna toimivat laadun- ja
          työturvallisuuden valvontaohjemat sekä taloushallinta- ja
          aikatauluohjelmistot. Järjestelmän ja ammattitaitoisen henkilökunnan
          avulla varmistamme korkean laadun, aikataulujen pysyvyyden ja
          asiakkaiden tyytyväisyyden.
        </Text>
        <br />
        <Text>
          Toimimme aina kaikissa putkiremonteissa
          pääurakoitsijana. Rakennusosaston organisaatioon kuuluu yli 60
          saneerausrakentamisen ammattilaista ja lisäksi laaja verkosto
          yhteistyökumppaneita.
        </Text>
        <br />
        <Text fontWeight="bold">Aina asiakasta kuunnellen</Text>
        <br />
        <Text>
          Asiakaslähtöisyys on toimintamme tärkein kulmakivi. Kuuntelemme
          osakkaita ja asukkaita, välitämme siitä mitä teemme ja kunnioitamme
          ihmisten koteja. Osakkaiden ja asukkaiden yhteyshenkilönä toimii koko
          projektin ajan helposti tavoitettava sama henkilö.
        </Text>
        <br />
        <Text>
          Taloyhtiön putkiremontti ei koskaan ole meluton, pölytön tai täysin
          vaivaton. Se on yksi suurimmista taloyhtiön remonteista, mutta samalla
          myös oivallinen tilaisuus toteuttaa muita huoneistoon liittyviä
          remontteja. Osaavat ammattilaiset ovat jo valmiiksi paikalla ja meiltä
          saat asiantuntevaa apua sekä remonttiisi liittyvät materiaalit töineen
          henkilökohtisella palvelulla. Moni uusiikin keittiönsä ja asunnon
          pinnat putkiremontin yhteydessä. Remontin jälkeen on mukava palata
          uutuuttaa kiiltelevään kotiin!
        </Text>
      </>
    );
  }
}

export default withRouter(DefaultTab);
