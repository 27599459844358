import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import I18n from 'i18n-js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

//Components
import CondominiumDropdown from '../buttons/CondominiumDropdown/CondominiumDropdown';

//Icons
import { BsPerson, BsArrowRightShort, BsBoxArrowRight } from "react-icons/bs";

//Chakra
import { Box, Center, Flex, Heading, Link, Text } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';
import Icon from '@chakra-ui/icon';

import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react"

class NavigationBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
    };
  }

  logout = () => {
    LocalStorageService.clearUser();
    window.location.assign("/");
    //window.location.reload();
  };

  componentDidMount = () => {
    const user = LocalStorageService.getUser();

    this.setState(() => ({
      user,
    }));

  }

  render() {
    const { name } = this.state.user;
    const params = `${this.props.match.params.id}/${this.props.match.params.projectId}`;
    const condominiumData = LocalStorageService.getCondominium();

    const activeLinkStyle = {
      boxShadow: "0px -3px 0px 0px inset #303030"
    };

    return (
      <Box className="print-hide" height="100%" display={{ base: "none", md: "block" }}>
        <Box p="15" display={{ base: "block", lg: "none" }} mb="4">
          <Image src="/assets/images/company-logo.png" alt="logo" />
        </Box>
        <Flex>
          <Box w="10%" p="15" display={{ base: "none", lg: "block" }}>
            <Image src="/assets/images/company-logo.png" alt="logo" />
          </Box>
          {this.props.match.params.id !== undefined ?
            <>
              <Box w="15%">
                <Box bg="base.yellow" height="100%">
                  <CondominiumDropdown
                    condominium={this.props.match.params.id}
                  />
                </Box>
              </Box>
              <Flex justify="space-evenly" flexGrow="1" bg="white">
                <Link as={NavLink} exact to={`/condominium/${params}`} variant="main-navigation-link" activeStyle={activeLinkStyle} >
                  <Center h="100%">
                    <Text size="sm" fontWeight="bold">{I18n.t('navigation.frontpage')}</Text>
                  </Center>
                </Link>
                { (this.props.userIsOwner || this.props.userIsAdmin) &&
                  <Link as={NavLink} exact to={`/condominium/${params}/materialforms`} variant="main-navigation-link" activeStyle={activeLinkStyle} >
                    <Center h="100%">
                      <Text size="sm">{I18n.t('navigation.materialSelection')}</Text>
                    </Center>
                  </Link>
                }
                <Link as={NavLink} exact to={`/condominium/${params}/notifications`} variant="main-navigation-link" activeStyle={activeLinkStyle} >
                  <Center h="100%">
                    <Text size="sm">{I18n.t('navigation.notifications')}</Text>
                  </Center>
                </Link>
                <Link as={NavLink} exact to={`/condominium/${params}/projectmaterials`} variant="main-navigation-link" activeStyle={activeLinkStyle} >
                  <Center h="100%">
                    <Text size="sm">{I18n.t('navigation.projectMaterials')}</Text>
                  </Center>
                </Link>
                <Link as={NavLink} exact to={`/condominium/${params}/schedules`} variant="main-navigation-link" activeStyle={activeLinkStyle} >
                  <Center h="100%">
                    <Text size="sm">{I18n.t('navigation.schedule')}</Text>
                  </Center>
                </Link>
                <Link as={NavLink} exact to={`/condominium/${params}/company`} variant="main-navigation-link" activeStyle={activeLinkStyle} >
                  <Center h="100%">
                    <Text size="sm">{I18n.t('navigation.company')}</Text>
                  </Center>
                </Link>
                <Link as={NavLink} exact to={`/condominium/${params}/contacts`} variant="main-navigation-link" activeStyle={activeLinkStyle} >
                  <Center h="100%">
                    <Text size="sm">{I18n.t('navigation.contact')}</Text>
                  </Center>
                </Link>
              </Flex>
            </>
            :
            <Flex justify="space-between" flexGrow="1" bg="white">
              <Center h="52.8px" ml="4">
                <Heading>{I18n.t('navigation.condosAndApartments')}</Heading>
              </Center>
            </Flex>
          }

          <Flex>
            <Menu autoSelect={false}>
              <Box
                display={{ base: "none", xl: "block" }}
                flexGrow="1"
                position="relative"
                minW="16"
                h="100%"
                bg="gray.darkGray"
                _before={{
                  content: `""`,
                  position: "absolute",
                  bg: "white",
                  width: "100%",
                  height: "100%",
                  background: "linear-gradient(131deg, #fff 51%, #303030 52%)",
                }}
              />
              <MenuButton
                as={Button}
                variant="user-nav-btn"
              >
                <Flex justify={{ base: "center", xl: "flex-end" }}>
                  <Icon as={BsPerson} w={5} h={5} color="#fff556" />
                  <Center><Text px="2" display={{ base: "none", xl: "block" }}>{name}</Text></Center>
                  <Icon as={BsArrowRightShort} display={{ base: "none", xl: "block" }} w={6} h={6} color="white" />
                </Flex>
              </MenuButton>
              <MenuList variant="test">
                <MenuItem fontSize="sm" fontWeight="bold" onClick={this.logout}>
                  {I18n.t('login.logout')}
                  <Icon as={BsBoxArrowRight} ml="2" w={5} h={5} color="white" />
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Box>
    );
  }
}

export default withRouter(NavigationBar);
