import React, { Component } from 'react';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import { NavLink } from 'react-router-dom';

//Icons
import { BsArrowRightShort } from "react-icons/bs";

// Chakra
import { SimpleGrid, Box, Flex, Link, Spacer, Text, Heading } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { Image } from '@chakra-ui/image';
import { FormControl } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      error: '',
      loading: false,
      password: '',
      token: '',
      user: '',
    }
  }

  accountRequest() {
    window.location.assign("/account-request")
  }

  doLogin = (e) => {
    const api = new AvainiaCore();
    this.setState({ loading: true }, () => {
      api.login(this.state.email, this.state.password, true)
        .then((result) => {

          if (result.error) { return this.setState({ loading: false, error: 8 }); }
          if (result.terms_accepted || !result.terms_accepted) {
            LocalStorageService.setUser(result);
            window.location.reload();
          } else {
            this.setState({ user: result, loading: false });
          }
        })
        .catch((error) => {
          console.error('Error during login', error); // TODO! Fatal error, actual logging
        });
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      return this.doLogin();
    }
  }

  render() {
    return (
      <Box className="Login">
        <Box mb="4" mt="4">
          <Image src="/assets/images/company-logo.png" alt="logo" />
        </Box>
        <SimpleGrid columns={[1, null, 2]} gap={4} rowGap={0}>
          <Box>
            <Box bg="white" p={["4", null, "6", "10", "14"]}>
              <Box>
                <Heading mb="4">{I18n.t('accountrequest.welcoming')}</Heading>
                <Text fontSize="md">
                  {I18n.t('accountrequest.portal_info')}
                  <br /><br />
                  {I18n.t('accountrequest.portal_responsibility')}
                </Text>
              </Box>
            </Box>
            <Box
              display={["none", null, "block"]}
              position="relative"
              _before={{
                content: `""`,
                position: "absolute",
                width: "70px",
                height: "70px",
                bg: "base.yellow",
                bottom: "0px",
                left: "70px"
              }}
              _after={{
                position: "absolute",
                content: `""`,
                left: "-70px",
                height: "140px",
                width: "140px",
                background: "gray.decorationBox",
                bottom: "-140px",
              }}
            >
              <Image src="/assets/images/login-screen.png" alt="placeholder" />
            </Box>
          </Box>
          <Box>
            <Box bg="white" p={["4", null, "6", "10", "14"]}>
              <Heading mb="4">{I18n.t('login.login')}</Heading>
              <FormControl id="login" pb="4">
                <Flex direction={["column", null, null, "row"]}>
                  <Input
                    id="email"
                    variant="login-input"
                    name="email"
                    type="email"
                    autoComplete="email"
                    onChange={this.onChange}
                    onKeyDown={this.onKeyDown}
                    autoFocus
                    placeholder={I18n.t('login.email')}
                    mr={[null, null, "2"]}
                    mb={["2", null, null, "0"]}
                  />
                  <Input
                    id="password"
                    variant="login-input"
                    name="password"
                    type="password"
                    value={this.state.password}
                    placeholder={I18n.t('login.password')}
                    autoComplete="password"
                    onChange={this.onChange}
                    onKeyDown={this.onKeyDown}
                  />
                </Flex>
                { this.state.error &&
                  <Box mt={4}>
                    <Text color="red" fontSize="md">
                    {I18n.t('materialforms.input_error')}
                    </Text>
                  </Box>
                }
              </FormControl>
              <Flex justify="">
                <Button
                  variant="green-btn"
                  rightIcon={<BsArrowRightShort size="26" className="icon" />}
                  onClick={this.doLogin}
                  disabled={this.state.loading}
                >
                  {I18n.t('login.login-button')}
                </Button>
                <Spacer />
                <Link
                  as={NavLink}
                  to={`/forgottenpassword`}
                  variant="default-link"
                  colorScheme="red"
                  placeSelf="center"
                >
                  {I18n.t('login.forgotten-password')}
                </Link>
              </Flex>
            </Box>
            <Button
              variant="request-account-btn"
              rightIcon={<BsArrowRightShort size="32" />}
              marginTop={["0", null, "4"]}
              onClick={this.accountRequest}
            >
              {I18n.t('login.request-user')}
            </Button>
          </Box>
          <Box
            display={["block", null, "none"]}
            position="relative"
            _before={{
              content: `""`,
              position: "absolute",
              width: "50px",
              height: "50px",
              bg: "base.yellow",
              bottom: "0px",
              left: "50px"
            }}
            _after={{
              position: "absolute",
              content: `""`,
              left: "-50px",
              height: "100px",
              width: "100px",
              background: "gray.decorationBox",
              bottom: "-100px",
            }}
          >
            <Image src="/assets/images/login-screen.png" alt="placeholder" />
          </Box>
        </SimpleGrid>
      </Box >
    )
  }
}

export default Login;
