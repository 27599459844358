import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Chakra
import { Box, Text } from '@chakra-ui/react';
import { Button } from '@chakra-ui/button';

class MaterialFormTab extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Button
        py={6}
        flex={1}
        textAlign={'center'}
        borderStyle={'none'}
        borderBottom={this.props.active ? 'none' : 'solid'}
        borderRight={'solid'}
        borderWidth={'1px'}
        borderColor={'gray.inputBorder'}
        borderRadius={0}
        bg={this.props.active ? 'white' : 'gray.lightGray'}
        display={'flex'}
        disabled={this.props.disabled}
        outline={'none'}
        _disabled={{
          bg: 'gray.lightGray',
          cursor: 'not-allowed',
        }}
        _active={{ outline: 'unset' }}
        _focus={{ outline: 'unset' }}
        onClick={() => this.props.tabCallback()}
      >
        <Box
          display={'flex'}
          opacity={this.props.disabled ? 0.25 : 'unset'}
          alignItems={'center'}
          textAlign={'center'}
        >
          <Box
            borderRadius={'50%'}
            bg={'black'}
            mx={4}
            color={'white'}
            height={4}
            width={4}
            p={3}
            lineHeight={0}
            display={'flex'}
            position={'relative'}
          >
            <Text
              fontSize={'sm'}
              position={'absolute'}
              top={'55%'}
              left={'49%'}
              transform={'translate(-50%, -50%)'}
            >
              {this.props.phaseNumber}
            </Text>
          </Box>
          <Text>{this.props.content}</Text>
        </Box>
      </Button>
    );
  }
}

export default withRouter(MaterialFormTab);
