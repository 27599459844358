const textarea = {
  variants: {
    "material-form": {
      h: "48",
      border: "1px solid",
      borderColor: "gray.inputBorder"
    },
  },
}

export default textarea;
