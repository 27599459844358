import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import AvainiaCore from 'avainia-core-api';
import I18n from 'i18n-js';

//Partials
import MaterialSummaryGeneral from '../../partials/MaterialSummaryGeneral/MaterialSummaryGeneral.js';
import MaterialSummaryPicks from '../../partials/MaterialSummaryPicks/MaterialSummaryPicks.js';

//Icons
import Icon from '@chakra-ui/icon';
import { BsArrowBarUp, BsFillCircleFill, BsFileEarmarkText } from 'react-icons/bs';

//Chakras
import { Heading, Box, Text } from '@chakra-ui/layout';
import { Button, Alert, AlertTitle, Grid, List, ListItem, ListIcon, Flex, Spacer, useDisclosure } from "@chakra-ui/react";


class MaterialFormSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount = () => {

}

render() {
  return (
    <Box className="MaterialFormSummary"
         background="linear-gradient(180deg, #ffffff 93.84%, #f2f3f4 6.16%)">
      <Box>
      <Box p={[4, 8, 4, 8]}>
        <Heading className="heading">{I18n.t('materialforms.summary')}</Heading>
        <Button className="submit" variant="green-btn">{I18n.t('materialforms.submit_selections')}<Icon as={BsArrowBarUp}
                display={{ base: "none", xl: "block" }} w={6} h={6} color="white" /></Button>
          <Alert status="error" className="closing-alert">
            <AlertTitle>{I18n.t('materialforms.choice_closes_on')} 1.3.2021</AlertTitle>
          </Alert>

        <Box className="personal-grid">
        <Grid templateRows="repeat(3, 1fr)" templateColumns="repeat(3, 1fr)" gap={0}>
          <Box className="personal-grid-boxes" p={2, 6, 2, 6} borderRadius="10px 0px 0px 10px">
            <Text fontSize="12px">AS OY TAPION-SALPA</Text>
            <Text fontWeight="bold" fontSize="18px">A5</Text>
            <Text fontWeight="bold" fontSize="18px">{I18n.t('materialforms.housing_base')} 2h+k+kph, 52 m² </Text>
            </Box>
          <Box className="personal-grid-boxes" p={2, 6, 2, 6}>
            <Text textTransform={'uppercase'} fontSize="12px">{I18n.t('materialforms.owner')}</Text>
            <Text fontWeight="bold" fontSize="18px">{I18n.t('materialforms.first_last')}</Text>
            <Text fontWeight="bold" fontSize="18px">{I18n.t('materialforms.telephone')}0400 111 111</Text>
            </Box>
          <Box className="personal-grid-boxes" p={2, 6, 2, 6} borderRadius="0px 10px 10px 0px">
            <Text textTransform={'uppercase'} fontSize="12px">{I18n.t('materialforms.tenant')}</Text>
            <Text fontWeight="bold" fontSize="18px">{I18n.t('materialforms.first_last')}</Text>
            <Text fontWeight="bold" fontSize="18px">{I18n.t('materialforms.telephone')}0400 111 111</Text>
          </Box>
        </Grid>
        </Box>

        <List className="list">
          <ListItem color="black">
            <ListIcon as={BsFillCircleFill} />{I18n.t('materialforms.contractual')}</ListItem>
          <ListItem color="red">
            <ListIcon as={BsFillCircleFill} />{I18n.t('materialforms.extra_charge')}</ListItem>
        </List>
        <Button className="print-button">
        <Icon as={BsFileEarmarkText} w={7} h={7} color="black" marginRight="3"/>{I18n.t('materialforms.print.form')}</Button>

        <MaterialSummaryGeneral ans1="Kyllä" ans2="Huoneistossa tehty lattiaremontti 2019." ans3="Kyllä"/>
        <MaterialSummaryPicks />

        <Box className="total">
          <Box className="total-outer" p={4, 8, 4, 8}>
            <Box className="total-inner" p={2, 4, 2, 4} paddingBottom="0px">
              <Flex>
              <Heading paddingTop="10px" paddingLeft="25px">{I18n.t('materialforms.total')}</Heading>
              <Spacer />
              <Text fontWeight="bold" paddingTop="10px" paddingRight="140px">85 €</Text>
              </Flex>
            </Box>
            <Text fontWeight="bold">{I18n.t('materialforms.calculations_disclaimer')}</Text>
          </Box>
        </Box>

        <Box mt="-115px">
        <Button className="submit" variant="green-btn">{I18n.t('materialforms.submit_selections')}<Icon as={BsArrowBarUp} display={{ base: "none", xl: "block" }} w={6} h={6} color="white" /></Button>
          <Alert status="error" className="closing-alert">
            <AlertTitle>{I18n.t('materialforms.choice_closes_on')} 1.3.2021</AlertTitle>
          </Alert>
        </Box>
      </Box>
      </Box>
      </Box>
      )
    }
  }


export default withRouter(MaterialFormSummary);