const colors = {
  bgColor: "#F2F2F2",
  base: {
    yellow: "#fff556",
    darkYellow: '#CB9400',
    bgDark: "#2E2E2E",
  },
  text: {
    dark: "#3B3B3B",
  },
  green: {
    light: "#45A455",
    dark: "#288538",
  },
  gray: {
    darkGray: "#303030",
    lighter: "#494949",
    lightest: "#C8C8C8",
    lightGray: "#EAEAEA", // These don't make much sense
    darkerLightGray: "#CECECE",
    inputBorder: "#707070",
    decorationBox: "#373737",

    divider: "#C8C8C8",
  },
  blue: {
    link: "#255274",
  },
  red: {
    basic: "#E53E3E",
  }
}

export default colors;
