import React, { Component } from 'react';

import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import AvainiaCore from 'avainia-core-api';

//Chakras
import { Heading, Box, Link, Flex, Center, SimpleGrid } from '@chakra-ui/layout';
import { Button, Menu, MenuButton } from "@chakra-ui/react";
import { Image } from '@chakra-ui/image';
import Icon from '@chakra-ui/icon';

//Partials
import CondominiumCard from '../../partials/CondominiumCard/CondominiumCard.js';

//Icons
import { BsPerson, BsArrowRightShort } from "react-icons/bs";

class Condominium extends Component {
  constructor(props) {
    super(props);

    this.state = {
      condominium: {},
      loading: false,
      projects: {},
    }
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    const {id} = this.props;

    api.condominiumGet(id).then((condominium) => {
      if (condominium.error) { return this.setState({ error: condominium.error }); }
      api.condominiumApartmentsGet(condominium.id).then((apartments) => {
        if (apartments.error) { return this.setState({ error: apartments.error }); }
        LocalStorageService.setApartment(apartments[0]);

        api.condominiumProjectsGet(id).then((condominiumProjects) => {
          if (condominiumProjects.error) { return this.setState({ error: condominiumProjects.error }); }
          if (condominiumProjects.length === 1) {
            LocalStorageService.setProject(condominiumProjects[0].project);
            this.props.history.push(`/condominium/${condominium.id}/${condominiumProjects[0].project_id}`);
          }

          this.setState({
            condominium,
            condominiumProjects,
            loading: false,
          });
        });
      });
    });
  }

  logout = () => {
    LocalStorageService.clearUser();
    window.location.assign("/");
  };

  render() {
    const {condominium, condominiumProjects} = this.state;

    return (
      <>

    <Box className="Condominium" p={4, 8, 4, 8}>

      <Flex>
      <Box p={1, 2, 1, 2}>
        <Image src="/assets/images/company-logo.png" alt="logo" />
      </Box>
        <Box display={{ base: "none", xl: "block" }}
             flexGrow="1"
             position="relative"
             minW="16"
             background="linear-gradient(135deg, #ffffff 80%, #2e2e2e 20%)">
               <Heading className="heading">{condominium.name} <br/> {condominium.street_address}, {condominium.postcode} {condominium.post_office}</Heading>
               <Menu>
               <Flex className="flex-menu" justify={{ base: "center", xl: "flex-end" }}>
                 <Icon as={BsPerson} w={5} h={5} color="#fff556" />
        <Center><MenuButton as={Button} variant="user-nav-btn" onClick={this.logout}> Etunimi Sukunimi </MenuButton></Center>
                <Icon as={BsArrowRightShort} display={{ base: "none", xl: "block" }} w={6} h={6} color="white" />
               </Flex>
               </Menu>
             </Box>
      </Flex>
    </Box>

      {Object.keys(condominium).length !== 0 &&
        <>
          <SimpleGrid position="absolute" left="163pt" columns={[2, null, 2, 3, 4]} gap={5}>
              {condominiumProjects && condominiumProjects.map((project) => {
                if (project.project) {
                  return (
                  <Box>
                    <Image src="/assets/images/350x250.png" alt="placeholder" paddingBottom="30pt" bg="white" />
                    <Link position="absolute" top="184pt"  pl="16pt" pr="28pt" py="10pt" onClick={() => LocalStorageService.setProject(project.project)}
                          href={`/condominium/${condominium.id}/${project.project.id}`} key={project.project.id} >
                      <strong>{project.project.name}</strong>
                    </Link>
                    </Box>
                  )
                } return null;
              })}
              </SimpleGrid>
        </>
      }
      </>
    )
  }
}

export default Condominium;
