import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import LocalStorageService from './AvainiaTools/LocalStorageService.js';

//Pages
import Frontpage from './components/layouts/Frontpage/Frontpage.js';
import Condominium from './components/layouts/Condominium/Condominium.js';
import CondominiumChoice from './components/layouts/CondominiumChoice/CondominiumChoice.js';
import User from './components/layouts/User/User.js';
import Login from './components/layouts/Login/Login.js';
import AccountRequest from './components/layouts/AccountRequest/AccountRequest.js';
import ProjectMaterialsPage from './components/layouts/ProjectMaterialsPage/ProjectMaterialsPage';
import Contacts from './components/layouts/Contacts/Contacts.js';
import CompanyPage from './components/layouts/CompanyPage/CompanyPage.js';
import NotificationsPage from './components/layouts/NotificationsPage/NotificationsPage.js';
import MaterialFormPage from './components/layouts/MaterialFormPage/MaterialFormPage.js';
import FormElements from './components/layouts/FormElements/FormElements.js';
import MaterialFormSummary from './components/layouts/MaterialFormSummary/MaterialFormSummary.js';
import Utils from './AvainiaTools/Utils.js';
import AvainiaCore from 'avainia-core-api';
import SchedulesPage from './components/layouts/SchedulesPage/SchedulesPage.js';
import AvainiaWrapper from './components/partials/AvainiaWrapper/AvainiaWrapper.js';
import MaterialFormOffer from './components/layouts/MaterialFormOffer/MaterialFormOffer.js'
import ForgottenPassword from './components/layouts/ForgottenPassword/ForgottenPassword.js'

//Chakra
import { ChakraProvider, Container } from "@chakra-ui/react";

import theme from './config/theme/theme';

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      configuration: false,
      sessionToast: false,
      timeout: false,
      loginCheckInterval: false,
      interval: null,
      pageTitle: '',
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    const loginCheckInterval = this.createLoginChecker();
    window.Avainia.loginCheckInterval = loginCheckInterval;

    this.setState({ loginCheckInterval }, () => {
      Utils.Globals.getGlobals().then(() => {
        // Ensure that the configuration exists and works
        Utils.Configuration.getConfiguration().then((configuration) => {
          if (!configuration || configuration.error) { return this.setState({ loading: false, error: 1000 }); }
          this.setState({
            configuration,
            loading: false,
            pageTitle: document.title,
          });
        });
      });
    });
  }

  createLoginChecker = () => {
    return window.setInterval(() => {
      if (window.triggerLoginCheck) {
        window.clearInterval(this.state.loginCheckInterval);

        const localToken = LocalStorageService.getToken();
        const api = new AvainiaCore(localToken);
        api.loginCheck().then((result) => {
          if (result.error) {
            this.handleSessionTimeout(localToken);
          } else {
            // TODO: Replace with real log
            console.error('This message should not be visible ever. Check why this is visible');
          }
        });
      }
    }, 1000);
  }

  loginCallback = () => {
    this.setState({
      timeout: false,
      sessionToast: false,
    }, this.stopBlink);
  }

  handleSessionTimeout = (token) => {
    const localToken = Utils.LocalStorage.getToken();
    if (token === localToken) {
      Utils.LocalStorage.clearUser();
      this.setState({ timeout: true }, this.stopBlink);
    }
  }


  blinkTitle = () => {
    const { pageTitle } = this.state;
    const attentionMsg = `\u26A0 ${pageTitle}`;

    if (document.title === attentionMsg) {
      document.title = pageTitle;
    } else {
      document.title = attentionMsg;
    }
  }

  stopBlink = () => {
    clearInterval(this.state.interval);
    document.title = this.state.pageTitle;
  }
  logout = () => {
    LocalStorageService.clearUser();
    window.location.assign("/");
  };

  render() {
    const user = LocalStorageService.getUser();
    const apartment = LocalStorageService.getApartment();
    const userIsOwner = user && user.roles.filter((role) => role.display_name === 'apartmentOwner').length > 0;
    const userIsAdmin = user && user.roles.filter((role) => role.name === 'owner').length > 0;

    if (!user) {
      return (
        <ChakraProvider theme={theme}>
          <Container maxW="100rem" px={[0, "1rem"]} className="Container">
            <Switch>
              <Route exact path="/account-request" render={() => <AccountRequest />} />
              <Route exact path="/forgottenpassword" render={() => <ForgottenPassword />} />
              <Route render={() => <Login />} />
            </Switch>
          </Container>
        </ChakraProvider>
      );
    }

    return (
      <ChakraProvider theme={theme}>
        <div className="VRJ">
          <Switch>
            <Route exact path="/" render={(props) =>
              <AvainiaWrapper hideSidebar containerVariant="mobile"><CondominiumChoice {...props} /></ AvainiaWrapper>
            } />
            <Route exact path="/condominium/:id" render={(props) =>
              <AvainiaWrapper hideNav hideSidebar><Condominium {...props} id={props.match.params.id} /></ AvainiaWrapper>
            } />
            {
              apartment &&
                <Route exact path="/condominium/:id/:projectId" render={(props) =>
                  <AvainiaWrapper><Frontpage apartment={apartment} projectId={props.match.params.projectId} /> </ AvainiaWrapper>
              } />
            }
            <Route exact path="/condominium/:id/:projectId/schedules" render={(props) =>
              <AvainiaWrapper> <SchedulesPage projectId={props.match.params.projectId} /> </ AvainiaWrapper>
            } />
            <Route exact path="/condominium/:id/:projectId/notifications" render={(props) =>
              <AvainiaWrapper><NotificationsPage projectId={props.match.params.projectId} /></ AvainiaWrapper>
            } />
            <Route exact path="/condominium/:id/:projectId/company" render={(props) =>
              <AvainiaWrapper><CompanyPage projectId={props.match.params.projectId} /></ AvainiaWrapper>
            } />
            <Route exact path="/user" render={() =>
              <AvainiaWrapper><User /></ AvainiaWrapper>
            } />
            <Route exact path="/condominium/:id/:projectId/contacts" render={(props) =>
              <AvainiaWrapper><Contacts projectId={props.match.params.projectId} /></ AvainiaWrapper>
            } />
            <Route path="/condominium/:id/:projectId/projectmaterials" render={(props) =>
              <AvainiaWrapper><ProjectMaterialsPage {...props} id={props.match.params.id} projectId={props.match.params.projectId} /></ AvainiaWrapper>
            } />
            { (userIsOwner || userIsAdmin) && <Route path="/condominium/:id/:projectId/materialforms" render={(props) =>
                <AvainiaWrapper hideSidebar><MaterialFormPage {...props} /> </AvainiaWrapper>
              } />
            }
            <Route path="/form-elements-test" render={(props) =>
              <AvainiaWrapper><FormElements /></AvainiaWrapper>
            } />
            <Route path="/material-form-summary" render={(props) =>
              <AvainiaWrapper><MaterialFormSummary /></AvainiaWrapper>
            } />
            <Route path="/offer" render={(props) =>
              <AvainiaWrapper><MaterialFormOffer /></AvainiaWrapper>
            } />
            <Route render={() => <Redirect to='/' />} />
          </Switch>
        </div>
      </ChakraProvider>
    )
  }
}
export default withRouter(App);
