import React, { Component } from 'react';

//Chakra
import { Box, Divider, Link, Heading, Text, Flex } from '@chakra-ui/layout';

import I18n from "i18n-js";
import { NavLink } from 'react-router-dom';

function sortSchedules(a, b) {
  const date = new Date();
  const aDate = Math.abs(date < new Date(a.start_at)) ? new Date(a.start_at) : new Date(a.end_at),
    bDate = Math.abs(date < new Date(b.start_at)) ? new Date(b.start_at) : new Date(b.end_at);

  const sameDatesA = a.start_at === a.end_at ? true : false,
    sameDatesB = b.start_at === b.end_at ? true : false;

  const aString = Math.abs(aDate - new Date(a.start_at)) === 0 ? I18n.t("schedules.is-opening") : I18n.t("schedules.is-closing");
  const bString = Math.abs(bDate - new Date(b.start_at)) === 0 ? I18n.t("schedules.is-opening") : I18n.t("schedules.is-closing");

  a['display_string'] = sameDatesA ? "" : aString;
  b['display_string'] = sameDatesB ? "" : bString;

  a['display_date'] = aDate.toLocaleDateString("fi-FI");
  b['display_date'] = bDate.toLocaleDateString("fi-FI");

  return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
}

class UpcomingSchedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schedules: this.props.schedules,
    };
  }

  componentDidMount = () => {

  }

  render() {
    const { condoId, aptId } = this.props;
    const date = new Date();

    return (
      <Box bg="base.bgDark" p={[4, 8, 4, 8]} mb="4">
        <Heading color="white" mb="4">{I18n.t('schedules.upcoming_schedules')}</Heading>
        {this.state.schedules && this.state.schedules.sort(sortSchedules).slice(0, 5).map(schedule => {
          if(date > new Date(schedule.end_at)){
            return false;
          }

          return (
            <Flex mb="4">
              <Text color="white" w="20" mr="0.5rem">{schedule.display_date}</Text>
              <Text color="white">{schedule.description} {schedule.display_string}</Text>
            </Flex>
          )
        })}
        <Divider mt="6" mb="2" opacity="1" borderColor="white" />
        <Link as={NavLink} to={`/condominium/${condoId}/${aptId}/schedules`} fontSize="sm" color="white"> {I18n.t("schedules.all-schedules")}</Link>
      </Box>
    )
  }
}

export default UpcomingSchedule;
