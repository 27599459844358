import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import I18n from 'i18n-js';

// Chakra
import { Box, Divider, Heading } from '@chakra-ui/layout';
import ContactCards from '../../partials/ContactCards/ContactCards.js';

class Contacts extends Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  render() {
    return (
      <Box bg="white" p={[4, 8, 4, 8]}>
        <Heading mb={{ base: "4", md: "0" }}>{I18n.t('contacts.contactPersons')}</Heading>
        <Divider display={{ base: "none", md: "block" }} />
        <ContactCards projectId={this.props.projectId} />
      </Box >
    )
  }
}

export default withRouter(Contacts);
