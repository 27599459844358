import React, { Component } from 'react';
import I18n from 'i18n-js';

//Icons
import { BsChevronDown } from "react-icons/bs";
import AvainiaCore from 'avainia-core-api';
import LocalStorageService from '../../../../AvainiaTools/LocalStorageService.js';


class ApartmentDropdown extends Component {

  constructor(props) {
    super(props);
    this.state = {
      apartments: null,
      currentApartment: null,
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.condominiumApartmentsGet(this.props.condominium, 'apartments').then((data) => {
      if (data.error) { return this.setState({ error: data.error }); }
      this.setState({
        apartments: data.apartments
      }, () => this.getCurrentApartment());
    })
  }

  getCurrentApartment = () => {
    if (this.state.apartments) {
      if (LocalStorageService.getApartment() !== null) {
        let currentApartment = this.state.apartments.find((x) => x.id === LocalStorageService.getApartment().id);
        if (!currentApartment) {
          LocalStorageService.setApartment(this.state.apartments[0]);
          currentApartment = this.state.apartments[0];
        }
        this.setState({ currentApartment });
      } else {
        LocalStorageService.setApartment(this.state.apartments[0]);
      }
    }
  }

  selectApartment = (id) => {
    LocalStorageService.setApartment(this.state.apartments.find((x) => x.id === id));
    this.getCurrentApartment();
    window.location.reload();
  }

  render() {
    const currentApartment = this.state.currentApartment ? this.state.currentApartment.stairwell+this.state.currentApartment.apartment_number : I18n.t('general.no_apartment');

    return (
      <>
      {this.state.apartments && <div className="ApartmentDropdown">
        <button className="button">
          <div className="button-content">
            <label>{currentApartment}</label>
            <BsChevronDown className="icon" />
          </div>
        </button>

        <div className="dropdown-content">
          {this.state.apartments.map((apartment) => {
            return <a href="#" key={apartment.id} onClick={() => {this.selectApartment(apartment.id)}}><label>{apartment.stairwell}{apartment.apartment_number}</label></a>
          })}
        </div>
      </div>}
      </>
    );
  }
}

export default ApartmentDropdown;
