import React, { Component } from 'react';
import moment from 'moment';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import I18n from 'i18n-js';

//Chakra
import { Box, Divider, Flex, Heading, Link, Text } from '@chakra-ui/layout';
import { NavLink } from 'react-router-dom';

function compare(a, b) {
  if (a.created_at > b.created_at) {
    return -1;
  }
  if (a.created_at < b.created_at) {
    return 1;
  }
  return 0;
}

class NotificationBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  downloadAttachment = async (notification) => {
    try {
      const obj = { headers: { Authorization: `Bearer ${LocalStorageService.getToken()}` } };
      const res = await fetch(process.env.REACT_APP_API_HOST + "/api/v1/notifications/" + notification.id + "/download", obj);
      const fileName = notification.attachment.name;

      if (res.ok) {
        const binary = await res.blob();

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(binary, fileName);
        } else {
          const src = window.URL.createObjectURL(binary);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style.cssText = 'display: none';
          a.href = src;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(src);
          setTimeout((x) => { document.body.removeChild(a); }, 1000);
        }
      } else if (res.status !== 410) { // 410 means dont retry
        throw new Error('failed to fetch document'); // TODO! Fatal error, do actual logging
      }
    } catch (ex) {
      console.error(ex); // TODO! Fatal error, do actual logging
    }
  }

  render() {
    const { notifications, condoId, aptId, deadlines, quoteDeadlines, roomCardDeadlines, projectId } = this.props;
    const user = LocalStorageService.getUser();
    //const userIsOwner = user.roles.filter((role) => role.display_name === 'apartmentOwner').length > 0;
    const userIsOwner = 1;

    return (
      <Box bg="white" p={[4, 8, 4, 8]} mb="4">
        <Heading mb="4">{I18n.t('notifications.latest_notifications')}</Heading>
        {userIsOwner && quoteDeadlines.map((deadline) => {
          const dateTime = deadline.split(" ");
          const date = dateTime[0];
          const time = dateTime[1];
          let receivedOffer = '.';
            if (date && date !== '0000-00-00') { // TODO check if this actually works
              const offerDlDate = moment(date).format("DD.MM.YYYY");
              const offerDlTime = moment(deadline).format("HH:mm");
              receivedOffer = (
                <>
                  {I18n.t('notifications.offer_deadline', {date: offerDlDate, time: offerDlTime})}
                </>
              );
            }

          return (
            <Box bg="base.yellow" p="4" mb="4">
              <Text fontWeight="bold">{I18n.t('notifications.received_offer')}{receivedOffer}</Text>
              <Link as={NavLink} to={`/condominium/${condoId}/${projectId}/materialforms`} variant="default-link"> {I18n.t('notifications.view_and_fill')}</Link>
            </Box>
          )
        })}
        {userIsOwner && deadlines.map((deadline) => {
          const dateTime = deadline.split(" ");
          const date = dateTime[0];
          const time = dateTime[1];
          const formDlDate = date;
          const formDlTime = time;

          return (
            <Box bg="base.yellow" p="4" mb="4">
              <Text fontWeight="bold">{I18n.t('notifications.materialform_deadline')}</Text>
              <Link as={NavLink} to={`/condominium/${condoId}/${projectId}/materialforms`} variant="default-link"> {I18n.t('notifications.view_and_fill')}</Link>
            </Box>
          )
        })}
        {userIsOwner && roomCardDeadlines.map((deadline) => {
          const dateTime = deadline.split(" ");
          const date = dateTime[0];
          const time = dateTime[1];
          const formDlDate = date;
          const formDlTime = time;

          return (
            <Box bg="base.yellow" p="4" mb="4">
              <Text fontWeight="bold">{I18n.t('notifications.roomcard_deadline')}</Text>
              <Link as={NavLink} to={`/condominium/${condoId}/${projectId}/materialforms`} variant="default-link"> {I18n.t('notifications.view_and_fill')}</Link>
            </Box>
          )
        })}
        <Box>
          {notifications.sort(compare).slice(0, 3).map(notification => {
            return (
              <Box mb="4">
                <Flex>
                  <Text><strong>{notification.title}</strong><br />{notification.body.substring(0,100)}{notification.body.length > 100 && '...'}</Text>
                </Flex>
                {notification.attachment &&
                  <Link variant="default-link" my={1} onClick={() => this.downloadAttachment(notification)} >{notification.attachment.name}</Link>
                }
              </Box>
            )
          })}
        </Box>
        <Divider />
        <Box className="to-all-releases">
          <Link as={NavLink} to={`/condominium/${condoId}/${projectId}/notifications`} variant="default-link"> {I18n.t('notifications.all_notifications')}</Link>
        </Box>
      </Box>
    )
  }
}

export default NotificationBoard;
