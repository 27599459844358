import React, { Component } from 'react';
import FormHeader from '../FormHeader/FormHeader.js';

class MaterialFormInstructionsSelfDelivered extends Component {

    render() {
        return (
            <div>
                <FormHeader text="YLEISTÄ ASIAA LAATOITUKSESTA JA MUISTA ITSE TOIMITETUISTA TUOTTEISTA"/>
                <p>Osakas vastaa toimittamiensa laattojen sopivuudesta, laadusta, laattojen riittävyydestä ja
                toimituksesta asuntoon. Toimitusaika ja paikka asunnossa on sovittava VRJ:n työnjohdon kanssa.
                Laattojen vaatimasta hukkaprosentista saat lisätietoa laattojen toimittajalta.</p>
                <br></br>
                <p>Itse hankittujen laattojen tulee olla laadultaan urakan laatutasoa vastaava. Laatat eivät saa olla
                paperiarkissa (voi heikentää laattojen tartuntaa). Jos osakas toimittaa itse laatat, osakkaan on
                ilmoitettava alle niiden toimittaja, malli, mitat ja materiaali viimeistään huoneistokatselmuksella.
                Jos laatoilla on erityisen pitkä toimitusaika, tulee ns. hukkavaraa olla normaalia enemmän, jotta
                laatat varmasti riittävät. Työmaata ei voida pysäyttää odottamaan laattatoimitusta. Erikoisilla
                laatoilla voi olla jopa useiden kuukausien toimitusaika.</p>
                <br></br>
                <p>Erikoislaatoitusten osalta osakkaan velvollisuus on toimittaa mitoitettu suunnitelmapiirros
                urakoitsijalle, jos laatoitustapa poikkeaa urakasta. Myös laattajako tulee olla huomioituna, jos siitä
                halutaan esittää erityisiä toiveita.</p>
                <br></br>
                <p>Erikoislaattojen asennusaika ja vaativuus saattavat poiketa urakkaan kuuluvasta työstä ja siksi olla
                lisähintaista. Esim. paksujen kuivapuristettujen laattojen leikkaus on tehtävä timanttisahalla ja
                niihin reikien tekeminen vaatii usein erikoisteräkalustoa. Asennushintoihin voi myös vaikuttaa
                hitaampi saumaus, irtolaattojen asettelu, suurien laattojen pohjatyö, jne.</p>
                <br></br>
                <p>Kun haluatte poiketa kylpyhuoneeseen suunnitelluista urakan mukaisista varusteista tai muuttaa
                suunniteltua kalustejärjestystä, on suunnitteluvastuu osakkaalla. Kalusteiden väleihin on syytä
                jättää tilaa lvis-asennuksia varten. Myös seinien mitat saattavat hiukan muuttua tasoituksien ja
                uudelleen pinnoituksien vuoksi.</p>
                <br></br>
                <p>Urakkaohjelman ulkopuolisten LVIS-kalusteiden tulee olla laatutasoltaan vähintään urakanmukaisia
                kalusteita vastaavia sekä taloyhtiön ja suunnittelijoiden hyväksymiä.</p>
                <br></br>
                <p>Osakkaan vastuulla on pyytää itse hankkimiensa vesikalusteiden (hanat, wc-istuin)
                tyyppihyväksyntätodistukset ja varmistaa, että hanojen ääniluokka on 1. Keittiön hanan
                kääntösäteen tulee olla rajoitettavissa altaan/altaiden päälle. Osakkaan itse hankkimien
                vesikalusteiden huoltovastuu jää tuotteen valmistajasta riippumatta osakkaalle.</p>
                <br></br>
                <p>Suihkupään ja lähimmän pistorasian väliin tulee jäädä etäisyyttä 120 cm. Kylpyhuoneen
                sähkölaitteiden suojaluokitusten tulee olla tilaan sopivia.</p>
                <br></br>
                <br></br>
            </div>
        )
    }

}

export default MaterialFormInstructionsSelfDelivered;
