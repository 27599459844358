import React, { Component } from 'react';

//Icons
import { BsChevronDown } from "react-icons/bs";
import AvainiaCore from 'avainia-core-api';
import LocalStorageService from '../../../../AvainiaTools/LocalStorageService.js';

class CondominiumDropdown extends Component {

  constructor(props) {
    super(props);
    this.state = {
      condominiums: null
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.condominiumsGet().then((data) => {
      if (data.error) { return this.setState({ error: data.error }); }
      this.setState({
        condominiums: data
      });
    })
  }

  getCurrentCondominium = () => {
    if(this.state.condominiums){
      const currentCondominium = this.state.condominiums.find((x) => x.id == this.props.condominium);
      return currentCondominium ? currentCondominium.name : null;
    }
  }

  chooseCondo = (condominium) => {
    LocalStorageService.setCondominium(condominium);
  }

  render() {

    return (
      <>
      {this.state.condominiums && <div className="CondominiumDropdown">
        <button className="button">
          <div className="button-content">
            <label>{this.getCurrentCondominium()}</label>
            <BsChevronDown className="icon" />
          </div>
        </button>

        <div className="dropdown-content">
          {this.state.condominiums.map((condominium) => {
            return <a key={condominium.id} onClick={() => {this.chooseCondo(condominium)}} href={`/condominium/${condominium.id}`}><label>{condominium.name}</label></a>
          })}
        </div>
      </div>}
      </>
    );
  }
}

export default CondominiumDropdown;
