import AvainiaCore from 'avainia-core-api';
import LocalStorageService from './LocalStorageService';

class ScheduleService {
  static getSchedules = async (id) => {
    return new Promise((resolve, reject) => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      const promises = [
        api.projectSchedulesGet(id, 'schedules'),
      ];

      Promise.all(promises).then((data) => {
        let error = false; let
          schedules;

        data.forEach((x) => {
          if (error) { return; }
          if (x.error) { error = x.error; return; }
          if (x.schedules) { schedules = x.schedules; }
        });

        if (error) { reject(error); }

        const response = {
          sorted: this.sortSchedules(schedules),
          unsorted: schedules,
        };
        resolve(response);
      });
    });
  }

  static sortSchedules(schedules) {
    let sorted = [];

    schedules.forEach((schedule) => {
      if (!schedule.parent_id) {
        sorted.push(schedule);
      } else if (this.scheduleParentIsMissing(schedule.parent_id, schedules)) {
        schedule.parent_id = 0;
        sorted.push(schedule);
      }
    });

    sorted = this.sortSchedulesChildren(schedules, sorted, 1);
    return sorted;
  }

  static sortSchedulesChildren(schedules, x, lvl) {
    x.forEach((parent) => {
      parent.children = [];
      schedules.forEach((child) => {
        if (parent.id === child.parent_id) {
          child.lvl = lvl;
          parent.children.push(child);
        }
        if (parent.children.length > 0) {
          this.sortSchedulesChildren(schedules, parent.children, (lvl + 1));
        }
      });
    });

    return x;
  }

  static scheduleParentIsMissing(parent_id, schedules) {
    let missing = true;
    schedules.forEach((schedule) => {
      if (schedule.id === parent_id) {
        missing = false;
      }
    });

    if (missing) {
      console.log(`${parent_id} - Schedule missing parent!`);
    }

    return missing;
  }
}

export default ScheduleService;
