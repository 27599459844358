const heading = {
  baseStyle: {
    fontWeight: "600",
    textTransform: "uppercase",
  },
  sizes: {

  },
  defaultProps: {
    size: "sm"
  },
}

export default heading;
