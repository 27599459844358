import React, { Component } from "react";

// Chakra
import { Box, Text } from "@chakra-ui/react";

export default class ProductSelectBadge extends Component {
  render() {
    return (
      <Box
        bg="black"
        borderRadius="20px"
        color="base.yellow"
        maxW="fit-content"
        mr="1.5rem"
        p="0.5rem 1.5rem 0.5rem 1.5rem"
        position="absolute"
        right="0"
        top="-1rem"
      >
        <Text
          fontSize="14px"
          fontStyle="italic"
          fontWeight="bold"
        >
          {this.props.contentText}
        </Text>
      </Box>
    );
  }
}
