import React, { Component } from 'react';

import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import AvainiaCore from 'avainia-core-api';
import I18n from 'i18n-js';

// Chakra
import { Box } from '@chakra-ui/layout';

class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
    }
  }

  componentDidMount = () => {
    const user = LocalStorageService.getUser();
    const api = new AvainiaCore(LocalStorageService.getToken);

    if (user) {
      // Haetaan käyttäjän projektit
      api.projectsGet().then((projects) => {
        if (projects.error) { return this.setState({ error: projects.error }); }

        this.setState((prevState) => ({
          user,
          projects,
          loading: false,
        }));
      });
    }
  }

  render() {
    const { name, email } = this.state.user;
    const { projects } = this.state;

    return (
      <>
        <Box>{I18n.t('general.welcome')} {name} : {email} </Box>
        <Box>
        {I18n.t('general.dev_project_list')} 
          <Box my="4">
            {projects && projects.map(project => {
              if (project.type === 'condominium') {
                return (
                  <Box key={project.id}>
                    <Box ml="4">{project.name}</Box>
                  </Box>
                )
              } else return null;
            })
            }
          </Box>
        </Box>
      </>
    )
  }
}

export default User;
