/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import AvainiaCore from 'avainia-core-api';

// Components
import NotificationBoard from '../../partials/NotificationBoard/NotificationBoard.js';
import UpcomingSchedule from '../../partials/UpcomingSchedule/UpcomingSchedule.js';
import ContactCard from '../../partials/ContactCard/ContactCard.js';
import ProjectMaterialsLift from '../../partials/ProjectMaterialsLift/ProjectMaterialsLift.js';
import SecureImage from '../../partials/SecureImage/SecureImage.js';

// Chakra
import { Box, Divider, GridItem, Link, SimpleGrid } from "@chakra-ui/react";

class Frontpage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
      scheduleData: [],
      condominium: {},
      apartment: {},
      loading: true,
    };
  }

  componentDidMount = () => {
    const condominiumId = this.props.match.params.id;
    const apartment = LocalStorageService.getApartment();
    const apartmentId = apartment.id;
    const projectId = this.props.match.params.projectId;
    const apartmentTemplateId = this.props.apartment.apartment_template_id;

    const api = new AvainiaCore(LocalStorageService.getToken);

    const promises = [
      api.projectContactsGet(projectId, 'contacts'),
      api.projectSchedulesGet(projectId, 'schedules'),

      api.condominiumGet(condominiumId, 'condominium'),
      api.condominiumApartmentGet(condominiumId, apartmentId, 'apartment'),
      api.condominiumNotificationsGet(condominiumId, 'condoNotification'),
      api.apartmentNotificationsGet(apartmentId, 'aptNotification'),
      api.condominiumApartmentTemplatesGet(condominiumId, 'apartmentTemplates'),
      api.condominiumApartmentTemplateMaterialformsWithSubmissionFormsGet(apartmentTemplateId, apartmentId, 'activeMaterialForms'),
      api.getOpenQuotesByApartment(apartmentId, 'openQuotes'),
    ];

    Promise.all(promises).then((data) => {
      let error = false;
      let contacts;
      let schedules;
      let condominium;
      let apartment;
      let condoNotification;
      let aptNotification;
      let openQuotes;
      let activeMaterialForms;

      data.forEach((x) => {
        if (error) { return; }
        if (x.contacts) { contacts = x.contacts; }
        if (x.schedules) { schedules = x.schedules; }
        if (x.condominium) { condominium = x.condominium; }
        if (x.apartment) { apartment = x.apartment; }
        if (x.condoNotification) { condoNotification = x.condoNotification; }
        if (x.aptNotification) { aptNotification = x.aptNotification; }
        if (x.openQuotes) { openQuotes = x.openQuotes; }
        if (x.activeMaterialForms) { activeMaterialForms = x.activeMaterialForms; }
      });

      if (error) { return this.setState({ loading: false, error }); }

      const condoArray = [];
      condoNotification.map((notification) => {
        condoArray.push(notification);
      });

      const aptArray = [];
      aptNotification.map((notification) => {
        aptArray.push(notification)
      });

      let deadlines = [];
      apartment.deadlines.forEach((a) => {
        // Each form should ever have only one submission.
        let form = null;

        activeMaterialForms.forEach((f) => {
          if(a.material_form_id == f.id){
            form = f;
          }
        })

        if(form){
          const submission = form && form.material_form_submissions && form.material_form_submissions[0];

          if (submission.status !== 'sent' && a.deadline != null) {
            deadlines.push(a.deadline)
          }
        }
      })

      let quoteDeadlines = [];
      openQuotes && openQuotes.forEach((quote) => {
        if (quote.deadline != null && quote.status === 'sent') {
          quoteDeadlines.push(quote.deadline)
        }
      })

      let roomCardDeadlines = [];
      openQuotes && openQuotes.forEach((quote) => {
        if (quote.room_card_approval_deadline != null && quote.room_card_approval_deadline != '0000-00-00 00:00:00' && !quote.room_card_approved) {
          roomCardDeadlines.push(quote.room_card_approval_deadline)
        }
      })

      this.setState({
        primaryContacts: contacts && contacts.filter((contact) => contact.is_primary),
        loading: false,
        scheduleData: schedules,
        condominium: condominium,
        apartment: apartment,
        notifications: condoArray.concat(aptArray),
        deadlines,
        quoteDeadlines,
        activeMaterialForms,
        roomCardDeadlines,
      })
    })
  }

  render() {
    const { primaryContacts, notifications, condominium, apartment, scheduleData, deadlines, quoteDeadlines, pictures, roomCardDeadlines } = this.state;
    const params = `${this.props.match.params.id}/${this.props.match.params.projectId}`;

    return (!this.state.loading &&
      <>
        <SimpleGrid columns={ 6 } gap={ 4 }>
          <GridItem colSpan={ [6, null, null, null, 4] }>
            <NotificationBoard quoteDeadlines={ quoteDeadlines } deadlines={ deadlines } roomCardDeadlines={roomCardDeadlines}  notifications={ notifications } condoId={ condominium.id } aptId={ apartment.id } projectId={this.props.match.params.projectId} />
            <ProjectMaterialsLift projectId={ this.props.projectId } summary />
          </GridItem>
          <GridItem colSpan={ [6, null, null, null, 2] }>
            <UpcomingSchedule condoId={ condominium.id } aptId={ apartment.id } schedules={ scheduleData } />
            { primaryContacts && primaryContacts.length > 0 &&
              <Box bg="white" p={ [4, 8, 4, 8] }>
                { primaryContacts.map(contact => {
                  return (
                    <ContactCard key={ contact.id } contact={ contact } inFrontPage={ true } />
                  )
                }) }
                <Divider mb="1" />
                <Link variant="default-link" href={ '/condominium/' + params + '/contacts' }>Kaikki projektin yhteyshenkilöt</Link>
              </Box>
            }
          </GridItem>
        </SimpleGrid>
      </>
    );
  }
}

export default withRouter(Frontpage);
