import React, { Component } from 'react';

// Chakra
import { Button } from '@chakra-ui/react';

export default class MaterialFormButton extends Component {
  render() {
    return (
      <Button
        mx={3}
        px={'1.5rem'}
        lineHeight={'1'}
        fontSize={'sm'}
        rightIcon={this.props.icon ?? false}
        onClick={() => this.props.onClick()}
        variant={this.props.variant}
        display={this.props.disabled ? 'none' : 'inline-flex'}
      >
        {this.props.content}
      </Button>
    );
  }
}
