import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

//Chakras
import { Text, Box } from '@chakra-ui/layout';

class Links extends Component {
  render() {
    return (
      <>
        <Box>
          <Text>Sortti huolehtii ongelmajätteistä</Text>
          <strong><a href="https://www.hsy.fi/jatteet-ja-kierratys/sortti-asemat/">https://www.hsy.fi/jatteet-ja-kierratys/sortti-asemat/</a></strong>
        </Box>
        <br></br>
        <Box>
          <Text>Hyödyllistä tietoa taloyhtiön asioista</Text>
          <strong><a href="https://www.kiinteistolehti.fi/avainsana/taloyhtio-net">https://www.kiinteistolehti.fi/avainsana/taloyhtio-net</a></strong>
        </Box>
        <br></br>
        <Box>
          <Text>Onnettomuuksien ehkäiseminen. Osaatko toimia oikein vaaratilanteessa?</Text>
          <strong><a href="http://www.varaudu.info/opas/Etusivu">http://www.varaudu.info/opas/Etusivu</a></strong>
        </Box>
      </>
    )
  }
}

export default withRouter(Links);
