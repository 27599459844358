import React, { Component } from "react";
import I18n from 'i18n-js';

// Chakra
import { Button } from "@chakra-ui/button";
import {
  Box,
  Flex,
  GridItem,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  SimpleGrid,
  Text,
  Textarea,
} from "@chakra-ui/react";

// import CustomCloseButton from "../buttons/CustomCloseButton/CustomCloseButton";
import ProductSelectButton from "../buttons/ProductSelectButton/ProductSelectButton";
import ImageSlider from "../ImageSlider/ImageSlider";

import Slider from "react-slick";

export default class ProductModal extends Component {
  constructor(props) {
    super(props);

    // creating ref to manage the focus
    this.numberInput = React.createRef();
    this.state = {
      productId: null,
      value: '',
    };
  }

  componentDidMount = () => {
    const productId = this.props.product.id;

    this.setState({
      productId,
    })
  }

  focusNumberInput() {
    this.numberInput.current.focus();
  }

  onChange = (e) => {
    const productId = this.props.product.id;
    this.props.commentCallback(this.props.product, e.target.value, this.props.optionId);
  }


  render() {
    const {
      brand,
      color,
      contractPrice,
      hue,
      packageSize,
      name,
      productNumber,
      productType,
      size,
      product_photo,
      product_context_photo
    } = this.props.product;


    const productPhoto = product_photo &&  {
      imageId: '0',
      imageSrc: product_photo,
      imageAlt: `${name}-main`
    }

    const contextPhoto = product_context_photo && {
      imageId: '1',
      imageSrc: product_context_photo,
      imageAlt: `${name}-context`
    }

    let images = [];
    if (productPhoto) {
      images.push(productPhoto)
    }
    if (contextPhoto) {
      images.push(contextPhoto)
    }

    var settings = {
      dots: true
    };

    return (
      <Modal
        initialFocusRef={this.numberInput}
        isOpen={this.props.isOpen}
        onClose={this.props.handleClick}
        size="6xl"
        key={this.props.id}
      >
        <ModalOverlay />
        <ModalContent borderRadius="none" pb={{ base: "2", md: "4" }}>
          <ModalHeader pt="4" pb="8" />
          <ModalCloseButton />
          <SimpleGrid columns={6}>
            {/* Image slider on the left side */}
            <GridItem colSpan={{ base: "6", md: "2", xl: "3" }}>
              <ModalBody
                alignItems="center"
                m="0rem 1.5rem 2rem 3rem"
                maxW="70%"
              >
                <Heading>
                  {I18n.t('materialforms.product_images')}
                </Heading>
                {images.length > 0 ? <ImageSlider images={images} /> : <Image src={product_photo ? product_photo : "/assets/images/product_img.png"} />}
              </ModalBody>
            </GridItem>
            {/* Product details on the right side */}
            <GridItem colSpan={{ base: "6", md: "4", xl: "3" }}>
              <ModalBody>
                <Heading>
                  {name}
                </Heading>

                <p style={{marginTop:"15px"}}>
                  {this.props.product.description}
                </p>

                <Box pt="1rem" pb="1rem" maxW="inherit">
                  <Text pb="0.3rem" fontWeight="bold">
                    {I18n.t('materialforms.comments_for_this_product')}
                  </Text>
                  <Textarea
                    border="2px solid"
                    borderColor="lightgray"
                    h="120px"
                    minH="80px"
                    resize="vertical"
                    w="100%"
                    _hover={{
                      borderColor: "gray",
                    }}
                    value={this.props.comment}
                    name={this.state.productId}
                    onChange={this.onChange}
                  />
                </Box>
                <Flex direction={{ base: "column", sm: "row" }}>
                  <ProductSelectButton
                    isDisabled={this.props.disabled}
                    contentText={
                      this.props.isSelected ? I18n.t('materialforms.selected') : I18n.t('materialforms.select_product')}
                    isSelected={this.props.isSelected}
                    toggleSelect={this.props.toggleSelect}
                    productIsSelected={this.props.productIsSelected}
                    canSelectMultiple={false}
                    w="100%"
                  />
                  <Button
                    mt={{ base: "2", sm: "0" }}
                    ml={{ base: "0", sm: "2" }}
                    variant="lightgray-btn-white"
                    onClick={this.props.handleClick}
                  >
                    {I18n.t('general.close')}
                  </Button>
                </Flex>
              </ModalBody>
            </GridItem>
          </SimpleGrid>
        </ModalContent>
      </Modal>
    );
  }
}
