import React, { Component } from 'react';

import { Heading, Box, Text, Flex } from '@chakra-ui/layout';
import Icon from '@chakra-ui/icon';

// Icons
import { BsArrowRightShort, BsQuestionCircle } from "react-icons/bs";
import { Center, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/react';

class FormHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popOpen: false,
      rotateArrow: false,
    };
  }

  handleInfoClick(e) {
    this.setState({ rotateArrow: !this.state.rotateArrow })
    this.setState({ popOpen: !this.state.popOpen });
  }

  render() {
    if(this.props.printMode){
      return <h2>{ this.props.text }</h2>
    }
    return (
      <Flex py={ [4, 8, 4, 8] }>
        <Box w={ { base: "80%", xl: "80%" } } p="4" bg="gray.darkGray">
          <Heading color="white">{ this.props.text }</Heading>
        </Box>
        <Center bg={ { base: "base.yellow", md: "linear-gradient(135deg, #2e2e2e 20%, #fff556 10%)" } } w={ { base: "20%", xl: "20%" } }></Center>
      </Flex >
    )
  }
}

export default FormHeader;
