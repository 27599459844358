import React, { Component } from 'react';
import { animateScroll as scroll, scroller } from 'react-scroll'
import I18n from 'i18n-js';

//Chakra
import { Box, Heading, Text } from '@chakra-ui/layout';
import { Badge, Divider, Flex } from '@chakra-ui/react';
import Icon from '@chakra-ui/icon';
import { FaShoppingCart } from 'react-icons/fa';

class MaterialFormSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      materialform: {},
      active: {
        id: 0,
        name: I18n.t('materialforms.general_instructions')
      }
    };
  }

  componentDidMount = () => {
    this.setState({
      materialform: this.props.materialform,
    })
  }

  handleClick = (menuItem) => {
    this.setState({ active: menuItem }, () => { this.props.activeMenuItemCallback(menuItem); scroll.scrollToTop(); });
  }

  handleClickSubmenuItem = (submenuItem, parent) => {
    this.setState({
      active: submenuItem
    }, () => {
      this.props.activeMenuItemCallback(parent);
      this.props.activeSubmenuItemCallback(submenuItem);
    });
  }

  scrollTo(el) {
    scroller.scrollTo(el, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }

  render() {
    const activeStyle = { backgroundColor: '#fff556' };
    const generalInstructions = {
      id: 0,
      name: I18n.t('materialforms.general_instructions')
    }
    const additionalProducts = {
      id: 1000000,
      name: I18n.t('materialforms.additional_products')
    }
    const additional_info = {
      id: 2000000,
      name: I18n.t('materialforms.additional_info')
    }

    return (this.props.visible &&
      <Box>
        <Box bg="white" mb="4">
          <Flex direction="column" py="6">
            <Heading px={[4, 8, 4, 8]} my="1">{I18n.t('materialforms.material_choices')}</Heading>
            <Box
              cursor="pointer"
              key={0}
              textTransform="uppercase"
              style={this.props.activeMenuItem.name === generalInstructions.name ? activeStyle : {}}
              onClick={this.handleClick.bind(this, generalInstructions)}
              _hover={{ backgroundColor: "white" }}
            >
              <Text px={[4, 8, 4, 8]} my="1">{I18n.t('materialforms.general_instructions')}</Text>
            </Box>
            {this.props.materialform.groups.map((menuItem, index) => {
              return (
                <Box key={index}>
                  <Box
                    cursor="pointer"
                    key={`main-${index}`}
                    textTransform="uppercase"
                    style={this.props.activeMenuItem === menuItem ? activeStyle : {}}
                    onClick={this.handleClick.bind(this, menuItem)}
                    _hover={{ backgroundColor: "white" }}
                  >
                    <Text px={[4, 8, 4, 8]} my="1">{menuItem.name}</Text>
                  </Box>
                  {/*this.props.materialform.selects.filter((item) => item.material_form_group_id === menuItem.id).map((subMenuItem, index) => {
                    if (subMenuItem.product_category_id) {
                      return (<Box
                        cursor="pointer"
                        key={`sub-${index}`}
                        style={this.props.activeMenuItem === subMenuItem ? activeStyle : {}}
                        onClick={this.handleClickSubmenuItem.bind(this, subMenuItem, this.props.materialform.groups.find((menuItem) => menuItem.id === subMenuItem.material_form_group_id))}
                        _hover={{ backgroundColor: "white" }}
                      >
                        <Text px={[6, 10, 6, 10]} my="1">{subMenuItem.name}</Text>
                      </Box>)
                    }
                  })*/}
                </Box>
              )
            })}
            { this.props.materialFormSubmission && this.props.materialFormSubmission.additional_products.length > 0 &&
              <Box
                cursor="pointer"
                key={1000000}
                textTransform="uppercase"
                style={this.props.activeMenuItem.name === additionalProducts.name ? activeStyle : {}}
                onClick={this.handleClick.bind(this, additionalProducts)}
                _hover={{ backgroundColor: "white" }}
              >
                <Text px={[4, 8, 4, 8]} my="1">{I18n.t('materialforms.additional_products')}</Text>
              </Box>
            }
            <Box
              cursor="pointer"
              key={0}
              textTransform="uppercase"
              style={this.props.activeMenuItem.name === additional_info.name ? activeStyle : {}}
              onClick={this.handleClick.bind(this, additional_info)}
              _hover={{ backgroundColor: "white" }}
            >
              <Text px={[4, 8, 4, 8]} my="1">{I18n.t('materialforms.additional_info')}</Text>
            </Box>

          </Flex>
        </Box>

        <Box bg="white" mb={{ base: "0", lg: "4" }} p={[4, 8, 4, 8]}>
          <Flex justify="space-between">
            <Heading alignSelf="center">{I18n.t('materialforms.material_choices')}</Heading>
            <Box position="relative">
              <Icon as={FaShoppingCart} w={6} h={6} />
            </Box>
          </Flex>
          <Divider />
          <Flex justify="space-between">
            <Text fontWeight="bold">{I18n.t('materialforms.total')}</Text>
            <Text fontWeight="bold">{this.props.totalPrice} €</Text>
          </Flex>
        </Box>
      </Box>
    )
  }
}

export default MaterialFormSidebar;
