import React, { Component } from 'react';
import I18n from 'i18n-js';

// Components
import MaterialFormStatusBadge from '../badges/MaterialFormStatusBadge/MaterialFormStatusBadge';

// Chakra
import { Box, Text } from '@chakra-ui/react';

class MaterialFormStatusInstructions extends Component {
  render() {
    let content;
    switch (this.props.activePhase) {
      case 1:
        content = (
          <Text>
            Kirjaathan toiveesi määräaikaan mennessä, jotta asuntosi asioista vastaava asiakaspalveluinsinöörimme kerkeää tutustumaan valintoihin ennen huoneistokatselmusta. Ei haittaa vaikka olisit vielä jostakin valinnasta epävarma. Tässä vaiheessa tehdyt valinnat eivät vielä ole sitovat. Jos jokin valinta päätetään katselmuksen yhteydessä muuttaa, niin saat valintalomakkeen takaisin muokattavaksi tai se muokataan katselmuksen yhteydessä.
          </Text>
        );
        break;

      case 2:
        content = (
          <Text>
            Kiitos valintalomakkeen täyttämisestä. Kun lähetät valintalomakkeen, saapuu se asiakaspalveluinsinöörille käsiteltäväksi. Käsittelyn jälkeen saat vielä tarjouslomakkeen tarkastettavaksi ja hyväksyttäväksi. 
            <br></br><br></br>
            Jos valinnoissa ei ole mitään hintoihin vaikuttavaa, on loppusumma 0 €.
            <br></br><br></br>
            Jos valintojen perusteella kertyy hyvityksiä tai lisäkuluja, saat niiden mukaisesti hinnoitellun tarjouksen.  Lopullinen hyväksyntä tapahtuu sähköisen allekirjoituksen kautta. Linkin saat sähköpostiisi.
            <br></br><br></br>
            Em. vaiheiden jälkeen voit jäädä odottelemaan huonekorttia eli työohjetta.
          </Text>
        );
        break;

      case 3:
        content = (
          <Text>

          </Text>
        );
        break;

      default:
        content = (
          <Text>

          </Text>
        );
        break;
    }

    return (
      <Box py={4} px={12}>
        <Text fontWeight={'bold'} py={2}>
          {I18n.t('materialforms.status_instructions')} "
          {`${this.props.activePhase}.`} {this.props.phaseTitle}"
        </Text>
        <Box py={2}>{content}</Box>
        <Box py={4}>
          {this.props.activePhase === 1 && (
            <>
              <Box display={'flex'} py={2}>
                <Box flex={'1 1 20%'}>
                  <MaterialFormStatusBadge
                    bg={'red'}
                    contentText={I18n.t('materialforms.statuses.pending', {
                      target: I18n.t('materialforms.pending-target.sending'),
                    })}
                  />
                </Box>
                <Text flex={'1 1 80%'}>
                {I18n.t('materialforms.modifying_choices')}
                </Text>
              </Box>
              <Box display={'flex'} py={2}>
                <Box flex={'1 1 20%'}>
                  <MaterialFormStatusBadge
                    bg={'green'}
                    contentText={I18n.t('materialforms.statuses.sent')}
                  />
                </Box>
                <Text flex={'1 1 80%'}>
                {I18n.t('materialforms.making_choices')}
                </Text>
              </Box>
            </>
          )}
          {this.props.activePhase === 2 && (
            <>
              <Box display={'flex'} py={2}>
                <Box flex={'1 1 20%'}>
                  <MaterialFormStatusBadge
                    bg={'base.darkYellow'}
                    contentText={I18n.t('materialforms.statuses.pending', {
                      target: I18n.t('materialforms.pending-target.quote'),
                    })}
                  />
                </Box>
                <Text flex={'1 1 80%'}>
                {I18n.t('materialforms.the_offer')}
                </Text>
              </Box>
              <Box display={'flex'} py={2}>
                <Box flex={'1 1 20%'}>
                  <MaterialFormStatusBadge
                    bg={'red'}
                    contentText={I18n.t('materialforms.statuses.pending', {
                      target: I18n.t('materialforms.pending-target.accepting'),
                    })}
                  />
                </Box>
                <Text flex={'1 1 80%'}>
                {I18n.t('materialforms.offer_ready')}
                </Text>
              </Box>
              <Box display={'flex'} py={2}>
                <Box flex={'1 1 20%'}>
                  <MaterialFormStatusBadge
                    bg={'green'}
                    contentText={I18n.t('materialforms.statuses.accepted')}
                  />
                </Box>
                <Text flex={'1 1 80%'}>
                {I18n.t('materialforms.offer_accepted')}
                </Text>
              </Box>
            </>
          )}
          {this.props.activePhase === 3 && (
            <>
              <Box display={'flex'} py={2}>
                <Box flex={'1 1 20%'}>
                  <MaterialFormStatusBadge
                    bg={'base.darkYellow'}
                    contentText={I18n.t('materialforms.statuses.pending', {
                      target: I18n.t('materialforms.pending-target.room-card'),
                    })}
                  />
                </Box>
                <Text flex={'1 1 80%'}>
                {I18n.t('materialforms.aparment_card')}
                </Text>
              </Box>
              <Box display={'flex'} py={2}>
                <Box flex={'1 1 20%'}>
                  <MaterialFormStatusBadge
                    bg={'red'}
                    contentText={I18n.t('materialforms.statuses.pending', {
                      target: I18n.t('materialforms.pending-target.accepting'),
                    })}
                  />
                </Box>
                <Text flex={'1 1 80%'}>
                {I18n.t('materialforms.card_completed')}
                </Text>
              </Box>
              <Box display={'flex'} py={2}>
                <Box flex={'1 1 20%'}>
                  <MaterialFormStatusBadge
                    bg={'green'}
                    contentText={I18n.t('materialforms.statuses.ready')}
                  />
                </Box>
                <Text flex={'1 1 80%'}>
                {I18n.t('materialforms.material_coiches')}
                </Text>
              </Box>
            </>
          )}
        </Box>
      </Box>
    );
  }
}

export default MaterialFormStatusInstructions;
